import { useSuspenseQuery } from '@tanstack/react-query';
import type { ListResponse, PaginationParams, SortingParams } from 'lib/api';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { CreativeGroup, CreativeStatus } from '../types';
import getCreativeGroupFromDTO from './getCreativeGroupFromDTO';
import type { CreativeGroupDTO } from './types';

interface FilteringFields {
  status: CreativeStatus[];
  channel: number[];
  ad_type: string[];
  domain_id: number[];
  flow_id: number[];
  period: string;
  first_seen: boolean;
  tag_id: number[];
  app_id: number[];
  language: string[];
}

interface GetCreativesOptions {
  params: PaginationParams & Partial<SortingParams> & Partial<FilteringFields>;
}

export const getCreatives = async (
  { params }: GetCreativesOptions,
  signal?: AbortSignal
): Promise<ListResponse<CreativeGroupDTO>> => {
  const res = await apiClient.get<ListResponse<CreativeGroupDTO>>(`/creatives`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseProjectCreativesOptions<TData> {
  config?: QueryConfig<typeof getCreatives, TData>;
  params: GetCreativesOptions['params'];
}

export const useCreatives = <TData = ListResponse<CreativeGroup>>({
  config,
  params,
}: UseProjectCreativesOptions<TData>) => {
  const {
    order_by,
    desc,
    offset,
    limit,
    period,
    domain_id,
    flow_id,
    channel,
    ad_type,
    status,
    first_seen,
    tag_id,
    app_id,
    language,
  } = params;

  return useSuspenseQuery({
    staleTime: 60 * 60 * 1000,
    ...config,
    select: (data) => ({ ...data, data: data.data.map((creativeGroup) => getCreativeGroupFromDTO(creativeGroup)) }),
    queryFn: ({ signal }) => getCreatives({ params }, signal),
    queryKey: [
      'creatives',
      offset,
      limit,
      order_by,
      desc,
      period,
      domain_id,
      channel,
      ad_type,
      status,
      first_seen,
      flow_id,
      tag_id,
      app_id,
      language,
    ],
  });
};
