/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useState, type FC, type ReactNode, useCallback } from 'react';
import { GapContext } from './GapContext';

interface GapContextProviderProps {
  children: ReactNode;
}

const GapContextProvider: FC<GapContextProviderProps> = ({ children }) => {
  const [selectedRows, setSelectedRow] = useState<number[]>([]);

  const onSelectRow = useCallback(
    (row: number) => {
      setSelectedRow((prev) => {
        if (prev.includes(row)) {
          return prev.filter((selected) => selected !== row);
        } else {
          return [...prev, row];
        }
      });
    },
    [setSelectedRow]
  );

  const onSetSelectedRows = useCallback((rows: number[]) => {
    setSelectedRow(rows);
  }, []);

  return <GapContext.Provider value={{ selectedRows, onSelectRow, onSetSelectedRows }}>{children}</GapContext.Provider>;
};

export default GapContextProvider;
