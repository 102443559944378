import type { AutocompleteValue, TextFieldProps } from '@mui/material';
import type { CreativeChannelType } from '../types';
import { CreativeChannel } from '../types';
import type { OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';
import type { AutocompleteProps } from '@mui/material';

interface Channel {
  value: CreativeChannelType;
  label: string;
}
export const CHANNELS = Object.entries(CreativeChannel).map(
  ([value, label]) => (({
    value: Number(value),
    label
  }) as Channel)
);

interface ChannelsAutocompleteProps
  extends Omit<AutocompleteProps<number, true, boolean, false>, 'value' | 'onChange' | 'options' | 'renderInput'> {
  value?: AutocompleteValue<number, true, boolean, false>;
  onChange: (value: AutocompleteValue<number, true, boolean, false>) => void;
  InputProps?: TextFieldProps;
  allSelectable?: boolean;
}

const ChannelsAutocomplete = ({
  InputProps,
  value,
  onChange,
  allSelectable = true,
  ...props
}: ChannelsAutocompleteProps): JSX.Element => {
  return (
    <CheckboxAutocomplete
      {...props}
      allSelectable={allSelectable}
      value={value}
      id="channels-autocomplete"
      onChange={onChange as (value: OptionValue[]) => void}
      InputProps={InputProps}
      options={CHANNELS}
    />
  );
};

export default ChannelsAutocomplete;
