import type { TextFieldProps, AutocompleteValue, AutocompleteProps } from '@mui/material';
import type { Option, OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';
import { useProjects } from 'features/projects';

interface ProjectAutocompleteProps
  extends Omit<AutocompleteProps<number, true, boolean, false>, 'value' | 'onChange' | 'options' | 'renderInput'> {
  value?: AutocompleteValue<number, true, false, false>;
  onChange: (value: AutocompleteValue<number, true, false, false>) => void;
  InputProps?: TextFieldProps;
  allSelectable?: boolean;
}

const ProjectAutocomplete = ({
  InputProps,
  onChange,
  value,
  allSelectable = false,
  ...props
}: ProjectAutocompleteProps): JSX.Element => {
  const { data: projects } = useProjects({
    config: {
      select: (data): Option[] => {
        const options: Option[] = data.map((p) => ({ value: p.id, label: p.name }));
        return options;
      },
    },
  });

  return (
    <CheckboxAutocomplete
      {...props}
      allSelectable={allSelectable}
      id="projects-autocomplete"
      options={projects}
      value={value}
      onChange={onChange as (value: OptionValue[]) => void}
      InputProps={InputProps}
    />
  );
};

export default ProjectAutocomplete;
