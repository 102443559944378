import { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { isAxiosError } from 'axios';
import { Chip, CircularProgress, Menu, MenuItem, Tooltip, Typography, Box } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useAnchorMenu } from 'hooks';
import getTruncatedText from 'utils/getTruncatedText';
import type { TagEntry } from '../types';
import { useChangeBranch, useGetBranches } from '../api';

interface FlowBranchesProps {
  flowId: number;
  branchId: number | null;
  branches?: TagEntry[];
}

const FlowBranches = ({ flowId, branchId }: FlowBranchesProps): JSX.Element => {
  const { data: branches } = useGetBranches<TagEntry[]>({
    config: {
      select: (data) => data.tags,
    },
  });
  const [formError, setFormError] = useState<string | null>(null);
  const { isOpen, anchor, handleOpenMenu, handleCloseMenu } = useAnchorMenu();

  const changeBranch = useChangeBranch({
    config: {
      onError: (e) => {
        if (isAxiosError(e) && e.response) {
          setFormError((e.response.data as { message: string }).message);
        }
      },
    },
  });

  const onChangeBranch = useCallback(
    (branch_id: number) => {
      setFormError(null);
      handleCloseMenu();
      changeBranch.mutate({ flow_id: flowId, branch_id });
    },
    [changeBranch, handleCloseMenu, setFormError, flowId]
  );

  const chosenBranchName = useMemo(
    () => branches.find((item) => item.tag_id === branchId)?.tag_name,
    [branches, branchId]
  );

  return (
    <>
      <Chip
        onClick={handleOpenMenu}
        size="small"
        variant={chosenBranchName ? 'filled' : 'outlined'}
        disabled={changeBranch.isPending}
        label={
          <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: 16 }}>
              {getTruncatedText({ text: chosenBranchName ?? '-', maxLength: 16 })}
            </Typography>
            <ExpandMoreIcon
              sx={{ fontSize: 16, transform: `rotate(${isOpen ? 180 : 0}deg)`, transition: 'all 0.1s' }}
            />
          </Box>
        }
        sx={(t) => ({
          fontSize: 16,
          minWidth: 70,
          borderColor: t.palette.grey[300],
          '.MuiChip-label': {
            display: 'flex',
            flexGrow: 1,
          },
        })}
        icon={
          changeBranch.isPending ? (
            <CircularProgress size={18} color="success" />
          ) : changeBranch.isError ? (
            <Tooltip title={formError} arrow>
              <>
                <ErrorOutlineIcon color="error" sx={{ width: 18 }} />
              </>
            </Tooltip>
          ) : undefined
        }
      />
      <Menu
        id={`branch-flow-${flowId}`}
        anchorEl={anchor}
        open={isOpen}
        onClose={handleCloseMenu}
        sx={{ maxHeight: 500 }}
      >
        {branchId && (
          <MenuItem
            onClick={() => {
              onChangeBranch(0);
            }}
          >
            <Typography sx={{ fontSize: 18 }}>—</Typography>
          </MenuItem>
        )}

        {!isEmpty(branches) ? (
          branches.map((item) => (
            <MenuItem
              key={item.tag_id}
              sx={{ fontSize: 16, whiteSpace: 'pre-wrap', maxWidth: 220 }}
              disabled={item.tag_id === branchId}
              onClick={() => {
                onChangeBranch(item.tag_id);
              }}
            >
              {item.tag_name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled sx={{ fontSize: 16 }}>
            No branches
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default FlowBranches;
