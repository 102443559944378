import {
  Box,
  Drawer,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme,
  styled,
  List,
  ThemeProvider,
  Tooltip,
  Divider,
  ListItemText,
  ListItem,
  Button,
  ListItemIcon,
} from '@mui/material';
import type { FC } from 'react';
import {
  Settings as SettingsIcon,
  ImportantDevices as ImportantDevicesIcon,
  Route as RouteIcon,
  Logout as LogoutIcon,
  QueryStats as QueryStatsIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { darkSubTheme } from 'theme';
import WellSpyLogo from 'assets/wellspy-logo.svg?react';
import { Auth } from 'aws-amplify';
import SidebarNavItem from './SidebarNavItem';
import { getPersistedData } from '../../utils/persist';
import { useNavigate } from 'react-router';
import { useAuthenticator } from '@aws-amplify/ui-react';

const HomeLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

interface SidebarProps {
  isShown: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const Sidebar: FC<SidebarProps> = ({ isShown, openDrawer, closeDrawer }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const theme = useTheme();
  const hideSidebar = useMediaQuery(theme.breakpoints.down('macbook15'));

  // This casting is safe because we are already logged in on this stage
  const email = user.getSignInUserSession()?.getIdToken().payload.email as string;
  const fullName = email ? email
    .split('@')[0]
    .split('.')
    .map((word: string) => [word[0].toUpperCase(), word.slice(1)].join(''))
    .join(' ') : '';

  const handleSignOut = async () => {
    await Auth.signOut();
  };

  const navigate = useNavigate();
  const handleGapRedirect = () => {
    const persistedData = getPersistedData<string>('gap_search', '');

    navigate('/gap' + persistedData);
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Toolbar>
        <HomeLink sx={{ mt: 4, mb: 1 }} to="/">
          <WellSpyLogo />
        </HomeLink>
      </Toolbar>

      <Box component="nav" sx={{ flexGrow: 1 }}>
        <List>
          <SidebarNavItem to="/web-creatives" icon={<ImportantDevicesIcon />} title="Creatives" />
          <SidebarNavItem to="/competitors-flows" icon={<RouteIcon />} title="Competitors flows" />
          <SidebarNavItem
            to="/gap"
            isButton
            onClick={handleGapRedirect}
            icon={<QueryStatsIcon />}
            title="Gap creatives"
          />
        </List>
      </Box>
      <Box
        sx={{
          py: 3,
        }}
      >
        <List>
          <div>
            <SidebarNavItem icon={<SettingsIcon />} title="Settings">
              <Tooltip title="Coming soon..." placement="right">
                <div>
                  <SidebarNavItem to="/settings/tags" title="Tags" />
                </div>
              </Tooltip>
              <SidebarNavItem to="/settings/competitors" title="Competitors" />
              <SidebarNavItem to="/settings/api-usage" title="API usage" />
              <Box sx={(t) => ({ padding: `${t.spacing(1)} ${t.spacing(3)}` })}>
                <Divider />
                <ListItemText
                  sx={(t) => ({ padding: `${t.spacing(2)} 0 0`, wordBreak: 'break-all' })}
                  primary={fullName}
                  secondary={email}
                />
              </Box>
              <ListItem>
                <Button
                  variant="text"
                  component="button"
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    textTransform: 'none',
                    textAlign: 'left',
                    color: '#fff',
                  }}
                  onClick={handleSignOut}
                >
                  <ListItemIcon sx={{ marginLeft: '0!important', minWidth: 32, opacity: 0.56 }}>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign Out" />
                </Button>
              </ListItem>
            </SidebarNavItem>
          </div>
        </List>
      </Box>
    </Box>
  );

  if (hideSidebar) {
    return (
      <ThemeProvider theme={darkSubTheme}>
        <SwipeableDrawer
          variant="temporary"
          sx={{ zIndex: theme.zIndex.appBar - 1, maxHeight: '100vh' }}
          PaperProps={{
            sx: {
              height: 'unset',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              maxWidth: theme.sizes.sidebarWidth,
              [theme.breakpoints.down('md')]: {
                // top: 65,
                maxWidth: '40%',
              },
              [theme.breakpoints.down('sm')]: {
                // top: 57,
                maxWidth: '100vw',
              },
            },
          }}
          componentsProps={{
            backdrop: () => ({
              sx: {
                [theme.breakpoints.down('md')]: {
                  top: 65,
                },
                [theme.breakpoints.down('sm')]: {
                  top: 57,
                },
              },
            }),
          }}
          open={isShown}
          onOpen={openDrawer}
          onClose={closeDrawer}
        >
          <Toolbar />
          {content}
        </SwipeableDrawer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkSubTheme}>
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          width: theme.sizes.sidebarWidth,
        }}
        PaperProps={{
          sx: {
            width: theme.sizes.sidebarWidth,
          },
        }}
      >
        {content}
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
