import type { CreativeGroup } from '../types';
import type { CreativeGroupDTO, CreativeItemDTO } from './types';

const getCreativeGroupFromDTO = (dto: CreativeGroupDTO): CreativeGroup => {
  const creatives = dto.creative_items.map((creative: CreativeItemDTO) => ({
    ...creative,
    first_seen: new Date(creative.first_seen),
    last_seen: new Date(creative.last_seen),
    created_at: new Date(creative.created_at),
    updated_at: new Date(creative.updated_at),
  }));

  return {
    ...dto,
    first_seen: new Date(dto.first_seen),
    last_seen: new Date(dto.last_seen),
    created_at: new Date(dto.created_at),
    updated_at: new Date(dto.updated_at),
    creative_items: creatives,
  };
};

export default getCreativeGroupFromDTO;
