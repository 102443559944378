import {
  BooleanParam,
  createEnumArrayParam,
  createEnumParam,
  NumericArrayParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import type { AdType } from '../creatives/types';
import type { SearchLanguages } from 'types/shared';

export const DEFAULT_IMPRESSIONS_PERIOD = '30';

export const DEFAULT_ROW_PARAM = 'networks';
//fasteasy.io, yoga-go.io, muscle-booster.io, fit-coach.io
export const DEFAULT_DOMAINS_PARAM = [54, 55, 56, 57];

export const COLUMN_PARAM = withDefault(StringParam, 'domains');
export const ROW_PARAM = withDefault(StringParam, DEFAULT_ROW_PARAM);
export const VALUE_PARAM = withDefault(StringParam, 'impressions');
export const DOMAINS_PARAM = withDefault(NumericArrayParam, DEFAULT_DOMAINS_PARAM);
//BetterMe
export const APPS_PARAM = withDefault(NumericArrayParam, [1] as number[]);
export const PERIOD_PARAM = withDefault(StringParam, DEFAULT_IMPRESSIONS_PERIOD);
export const LANG_PARAM = withDefault(
  createEnumArrayParam<SearchLanguages>(['en', 'es', 'pt']),
  [] as SearchLanguages[]
);

export const AD_TYPE_PARAM = withDefault(
  createEnumArrayParam<AdType>(['carousel', 'html', 'image', 'video']),
  [] as AdType[]
);
export const CHANNELS_PARAM = withDefault(NumericArrayParam, [] as number[]);
export const TAGS_PARAM = withDefault(NumericArrayParam, [] as number[]);
export const ORDER_BY_PARAM = withDefault(StringParam, undefined);
export const ORDER_DIRECTION_PARAM = withDefault(BooleanParam, undefined);
export const GAP_ID_PARAM = withDefault(StringParam, '');
export const FILTER_PARAM = withDefault(createEnumParam<'web' | 'app'>(['web', 'app']), 'web');

export const CHART_COLORS = [
  '#651FFF',
  '#FF3D00',
  '#FFC400',
  '#00BFA5',
  '#D500F9',
  '#2979FF',
  '#FF9100',
  '#B71C1C',
  '#64DD17',
  '#00695C',
  '#311B92',
  '#FF4081',
  '#9E9D24',
  '#5F2120',
  '#014361',
  '#663C00',
  '#880E4F',
  '#757575',
];
