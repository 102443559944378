/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useAuthenticator } from '@aws-amplify/ui-react';
import hotjarIdentify from './hotjarIdentify';
import type { FC, ReactNode} from 'react';
import { useEffect } from 'react';
import { getPersistedData } from 'utils/persist';

const HotjarInitial: FC<{ children: ReactNode }> = ({ children }) => {
  const isConsent = getPersistedData("hotjar_consent");
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (user && isConsent === "agree") {
      const email = user.getSignInUserSession()?.getIdToken().payload.email as string;
      const fullName = email
        .split('@')[0]
        .split('.')
        .map((word: string) => [word[0].toUpperCase(), word.slice(1)].join(''))
        .join(' ');

      hotjarIdentify(fullName, email);
    }
  }, [user, isConsent])

  return children
};

export default HotjarInitial;
