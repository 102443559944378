import { CheckCircleOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import type { FC } from "react";

const AlertMessage: FC<{ message: string }> = ({ message }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        paddingRight: 2,
        paddingY: 1,
      }}
    >
      <CheckCircleOutline sx={ t => ({ marginRight: 1, color: t.palette.success.main, })} />
      <Typography sx={(t) => ({ fontSize: t.typography.body1.fontSize, lineHeight: 1.25, fontWeight: 500, wordBreak: 'break-word', })}>
        {message}
      </Typography>
    </Box>
  );
};

export default AlertMessage;
