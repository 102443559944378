import { Table, TableBody, TableContainer } from '@mui/material';
import type { FC } from 'react';
import { useApps } from '../api';
import AppRow from './AppRow';
import AppListHeader from './AppListHeader';

interface AppListProps {
  projects: number[];
}

const AppList: FC<AppListProps> = ({  projects }) => {
  const { data: apps } = useApps({ params: { projects } });

  return (
    <TableContainer sx={{ flexGrow: 1, height: 0 }}>
      <Table stickyHeader>
        <AppListHeader total={apps.length} />
        <TableBody>
          {apps.map((app) => (
            <AppRow key={app.id} app={app} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppList;
