import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import getTruncatedText from 'utils/getTruncatedText';
import { useColumnsSettingsContext } from '../providers/ColumnsSettingsContext';
import type { CompetitorFlow } from '../types';
import { StyledLink, StyledStickyTableCell, StyledTableCell, StyledTableRow } from './CompetitorsFlowsComponents';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { DEFAULT_IMPRESSIONS_PERIOD } from './CompetitorsFlows';
import { FlowBranches } from 'features/tags';

interface CompetitorsFlowsTableItemProps {
  domainName: string;
  domainId: number;
  flow: CompetitorFlow;
}

const CompetitorsFlowsTableItem = ({ domainName, domainId, flow }: CompetitorsFlowsTableItemProps) => {
  const [period] = useQueryParam('period', StringParam);
  const theme = useTheme();

  const {
    visibilityColumns: {
      creatives: creativesVisible,
      branch: branchVisible,
      impressions_meta: impressionsMetaVisible,
      impressions_google: impressionsGoogleVisible,
      impressions_total: impressionsTotalVisible,
      traffic_share: trafficShareVisible,
      first_seen: firstSeenVisible,
    },
  } = useColumnsSettingsContext();

  const {
    creatives,
    first_seen,
    impressions_google,
    impressions_meta,
    flow_impressions,
    flow_impressions_percent,
    path,
    flow_id,
    branch_id
  } = flow;

  return (
    <StyledTableRow hover>
      <StyledStickyTableCell
        sx={{
          boxShadow: `inset -1px 0 0 0 ${theme.palette.grey[300]}`,
          backgroundColor: 'white',
          wordBreak: 'keep-all',
          left: 162,
        }}

      >
        <StyledLink target="_blank" href={`//${domainName}${path}`}>
          <Typography>{getTruncatedText({ text: path })}</Typography>
        </StyledLink>
      </StyledStickyTableCell>
      {creativesVisible && (
        <StyledTableCell>
          <Button
            component={Link}
            to={{
              pathname: '/web-creatives',
              search: new URLSearchParams({
                domains: `${domainId}`,
                flows: `${flow_id}`,
                period: `${period ?? DEFAULT_IMPRESSIONS_PERIOD}`,
              }).toString(),
            }}
            variant="outlined"
            sx={{ p: 0.25, minWidth: 48 }}
          >
            <Typography textAlign="center">{creatives}</Typography>
          </Button>
        </StyledTableCell>
      )}
      {branchVisible && <StyledTableCell>
        <FlowBranches branchId={branch_id} flowId={flow_id} />
      </StyledTableCell>}
      {impressionsMetaVisible && (
        <StyledTableCell>
          <Typography noWrap>{impressions_meta.toLocaleString('en-US')}</Typography>
        </StyledTableCell>
      )}
      {impressionsGoogleVisible && (
        <StyledTableCell>
          <Typography noWrap>{impressions_google.toLocaleString('en-US')}</Typography>
        </StyledTableCell>
      )}
      {impressionsTotalVisible && (
        <StyledTableCell>
          <Typography noWrap>{flow_impressions.toLocaleString('en-US')}</Typography>
        </StyledTableCell>
      )}
      {trafficShareVisible && (
        <StyledTableCell>
          <Typography noWrap>{flow_impressions_percent.toFixed(1)}%</Typography>
        </StyledTableCell>
      )}
      {firstSeenVisible && (
        <StyledTableCell sx={{ wordBreak: 'keep-all' }}>
          <Typography>{`${format(first_seen, 'dd.MM.yyyy')}`}</Typography>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

export default CompetitorsFlowsTableItem;
