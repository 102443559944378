import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { TagGroupEntry } from '../types';
import queryKeys from './queryKeys';

export const getBranches = async (): Promise<TagGroupEntry> => {
  const res = await apiClient.get<TagGroupEntry>(`/tag_groups/branches`);
  return res.data;
};

interface UseGetBranchesOptions<TData> {
  config?: QueryConfig<typeof getBranches, TData>;
}

export const useGetBranches = <TData = TagGroupEntry>({ config }: UseGetBranchesOptions<TData> = {}) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: getBranches,
    queryKey: queryKeys().tagBranches(),
  });
};

interface UseGetSynchronousBranchesOptions<TData> {
  config?: QueryConfig<typeof getBranches, TData>;
}

export const useGetSynchronousBranches = <TData = TagGroupEntry>({
  config,
}: UseGetSynchronousBranchesOptions<TData> = {}) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: getBranches,
    queryKey: queryKeys().tagBranches(),
  });
};
