import { MoreVert } from '@mui/icons-material';
import { IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import type { FC } from 'react';
import getSkeletonArray from 'utils/getSkeletonArray';
import AppListHeader from './AppListHeader';

const AppListSkeleton: FC = () => {
  const apps = getSkeletonArray(10);

  return (
    <TableContainer sx={{ height: 0, flexGrow: 1 }}>
      <Table stickyHeader>
        <AppListHeader />
        <TableBody>
          {apps.map((a) => (
            <TableRow key={a.id}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Typography>
                  <Skeleton />
                </Typography>
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <IconButton>
                  <MoreVert />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppListSkeleton;
