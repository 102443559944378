import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { GetGapDetailsEntryResponse } from '../types';
import type { FilterType } from '../types';
import type { SearchLanguages } from '../../../types/shared';

export interface FilteringFields {
  // viewing settings
  rows: string | null;
  columns: string | null;
  value: string | null;
  // filters
  domain_id?: (number | null)[] | null;
  app_id?: (number | null)[] | null;
  period: string | null;
  channel?: (number | null)[] | null;
  ad_type?: string[];
  filter?: FilterType;
  tag_id?: (number | null)[] | null;
  language: SearchLanguages[];
}

interface GetGapDetailsOptions {
  params: Omit<FilteringFields, 'filter'>;
}

export const getGapDetails = async (
  id: string,
  { params }: GetGapDetailsOptions,
  signal?: AbortSignal
): Promise<GetGapDetailsEntryResponse> => {
  const res = await apiClient.get<GetGapDetailsEntryResponse>(`/gap/${id}`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseGapDetailsOptions<TData> {
  config?: QueryConfig<typeof getGapDetails, TData>;
  params: FilteringFields;
  id: string;
}

export const useGapDetails = <TData = GetGapDetailsEntryResponse>({
  config,
  params,
  id,
}: UseGapDetailsOptions<TData>) => {
  const { filter, app_id, domain_id, ...restParams } = params;

  const filteredParams = {
    ...restParams,
    ...(filter === 'web' ? { domain_id } : { app_id }),
  };

  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getGapDetails(id, { params: filteredParams }, signal),
    queryKey: ['gap', ...Object.values(filteredParams), id, filter],
  });
};
