import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';

interface ChangeBranchPayload {
  flow_id: number;
  branch_id: number;
};

interface ChangeBranchEntry {
  success: boolean
};

export const changeBranch = async ({flow_id, ...data}: ChangeBranchPayload): Promise<ChangeBranchEntry> => {
  const res = await apiClient.patch<ChangeBranchEntry>(`/flows/${flow_id}`, data);
  return res.data;
};

interface UseChangeBranchOptions {
  config?: MutationConfig<typeof changeBranch>;
}

export const useChangeBranch = ({ config }: UseChangeBranchOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: ['flows']
      });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    onError: async (...args) => {
      if (config?.onError) {
        await config.onError(...args);
      }
    },
    mutationFn: changeBranch,
  });
};
