import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { APIUsageEntry } from '../types';
import getAPIUsageEntryFromDTO from './getAPIUsageFromDTO';
import type { APIUsageEntryDTO } from './types';

interface GetAPIUsagesOptions {
  startTime?: string;
  endTime?: string;
}

export const getAPIUsages = async (params?: GetAPIUsagesOptions): Promise<APIUsageEntry[]> => {
  const res = await apiClient.get<APIUsageEntryDTO[]>(`/external_api_usage`, { params });
  return res.data.map((domain) => getAPIUsageEntryFromDTO(domain));
};

interface UseAPIUsageOptions<TData> {
  config?: QueryConfig<typeof getAPIUsages, TData>;
  params?: GetAPIUsagesOptions;
}

export const useAPIUsages = <TData = APIUsageEntry[]>({ config, params }: UseAPIUsageOptions<TData> = {}) => {
  const { startTime, endTime } = params ?? {};
  return useSuspenseQuery({
    ...config,
    queryFn: () => getAPIUsages(params),
    queryKey: ['api-usage', startTime, endTime],
  });
};
