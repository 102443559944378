import { Box, Typography, styled } from "@mui/material";
import getRandomBackground from "../utils/randomBackground";

export const GradientBox = styled(Box)`
  position: relative;
  padding: 16px 24px 56px;
  ${({ theme: t }) => `background-color: ${t.palette.grey[500]}`};
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ${getRandomBackground()}

  ${({ theme: t }) => t.breakpoints.up('md')} {
    justify-content: space-between;
    max-width: 450px;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    justify-content: flex-start;

    &:after {
      display: none;
    }
  }
`;

export const TitleStyled = styled(Typography)`
  position: relative;
  z-index: 3;
  font-size: 48px;
  line-height: 116.7%;
  font-weight: 400;
  color: white;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    width: min-content;
  }
`;
