import { Box, LinearProgress } from '@mui/material';
import WellSpyLogo from 'assets/wellspy-logo.svg?react';
import LoginForm from './LoginForm';
import { useCheckSession } from 'hooks';
import { GradientBox, TitleStyled } from './LoginComponents';
import EnvironmentBanner from '../../../components/common/EnvironmentBanner';

const Login = () => {
  const { isChecked } = useCheckSession();

  return isChecked ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', minWidth: 320 }}>
      <EnvironmentBanner />
      <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <GradientBox>
          <WellSpyLogo width={264} />
          <TitleStyled variant="h1">Creatives analytics</TitleStyled>
        </GradientBox>
        <LoginForm />
      </Box>
    </Box>
  ) : (
    <LinearProgress />
  );
};

export default Login;
