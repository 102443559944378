import { TableCell, TableHead, TableRow } from '@mui/material';
import TotalCompetitorsTitle from 'components/common/TotalCompetitorsTitle';
import type { FC } from 'react';

const AppListHeader: FC<{ total?: number, }> = ({ total }) => {
  return (
    <TableHead>
      <TableRow sx={{ th: { backgroundColor: 'white' } }}>
        <TableCell component="th">ID</TableCell>
        <TableCell component="th">App name</TableCell>
        <TableCell component="th">Project</TableCell>
        <TableCell component="th">iOS ID</TableCell>
        <TableCell component="th">Android ID</TableCell>
        <TableCell component="th">Created at</TableCell>
        <TableCell component="th" align='right'>
          {total ? (<TotalCompetitorsTitle value={total} />) : null}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default AppListHeader;
