import { Button, TableCell, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useColumnsSettingsContext } from '../providers/ColumnsSettingsContext';
import type { CompetitorDomain, CompetitorFlow } from '../types';
import { StyledStickyTableCell, StyledTableCell, StyledTableRow } from './CompetitorsFlowsComponents';
import CompetitorsFlowsTableItem from './CompetitorsFlowsTableItem';
import ShowMore from './ShowMoreComponent';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { DEFAULT_IMPRESSIONS_PERIOD } from './CompetitorsFlows';

const DEFAULT_VISIBLE_FLOWS = 5;

const CompetitorsFlowsTableRow = ({ row }: { row: CompetitorDomain }) => {
  const [period] = useQueryParam('period', StringParam);
  const {
    visibilityColumns: {
      impressions_meta: impressionsMetaVisible,
      impressions_google: impressionsGoogleVisible,
      impressions_total: impressionsTotalVisible,
      traffic_share: trafficShareVisible,
      first_seen: firstSeenVisible,
      creatives: creativesVisible,
      branch: branchVisible,
    },
  } = useColumnsSettingsContext();
  const {
    domain,
    domain_id,
    total_impressions_google,
    total_impressions_meta,
    total_impressions,
    total_creatives,
    total_flows,
    flows,
  } = row;

  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const onShowMore = useCallback(() => {
    setIsShowMore((prev) => !prev);
  }, [setIsShowMore]);

  const getRowSpanDomain = (flowsLength: number) => {
    const sizeColsWhenHidden = flowsLength > DEFAULT_VISIBLE_FLOWS ? DEFAULT_VISIBLE_FLOWS + 2 : flowsLength + 1;
    const sizeColsWhenShown = flowsLength + 2;

    return isShowMore ? sizeColsWhenShown : sizeColsWhenHidden;
  };

  const topFlows = flows.slice(0, DEFAULT_VISIBLE_FLOWS);
  const restFlows = flows.slice(DEFAULT_VISIBLE_FLOWS, flows.length);

  const isExpandable: boolean = flows.length > DEFAULT_VISIBLE_FLOWS;

  return (
    <>
      <StyledTableRow>
        <StyledStickyTableCell
          rowSpan={getRowSpanDomain(flows.length)}

          sx={{ maxWidth: 162, background: 'white!important' }}
        >
          <Typography sx={{ wordBreak: 'break-all' }} fontWeight="bold">
            {domain}
          </Typography>
        </StyledStickyTableCell>
        <StyledStickyTableCell sx={{ left: 162, background: 'white' }}>
          <Typography fontWeight="bold">{total_flows} flows</Typography>
        </StyledStickyTableCell>
        {creativesVisible && (
          <StyledTableCell>
            <Button
              component={Link}
              to={{
                pathname: '/web-creatives',
                search: new URLSearchParams({
                  domains: `${domain_id}`,
                  period: `${period ?? DEFAULT_IMPRESSIONS_PERIOD}`,
                }).toString(),
              }}
              variant="outlined"
              sx={{ p: 0.25, minWidth: 48 }}
            >
              <Typography fontWeight="bold" textAlign="center">
                {total_creatives}
              </Typography>
            </Button>
          </StyledTableCell>
        )}
        {branchVisible && <StyledTableCell>-</StyledTableCell>}
        {impressionsMetaVisible && (
          <StyledTableCell>
            <Typography noWrap fontWeight="bold">
              {total_impressions_meta.toLocaleString('en-US')}
            </Typography>
          </StyledTableCell>
        )}
        {impressionsGoogleVisible && (
          <StyledTableCell>
            <Typography noWrap fontWeight="bold">
              {total_impressions_google.toLocaleString('en-US')}
            </Typography>
          </StyledTableCell>
        )}
        {impressionsTotalVisible && (
          <StyledTableCell>
            <Typography noWrap fontWeight="bold">
              {total_impressions.toLocaleString('en-US')}
            </Typography>
          </StyledTableCell>
        )}
        {trafficShareVisible && (
          <StyledTableCell>
            <Typography noWrap fontWeight="bold">
              100.0%
            </Typography>
          </StyledTableCell>
        )}
        {firstSeenVisible && <TableCell />}
      </StyledTableRow>

      {topFlows.map((flow: CompetitorFlow) => (
        <CompetitorsFlowsTableItem key={flow.flow_id} domainName={domain} domainId={domain_id} flow={flow} />
      ))}
      {isShowMore &&
        restFlows.map((flow: CompetitorFlow) => (
          <CompetitorsFlowsTableItem key={flow.flow_id} domainName={domain} domainId={domain_id} flow={flow} />
        ))}
      {isExpandable && <ShowMore onShowMore={onShowMore} open={isShowMore} />}
    </>
  );
};

export default CompetitorsFlowsTableRow;
