/* eslint-disable @typescript-eslint/no-unsafe-argument */
import type { MouseEventHandler, PropsWithChildren } from 'react';
import { type FC } from 'react';
import type { TableRowTypeMap } from '@mui/material';
import { TableRow, TableCell, Checkbox, Typography, styled } from '@mui/material';
import { type CommonProps } from '@mui/material/OverridableComponent';
import type { ColumnsNameRecord, GapRowEntry } from '../types';

interface GapTableRowItemProps {
  row: GapRowEntry;
  columns: ColumnsNameRecord;
  gapIdDetails: string | null;
  selectedRows: number[];
  onSelectRow: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onGapDetails: (value: string | null) => void;
  getGapColor: (columnName: string, columnValue: string) => string;
}

const TableRowClickable: FC<
  PropsWithChildren<{ onClick?: MouseEventHandler | undefined } | CommonProps | TableRowTypeMap>
> = ({ children, ...props }) => <TableRow {...props}>{children}</TableRow>;

export const StyledTableRow = styled(TableRowClickable)(({ theme: t }) => ({
  position: 'relative',
  '&:hover td': {
    backgroundColor: t.palette.grey[200],
  },
  '&:hover::after': {
    content: '"Show more"',
    position: 'absolute',
    right: 1,
    height: '41px',
    padding: '2px 10px 2px 100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    textTransform: 'uppercase',
    color: t.palette.primary.light,
    fontSize: 13,
    background: 'linear-gradient(-90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)',
  },
}));

const GapTableRowItem: FC<GapTableRowItemProps> = ({
  row,
  columns,
  selectedRows,
  gapIdDetails,
  onGapDetails,
  onSelectRow,
  getGapColor,
}) => {
  const isSelected = gapIdDetails === String(row.row_id);

  return (
    <StyledTableRow
      key={row.row_name}
      style={{ cursor: 'pointer', position: 'relative' }}
      onClick={() => {
        onGapDetails(String(row.row_id));
      }}
    >
      <TableCell
        width={42}
        height={42}
        sx={{
          minWidth: 42,
          position: 'sticky',
          left: 0,
          backgroundColor: isSelected ? 'rgba(33, 150, 243, 0.12)' : 'white',
        }}
        padding="checkbox"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          color="primary"
          name={`${row.row_id}`}
          checked={selectedRows.includes(row.row_id)}
          onChange={onSelectRow}
        />
      </TableCell>
      <TableCell
        height={42}
        sx={(t) => ({
          position: 'sticky',
          left: 52,
          pl: 0.5,
          whiteSpace: 'nowrap',
          borderRight: isSelected ? '2px solid rgba(33, 150, 243, 1)' : `1px solid ${t.palette.grey[300]}`,
          backgroundColor: isSelected ? 'rgba(33, 150, 243, 0.12)' : 'white',
        })}
      >
        <Typography
          sx={{ fontSize: 14, maxWidth: gapIdDetails ? 320 : 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {row.row_name}
        </Typography>
      </TableCell>
      {!gapIdDetails
        ? Object.entries(columns).map(([colId, colName]) => (
            <TableCell
              key={colId}
              height={42}
              sx={(t) => ({
                whiteSpace: 'wrap',
                wordBreak: 'break-all',
                backgroundColor: getGapColor(colName, row.columns[colId]),
                borderRight: gapIdDetails ? 'none' : `1px solid ${t.palette.grey[300]}`,
              })}
            >
              {row.columns[colId].toLocaleString()}
            </TableCell>
          ))
        : null}
    </StyledTableRow>
  );
};

export default GapTableRowItem;
