import type { FC } from 'react';
import type { SelectProps } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { useTagGroups } from '../api';

interface TagGroupSelectProps extends SelectProps<number> {
  inputLabel: string;
  errorText?: string;
}

const TagGroupSelect: FC<TagGroupSelectProps> = ({
  value,
  onChange,
  inputLabel,
  required,
  error,
  errorText = 'Error',
  ...restProps
}: TagGroupSelectProps) => {
  const { data } = useTagGroups({
    config: {
      select: (data) =>
        data.data
          .filter((tagGroup) => tagGroup.is_visible)
          .map((tagGroup) => ({ id: tagGroup.group_id, label: tagGroup.group_name })),
    },
  });

  return (
    <FormControl sx={{ width: '100%' }} required={required}>
      <InputLabel id="tag-group-select">{inputLabel}</InputLabel>
      <Select value={value} onChange={onChange} {...restProps}>
        {data.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default TagGroupSelect;
