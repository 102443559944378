import { Box } from '@mui/system';
import AddChartIcon from '@mui/icons-material/Addchart';
import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const GapMainChartEmptyState = ({ variant = 'empty' }: { variant: 'empty' | 'error' }) => {
  const isEmpty = variant === 'empty';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {isEmpty ? <AddChartIcon color="disabled" /> : <ErrorOutlineIcon color="disabled" />}
      <Typography sx={{ mt: 2 }}>
        {isEmpty
          ? 'Select a trend to display from the table below'
          : 'Something went wrong. Try to adjust your filters'}
      </Typography>
    </Box>
  );
};

export default GapMainChartEmptyState;
