import type { ChipProps } from '@mui/material';
import { Chip } from '@mui/material';
import { DescriptionOutlined, StorageOutlined } from '@mui/icons-material';
import type { FC } from 'react';
import type { APIUsageType } from '../types';

const APIUsageTypeLabel: Record<APIUsageType, string> = {
  meta_info: 'Meta Info',
  database_app: 'Database by App',
  spreadsheets_domain: 'Spreadsheets by Domain',
  database_domain: 'Database by Domain',
  spreadsheets_app_high: 'Spreadsheets App High',
  spreadsheets_app_low: 'Spreadsheets App Low',
};

const APIUsageTypeIcon: Partial<Record<APIUsageType, ChipProps['icon']>> = {
  meta_info: <DescriptionOutlined />,
  database_app: <StorageOutlined />,
  spreadsheets_domain: <DescriptionOutlined />,
  spreadsheets_app_high: <DescriptionOutlined />,
  spreadsheets_app_low: <DescriptionOutlined />,
  database_domain: <StorageOutlined />,
};

interface APIUsageTypeChipProps {
  type: APIUsageType;
}

const APIUsageTypeChip: FC<APIUsageTypeChipProps> = ({ type }) => (
  <Chip label={APIUsageTypeLabel[type]} icon={APIUsageTypeIcon[type]} />
);

export default APIUsageTypeChip;
