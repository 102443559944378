import { useQueryParam } from 'use-query-params';
import {
  AD_TYPE_PARAM,
  APPS_PARAM,
  CHANNELS_PARAM,
  COLUMN_PARAM,
  DOMAINS_PARAM,
  FILTER_PARAM,
  GAP_ID_PARAM,
  ORDER_BY_PARAM,
  ORDER_DIRECTION_PARAM,
  PERIOD_PARAM,
  ROW_PARAM,
  TAGS_PARAM,
  VALUE_PARAM,
  LANG_PARAM,
} from '../constants';
import { useCallback, useRef } from 'react';
import { isEqual } from 'lodash-es';

const useGapParams = () => {
  const [columns, setColumns] = useQueryParam('column', COLUMN_PARAM);
  const [rows, setRows] = useQueryParam('row', ROW_PARAM);
  const [value, setValue] = useQueryParam('value', VALUE_PARAM);
  const [domains, setDomains] = useQueryParam('domains', DOMAINS_PARAM);
  const [period, setPeriod] = useQueryParam('period', PERIOD_PARAM);
  const [tags, setTags] = useQueryParam('tags', TAGS_PARAM);
  const [channel, setChannel] = useQueryParam('channels', CHANNELS_PARAM);
  const [adTypes, setAdTypes] = useQueryParam('ad_types', AD_TYPE_PARAM);
  const [orderBy, setOrderBy] = useQueryParam('order_by', ORDER_BY_PARAM);
  const [desc, setDesc] = useQueryParam('desc', ORDER_DIRECTION_PARAM);
  const [gapIdDetails, setGapIdDetails] = useQueryParam('gap_id', GAP_ID_PARAM);
  const [apps, setApps] = useQueryParam('apps', APPS_PARAM);
  const [languages, setLanguages] = useQueryParam('lang', LANG_PARAM);
  const [filter, setFilter] = useQueryParam('filter', FILTER_PARAM);

  const errorBoundaryKey = [value, adTypes, period, tags, domains, rows, columns, channel, apps].flat().toString();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetGapIdDependencies = [rows, columns, value, period, domains, adTypes, channel, tags, languages];
  const initialResetGapIdDependencies = useRef(resetGapIdDependencies);

  const handleResetGapId = useCallback(() => {
    if (!isEqual(initialResetGapIdDependencies.current, resetGapIdDependencies)) {
      setGapIdDetails(null);
      initialResetGapIdDependencies.current = resetGapIdDependencies;
    }
  }, [resetGapIdDependencies, setGapIdDetails]);

  return {
    columns,
    setColumns,
    rows,
    setRows,
    value,
    setValue,
    domains,
    setDomains,
    period,
    setPeriod,
    tags,
    setTags,
    channel,
    setChannel,
    adTypes,
    setAdTypes,
    orderBy,
    setOrderBy,
    desc,
    setDesc,
    gapIdDetails,
    setGapIdDetails,
    errorBoundaryKey,
    handleResetGapId,
    apps,
    setApps,
    filter,
    setFilter,
    languages,
    setLanguages,
  };
};

export default useGapParams;
