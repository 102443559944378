import type { SelectChangeEvent } from '@mui/material';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TablePagination,
  LinearProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import type { FC } from 'react';
import { Suspense, useCallback, useRef, useState } from 'react';
import { DomainAutocomplete } from 'features/domains';
import { useDebouncedQueryParam, usePagination } from 'hooks';
import {
  StringParam,
  createEnumArrayParam,
  NumericArrayParam,
  useQueryParam,
  withDefault,
  BooleanParam,
} from 'use-query-params';
import { FlowsAutocomplete } from 'features/competitors-flows';
import type { AdType } from '../types';
import ChannelsAutocomplete from './ChannelsAutocomplete';
import WebCreativesList from './WebCreativesList';
import TypesAutocomplete from './TypeAutocomplete';
import FilterHeader from './FilterHeader';
import PeriodFilter from 'components/common/PeriodFilter';
import { useScrolled } from 'hooks/useScrolled';
import { TagsAutocomplete } from '../../tags';
import AppAutocomplete from '../../apps/components/AppAutocomplete';
import type { SearchLanguages } from 'types/shared';
import LanguageSelect from 'components/common/LanguageAutocomplete';

const sortingOptions = [
  [['max_impression', 'desc'].join(':'), 'Impressions (Desc)'],
  [['max_impression', 'asc'].join(':'), 'Impressions (Asc)'],
  [['latest_last_seen', 'desc'].join(':'), 'Last seen (Newer)'],
  [['latest_last_seen', 'asc'].join(':'), 'Last seen (Older)'],
  [['latest_first_seen', 'desc'].join(':'), 'First seen (Newer)'],
  [['latest_first_seen', 'asc'].join(':'), 'First seen (Older)'],
  [['created_at', 'desc'].join(':'), 'Newest'],
  [['created_at', 'asc'].join(':'), 'Oldest'],
];

const DEFAULT_PERIOD_PARAM = '7';

const PeriodParam = withDefault(StringParam, DEFAULT_PERIOD_PARAM);
const OrderParam = withDefault(StringParam, 'max_impression:desc');
const AdTypeParam = withDefault(createEnumArrayParam<AdType>(['carousel', 'html', 'image', 'video']), [] as AdType[]);
const DomainsParam = withDefault(NumericArrayParam, [] as number[]);
const FlowsParam = withDefault(NumericArrayParam, [] as number[]);
const TagsParam = withDefault(NumericArrayParam, [] as number[]);
const ChannelsParam = withDefault(NumericArrayParam, [] as number[]);
const FirstSeenParam = withDefault(BooleanParam, false);
const AppsParam = withDefault(NumericArrayParam, [] as number[]);
const LangParam = withDefault(createEnumArrayParam<SearchLanguages>(['en', 'es', 'pt', 'un']), [] as SearchLanguages[]);

const WebCreativesPage: FC = () => {
  const fixedHeaderRef = useRef(null);
  const isScrolled = useScrolled({ obj: fixedHeaderRef, scrollContainerId: 'web-creatives-page' });
  const {
    param: domains,
    actualValue: actualDomains,
    handleChange: setDomains,
  } = useDebouncedQueryParam<typeof DomainsParam, number[]>({ name: 'domains', defaultValue: DomainsParam });
  const {
    param: apps,
    actualValue: actualApps,
    handleChange: setApps,
  } = useDebouncedQueryParam<typeof AppsParam, number[]>({ name: 'apps', defaultValue: AppsParam });
  const {
    param: flows,
    actualValue: actualFlows,
    handleChange: setFlows,
  } = useDebouncedQueryParam<typeof FlowsParam, number[]>({ name: 'flows', defaultValue: FlowsParam });
  const {
    param: tags,
    actualValue: actualTags,
    handleChange: setTags,
  } = useDebouncedQueryParam<typeof TagsParam, number[]>({ name: 'tags', defaultValue: TagsParam });
  const {
    param: channels,
    actualValue: actualChannels,
    handleChange: setChannels,
  } = useDebouncedQueryParam<typeof ChannelsParam, number[]>({ name: 'channels', defaultValue: ChannelsParam });
  const {
    param: adTypes,
    actualValue: actualAdTypes,
    handleChange: setAdTypes,
  } = useDebouncedQueryParam<typeof AdTypeParam, AdType[]>({ name: 'ad_types', defaultValue: AdTypeParam });

  const {
    param: languages,
    actualValue: actualLanguages,
    handleChange: setLanguages,
  } = useDebouncedQueryParam<typeof LangParam, SearchLanguages[]>({ name: 'lang', defaultValue: LangParam });
  const [total, setTotal] = useState(0);
  const [firstSeen, setFirstSeen] = useQueryParam('first_seen', FirstSeenParam);
  const handleChannelsChange = useCallback(
    (val: number[]) => {
      setChannels(val);
    },
    [setChannels]
  );
  const [ordering, setOrdering] = useQueryParam('order_by', OrderParam);
  const handleOrderingChange = useCallback(
    (event: SelectChangeEvent) => {
      setOrdering(event.target.value);
    },
    [setOrdering]
  );
  const handleAdTypeChange = useCallback(
    (types: AdType[]) => {
      const next = [...types].sort();
      setAdTypes(next);
    },
    [setAdTypes]
  );
  const handleFlowsChange = useCallback(
    (flow: number[]) => {
      setFlows(flow);
    },
    [setFlows]
  );

  const handleAppsChange = useCallback(
    (apps: number[]) => {
      setApps(apps);
    },
    [setApps]
  );

  const handleTagsChange = useCallback(
    (tags: number[]) => {
      setTags(tags);
    },
    [setTags]
  );
  const handleDomainChange = useCallback(
    (dom: number[]) => {
      setFlows([]);
      setDomains(dom);
    },
    [setDomains, setFlows]
  );
  const [period, setPeriod] = useQueryParam('period', PeriodParam);
  const handlePeriodChange = useCallback(
    (event: SelectChangeEvent) => {
      setPeriod(event.target.value);
    },
    [setPeriod]
  );
  const handleLanguageChange = useCallback(
    (langs: SearchLanguages[]) => {
      setLanguages(langs);
    },
    [setLanguages]
  );

  const { page, perPage, setPerPage, handlePageChange, handleRowsPerPageChange, resetPagination } = usePagination([
    channels,
    ordering,
    adTypes,
    domains,
    period,
    flows,
    tags,
    languages,
  ]);
  const handleRowsPerPageSelectChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      setPerPage(event.target.value as number);
    },
    [setPerPage]
  );

  const handleReset = useCallback(() => {
    resetPagination();
    setPeriod(DEFAULT_PERIOD_PARAM);
    setFirstSeen(false);
    setDomains([]);
    setFlows([]);
    setAdTypes([]);
    setOrdering('max_impression:desc');
    setChannels([]);
    setTags([]);
    setApps([]);
    setLanguages([]);
  }, [
    resetPagination,
    setAdTypes,
    setChannels,
    setDomains,
    setOrdering,
    setPeriod,
    setFirstSeen,
    setFlows,
    setTags,
    setApps,
    setLanguages,
  ]);

  return (
    <>
      <Grid container spacing={1} rowSpacing={2} sx={{ mb: 2, px: 3 }}>
        <Grid item>
          <DomainAutocomplete
            size="small"
            value={actualDomains}
            InputProps={{
              label: 'Domains',
            }}
            fullWidth
            onChange={handleDomainChange}
          />
        </Grid>
        <Grid item>
          <FlowsAutocomplete
            size="small"
            value={actualFlows}
            domains={domains}
            InputProps={{
              label: 'Flow',
              disabled: !domains.length,
            }}
            onChange={handleFlowsChange}
          />
        </Grid>
        <Grid item>
          <AppAutocomplete
            size="small"
            value={actualApps}
            InputProps={{
              label: 'App',
            }}
            onChange={handleAppsChange}
          />
        </Grid>
        <Grid item>
          <TagsAutocomplete size="small" value={actualTags} onChange={handleTagsChange} />
        </Grid>
        <Grid item>
          <ChannelsAutocomplete
            size="small"
            value={actualChannels}
            InputProps={{
              label: 'Networks',
            }}
            onChange={handleChannelsChange}
          />
        </Grid>
        <Grid item>
          <TypesAutocomplete
            size="small"
            value={actualAdTypes}
            InputProps={{
              label: 'Ad Type',
            }}
            onChange={handleAdTypeChange}
          />
        </Grid>
        <Grid item>
          <LanguageSelect
            size="small"
            value={actualLanguages}
            InputProps={{
              label: 'Languages',
            }}
            onChange={handleLanguageChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        ref={fixedHeaderRef}
        sx={{
          px: 3,
          backgroundColor: isScrolled ? 'white' : 'transparent',
          transition: 'background-color .3s ease',
          position: 'sticky',
          zIndex: 10,
          left: 0,
          top: 0,
        }}
      >
        <FilterHeader
          selectedApps={actualApps}
          changeApps={handleAppsChange}
          selectedDomains={actualDomains}
          changeDomains={setDomains}
          selectedFlows={actualFlows}
          changeFlows={setFlows}
          selectedChannels={actualChannels}
          changeChannels={setChannels}
          selectedAdTypes={actualAdTypes}
          changeAdTypes={setAdTypes}
          selectedTags={actualTags}
          changeTags={setTags}
          selectedLanguages={actualLanguages}
          changeLanguages={setLanguages}
          handleReset={handleReset}
        />
      </Grid>
      <Grid sx={{ px: 3 }} justifyContent="space-between" container direction="row" alignItems="center">
        <Grid item container xs={6} display="flex" spacing={2} flexDirection="row" alignItems="center">
          <Grid item xs="auto" sx={{ minWidth: 240 }}>
            <PeriodFilter
              size="small"
              fullWidth
              inputLabel="Impressions period"
              value={period}
              onChange={handlePeriodChange}
            />
          </Grid>
          <Grid alignItems="center" item sx={{ minWidth: 300 }}>
            <FormControlLabel
              id="only-seen-label"
              control={
                <Checkbox
                  id="only-seen-checkbox"
                  checked={firstSeen}
                  onChange={(event) => {
                    setFirstSeen(event.target.checked);
                  }}
                  name="first_seen"
                />
              }
              label="Only first seen"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          display="flex"
          spacing={1}
          flexDirection="row"
          justifyContent="flex-end"
          sx={{ minWidth: 200, mb: 1 }}
        >
          <Grid item sx={{ minWidth: 240 }}>
            <FormControl fullWidth>
              <InputLabel id="sort">Sort by</InputLabel>
              <Select
                size="small"
                id="sort-list"
                labelId="sort"
                label="Sort by"
                fullWidth
                value={ordering}
                onChange={handleOrderingChange}
              >
                {sortingOptions.map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ minWidth: 80 }} id="perpage-control">
              <InputLabel id="per-page-label">Per page</InputLabel>
              <Select
                size="small"
                id="perpage-list"
                value={perPage}
                labelId="perPage"
                label="Per page"
                onChange={handleRowsPerPageSelectChange}
                inputProps={{
                  id: 'perpage-input',
                }}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ px: 3 }}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Suspense fallback={<LinearProgress />}>
            <WebCreativesList
              key="creatives-list"
              limit={perPage}
              offset={page * perPage}
              orderBy={ordering.split(':')[0]}
              desc={ordering.split(':')[1] === 'desc'}
              adType={adTypes.length ? adTypes : undefined}
              period={period}
              domains={domains as number[] | undefined}
              flows={flows as number[] | undefined}
              channels={channels as number[] | undefined}
              apps={apps}
              firstSeen={firstSeen}
              tags={tags}
              languages={languages}
              setTotal={setTotal}
              reset={handleReset}
            />
          </Suspense>
        </Grid>
        <Grid item xs={12}>
          <TablePagination
            id="creatives-pagination"
            component="div"
            count={total}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={perPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WebCreativesPage;
