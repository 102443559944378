import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { APIUsageDetailsEntry } from '../types';

interface GetAPIUsageDetailsOptions {
  started_after?: string;
  ended_before?: string;
}

export const getAPIUsageDetails = async (params?: GetAPIUsageDetailsOptions): Promise<APIUsageDetailsEntry[]> => {
  const res = await apiClient.get<{ data: APIUsageDetailsEntry[] }>(`/db-sync/reports`, { params });
  return res.data.data;
};

export const useAPIUsageDetails = <TData = APIUsageDetailsEntry[]>({
  config,
  params,
}: {
  config?: QueryConfig<typeof getAPIUsageDetails, TData>;
  params?: GetAPIUsageDetailsOptions;
} = {}) => {
  const { started_after, ended_before } = params ?? {};
  return useSuspenseQuery({
    ...config,
    queryFn: () => getAPIUsageDetails(params),
    queryKey: ['db-sync', 'reports', started_after, ended_before],
  });
};
