import type { FC } from 'react';
import type { SelectProps } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const IMPRESSIONS_PERIOD_OPTS = [
  { value: '7', label: 'Last week' },
  { value: '30', label: 'Last month' },
  { value: '90', label: 'Last quarter' },
  { value: 'lifetime', label: 'Lifetime' },
];

interface PeriodFilterProps extends SelectProps<string> {
  inputLabel?: string;
  options?: { value: string; label: string }[];
}
const PeriodFilter: FC<PeriodFilterProps> = ({
  value,
  onChange,
  inputLabel,
  options = IMPRESSIONS_PERIOD_OPTS,
  ...restProps
}) => (
  <FormControl fullWidth>
    {inputLabel && <InputLabel id="period-label">{inputLabel}</InputLabel>}
    <Select id="period-list" labelId="impressionsPeriod" label={inputLabel} value={value} onChange={onChange} inputProps={{ id: "period-input" }} {...restProps}>
      {options.map(({ value: optValue, label }) => (
        <MenuItem key={optValue} value={optValue}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default PeriodFilter;
