/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Box, Table, TableBody, TableContainer } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { useGap } from '../api/getGap';
import { useGapContext } from '../context/GapContext';
import useGapColors from '../hooks/useGapColor';
import useGapParams from '../hooks/useGapParams';
import GapTableHead from './GapTableHead';
import GapTableRowItem from './GapTableRowItem';

const GapTable = () => {
  const { selectedRows, onSelectRow, onSetSelectedRows } = useGapContext();
  const {
    orderBy,
    gapIdDetails,
    rows,
    columns,
    value,
    adTypes,
    desc,
    period,
    tags,
    domains,
    channel,
    setGapIdDetails,
    setDesc,
    setOrderBy,
    apps,
    filter,
    languages,
  } = useGapParams();

  const handleGapDetails = useCallback(
    (value: string | null) => {
      setGapIdDetails(value);
    },
    [setGapIdDetails]
  );

  const handleToggleSort = useCallback(
    (column: string) => {
      if (column === orderBy) {
        setDesc((prevState) => !prevState);
      } else {
        setOrderBy(column);
        setDesc(true);
      }
    },
    [orderBy, setOrderBy, setDesc]
  );

  const {
    data: { data: gapList },
  } = useGap({
    params: {
      rows,
      columns,
      value,
      domain_id: domains,
      period,
      tag_id: tags,
      channel,
      ad_type: adTypes,
      desc,
      order_by: orderBy,
      app_id: apps as number[],
      filter: filter!,
      language: languages,
    },
  });

  const { getGapColor } = useGapColors(gapList);

  useEffect(() => {
    if (!isEmpty(gapList)) {
      const defaultSelectedRow = gapList.rows.slice(0, 6).map(({ row_id }) => row_id);
      onSetSelectedRows(defaultSelectedRow);
    }
  }, [gapList, onSetSelectedRows]);

  const handleSelectRow = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      onSelectRow(Number(event.target.name));
    },
    [onSelectRow]
  );

  const handleSelectAllRows = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = gapList.rows.map(({ row_id }) => row_id);
        onSetSelectedRows(newSelected);
        return;
      }

      onSetSelectedRows([]);
    },
    [onSetSelectedRows, gapList.rows]
  );

  if (isEmpty(gapList.rows)) return null;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexGrow: gapIdDetails ? 0 : 1,
        width: gapIdDetails ? 400 : '100%',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: gapIdDetails ? '0' : 1,
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
      }}
    >
      <TableContainer
        sx={{
          overflow: 'scroll',
          width: '100%',
          height: '100%',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          borderTopRightRadius: gapIdDetails ? 0 : '4px',
        }}
      >
        <Table stickyHeader size="small">
          <GapTableHead
            gapList={gapList}
            gapIdDetails={gapIdDetails}
            onToggleSort={handleToggleSort}
            onSelectAllRows={handleSelectAllRows}
            numSelected={selectedRows.length}
            orderBy={orderBy}
            desc={desc}
          />
          <TableBody>
            {gapList.rows.map((row) => (
              <GapTableRowItem
                key={row.row_id}
                row={row}
                columns={gapList.columns}
                gapIdDetails={gapIdDetails}
                selectedRows={selectedRows}
                getGapColor={getGapColor}
                onSelectRow={handleSelectRow}
                onGapDetails={handleGapDetails}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GapTable;
