import { useEffect, useState } from 'react';
import type { GetGapEntry } from '../types';
import { isEmpty } from 'lodash-es';

type MapColorsEntry = Record<string, Record<string, string>>;

interface UseGapColorsEntry {
  getGapColor: (columnName: string, columnValue: string) => string;
}

const COLORS_GAPS = ['#CFC1FB', '#E1D8FD', '#F7F5FF'];

const useGapColors = (gapRows: GetGapEntry): UseGapColorsEntry => {
  const [mapColors, setMapColors] = useState<MapColorsEntry>({});
  useEffect(() => {
    const result = Object.entries(gapRows.columns).reduce<MapColorsEntry>((accum, [columnId, columnName]) => {
      const sortedColumnValues = gapRows.rows.map((rowCol) => rowCol.columns[columnId]).sort((a: string, b: string) => parseInt(b) - parseInt(a));
      const columnValues = sortedColumnValues.slice(0, COLORS_GAPS.length);
      return {
        ...accum,
        [columnName]: columnValues.reduce(
          (obj, columnValue, i) => ({
            ...obj,
            [columnValue]: COLORS_GAPS[i],
          }),
          {}
        ),
      };
    }, {});

    setMapColors(result);
  }, [gapRows]);

  const getGapColor = (columnName: string, columnValue: string): string => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isEmpty(mapColors) || !mapColors[columnName]) return 'white';
    return mapColors[columnName][columnValue];
  };

  return { getGapColor };
};

export default useGapColors;
