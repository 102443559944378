import { createContext, useContext } from 'react';

export interface GapContextEntry {
  selectedRows: number[];
  onSelectRow: (row: number) => void;
  onSetSelectedRows: (rows: number[]) => void;
};

export type GapContextType = GapContextEntry;

export const GapContext = createContext<GapContextType>({} as GapContextType);

export function useGapContext() {
  return useContext(GapContext);
}
