import type { FC } from "react";
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from "@mui/material";

const APIUsageDetailsSkeleton: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={t => ({ minHeight: 57, display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2, borderBottom: `1px solid ${t.palette.grey[300]}` })}>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Details</Typography>
        <Skeleton width={28} height={31} />
      </Box>
      <Box sx={{ px: 4, pt: 4, pb: 2 }}>
        <Skeleton width={140} height={44} />
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1, p: 2, height: '100%' }}>
        <Box sx={{ position: 'relative', flexGrow: 1 }}>
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ position: 'absolute', height: '100%', overflow: 'hidden' }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    align="center"
                    colSpan={4}
                    height={36}
                    sx={t => ({ backgroundColor: 'white', color: t.palette.grey[500] })}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                      <Skeleton width={240} height={26} />
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" sx={{ top: 37, backgroundColor: 'white' }}><Skeleton width={100} height={26} /></TableCell>
                  <TableCell component="th" sx={{ top: 37, backgroundColor: 'white' }}><Skeleton width={100} height={26} /></TableCell>
                  <TableCell component="th" sx={{ top: 37, backgroundColor: 'white' }}><Skeleton width={100} height={26} /></TableCell>
                  <TableCell component="th" sx={{ top: 37, backgroundColor: 'white' }}><Skeleton width={120} height={26} /></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(15).keys()].map(k => (
                  <TableRow key={k}>
                    <TableCell height={36}><Skeleton width={100} height={26} /></TableCell>
                    <TableCell><Skeleton width={60} height={26} /></TableCell>
                    <TableCell><Skeleton width={60} height={26} /></TableCell>
                    <TableCell><Skeleton width={60} height={26} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  )
};

export default APIUsageDetailsSkeleton;
