import { Box, Typography } from '@mui/material';
import { Gap, GapContextProvider } from 'features/gap';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Titled } from 'react-titled';

const GapPage: FC = () => (
  <>
    <Titled title={(title) => `Gap creatives | ${title}`} />
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, py: 3, pl: 3, pr: 1 }}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5">Gap creatives</Typography>
      </Box>
      <GapContextProvider>
        <Gap />
      </GapContextProvider>
    </Box>
    <Outlet />
  </>
);

export default GapPage;
