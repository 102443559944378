import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { TagGroupEntry } from '../types';

interface DeleteTagGroupPayload {
  group_id: number
}

export const deleteTagGroup = async (data: DeleteTagGroupPayload): Promise<TagGroupEntry> => {
  const res = await apiClient.delete<TagGroupEntry>(`/tag_groups/${data.group_id}`);
  return res.data;
};

interface UseDeleteTagGroupOptions {
  config?: MutationConfig<typeof deleteTagGroup>;
}

export const useDeleteTagGroup = ({ config }: UseDeleteTagGroupOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: ['tag_groups']
      });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: deleteTagGroup,
  });
};
