import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useGapParams from '../hooks/useGapParams';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { AdType } from '../../creatives';
import { isEmpty } from 'lodash-es';
import type { SearchLanguages } from '../../../types/shared';

interface RowProps {
  columnName: string;
  impressions: number;
  percentTotal: number;
  percentGrowth: number;
  creatives: number;
  color: string;
  columnId: string | number;
  generalColumnName: string;
}

const enNumberFormatter = Intl.NumberFormat('en-US');

const mapGapToCreativeFilter = (key: string) => {
  let creativeFilterKey = '';

  switch (key) {
    case 'ad_types':
      creativeFilterKey = 'ad_types';
      break;
    case 'networks':
      creativeFilterKey = 'channels';
      break;
    case 'domains':
      creativeFilterKey = 'domains';
      break;
    case 'apps':
      creativeFilterKey = 'apps';
      break;
    case 'languages':
      creativeFilterKey = 'lang';
      break;
    default:
      creativeFilterKey = 'tags';
  }

  return creativeFilterKey;
};

const GapDetailsTableRow: FC<RowProps> = ({
  impressions,
  columnName,
  creatives,
  percentTotal,
  percentGrowth,
  color,
  columnId,
}) => {
  const { period, rows, columns, channel, domains, tags, adTypes, gapIdDetails, apps, filter, languages } =
    useGapParams();

  const creativeRedirect = useMemo(() => {
    const params: Record<string, (number | null)[] | number[] | AdType[] | string | SearchLanguages[]> = {
      channels: channel,
      ...(filter === 'web' ? { domains } : { apps }),
      tags,
      ad_types: adTypes,
      period,
      lang: languages,
    };

    const rowFilter = mapGapToCreativeFilter(rows);
    const colFilter = mapGapToCreativeFilter(columns);

    const isBothTags = rowFilter === colFilter;

    const initialFilters = isBothTags
      ? {
          tags: [String(gapIdDetails), String(columnId)].toString(),
        }
      : {
          [rowFilter]: String(gapIdDetails),
          [colFilter]: String(columnId),
        };

    const excludedFilters = [rowFilter, colFilter];

    const creativeFilters = Object.keys(params)
      .filter((k) => !excludedFilters.includes(k))
      .reduce<Record<string, string>>((filters, currentKey) => {
        if (!isEmpty(params[currentKey])) {
          filters[currentKey] = String(params[currentKey]);
        }

        return filters;
      }, initialFilters);

    return new URLSearchParams(creativeFilters).toString();
  }, [channel, filter, domains, apps, tags, adTypes, period, rows, columns, gapIdDetails, languages, columnId]);

  return (
    <TableRow sx={{ height: 32 }}>
      <TableCell
        sx={{
          width: '30%',
          padding: '6px 12px',
        }}
      >
        <Box
          sx={{
            backgroundColor: color,
            width: 8,
            height: 8,
            borderRadius: '50%',
            display: 'inline-block',
            mr: 1,
          }}
        />
        {columnName}
      </TableCell>
      <TableCell
        sx={{
          width: '20%',
          padding: '6px 12px',
        }}
      >
        {enNumberFormatter.format(impressions)}
      </TableCell>
      <TableCell
        sx={{
          width: '15%',
          padding: '6px 12px',
        }}
      >
        {percentTotal.toFixed(2)}
      </TableCell>
      <TableCell
        sx={{
          width: '15%',
          padding: '6px 12px',
        }}
      >
        {percentGrowth.toFixed(2)}
      </TableCell>
      <TableCell
        sx={{
          width: '20%',
          padding: '6px 12px',
        }}
      >
        <Button
          component={Link}
          to={{
            pathname: '/web-creatives',
            search: creativeRedirect,
          }}
          variant="outlined"
          sx={{ padding: '4px 8px' }}
        >
          <Typography textAlign="center" fontSize={13}>
            {creatives}
          </Typography>
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default GapDetailsTableRow;
