import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, Stack, TextField, Typography, CircularProgress } from '@mui/material';
import { isAxiosError } from 'axios';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useCreateDomain } from '../api';
import { enqueueSnackbar } from 'notistack';
import { ProjectAutocomplete } from 'features/projects';
import { useSynchronousDomainById } from '../api/getDomainById';
import { useEditedDomainId } from '../hooks/useEditedDomainId';
import { useUpdateDomain } from '../api/updateDomain';
import { useQueryClient } from '@tanstack/react-query';

interface DomainDialogProps {
  isOpen: boolean;
  onClose: (action?: 'declined') => void;
}

const DomainDialog: FC<DomainDialogProps> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();
  const [editedDomainId, setEditedDomainId] = useEditedDomainId();

  const [projects, setProjects] = useState<number[]>([]);
  const [domain, setDomain] = useState<string>('');
  const [formError, setFormError] = useState<string | null>(null);

  const { data: domainData, isLoading } = useSynchronousDomainById({
    id: Number(editedDomainId),
    config: { enabled: !!editedDomainId },
  });

  useEffect(() => {
    if (domainData) {
      setDomain(domainData.domain);
      setProjects(domainData.projects.map((p) => p.id));
    }
  }, [domainData]);
  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(event.target.value);
  };

  const handleProjectChange = useCallback(
    (newProjects: number[]) => {
      setProjects(newProjects);
    },
    [setProjects]
  );

  const handleClose = () => {
    setDomain('');
    setFormError(null);
    setEditedDomainId(null);
    onClose();
  };

  const createDomain = useCreateDomain({
    config: {
      onSuccess: () => {
        enqueueSnackbar('New Domain was added!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        });
        handleClose();
      },
    },
  });

  const updateDomain = useUpdateDomain({
    config: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['domain', editedDomainId],
        });
        enqueueSnackbar('Domain has been edited!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        });
        handleClose();
      },
    },
  });

  const title = editedDomainId ? 'Edit' : 'Add';

  const handleSubmit = () => {
    try {
      if (editedDomainId) {
        updateDomain.mutate({ id: editedDomainId, data: { domain, projects } });
      } else {
        createDomain.mutate({ domain, projects });
      }
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          setFormError(e.response.data.message as string);
        }
      }
    }
  };

  return (
    <Dialog maxWidth="xs" onClose={handleClose} open={isOpen}>
      <Stack sx={{ p: 2, width: 350 }} spacing={4}>
        <Box>
          <Typography variant="h5">{title} domain</Typography>
          <Typography variant="body2">{title} a domain to scrap creatives from</Typography>
        </Box>
        {isLoading ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box>
              <TextField
                label="Domain"
                name="domain"
                fullWidth
                onChange={handleDomainChange}
                value={domain}
                error={Boolean(formError)}
                helperText={formError}
              />
            </Box>
            <Box>
              <ProjectAutocomplete
                InputProps={{
                  label: 'Project',
                }}
                fullWidth
                onChange={handleProjectChange}
                value={projects}
              />
            </Box>
          </>
        )}
        <Stack sx={{ mt: 4 }} spacing={2}>
          <LoadingButton
            size="large"
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading || updateDomain.isPending}
            fullWidth
            loading={createDomain.isPending || updateDomain.isPending}
          >
            {title}
          </LoadingButton>
          <Button
            disabled={isLoading || updateDomain.isPending}
            size="large"
            variant="outlined"
            onClick={handleClose}
            color="primary"
            fullWidth
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DomainDialog;
