import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { CreativeGroupDTO, UpdateCreativeGroupPayload } from './types';

interface UpdateCreativeOptions {
  id: number;
  data: UpdateCreativeGroupPayload;
}

export const updateCreative = async ({ id, data }: UpdateCreativeOptions): Promise<CreativeGroupDTO> => {
  const res = await apiClient.patch<CreativeGroupDTO>(`/creatives/${id}`, data);
  return res.data;
};

type MutationFnType = (data: UpdateCreativeGroupPayload) => ReturnType<typeof updateCreative>;

export interface UseUpdateCreativeOptions {
  id: number;
  config?: MutationConfig<MutationFnType>;
}

export const useUpdateCreative = ({ id, config }: UseUpdateCreativeOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['creatives']
      });

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: (data: UpdateCreativeGroupPayload) => updateCreative({ id, data }),
  });
};
