/* eslint-disable  @typescript-eslint/no-unsafe-member-access */
/* eslint-disable  @typescript-eslint/no-unnecessary-condition */
/* eslint-disable  @typescript-eslint/no-unsafe-assignment */
import { Box } from "@mui/system";
import type { FC } from "react";
import { useAPIUsageDetails } from "../api";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import type { CreativesStatsReportEntry } from "../types";
import getNextDate from "utils/getNextDate";
import { useDetailsSort } from "../hooks";
import { getAPIUsageDetailsFromDTO } from "../api";
import { isEmpty } from "lodash-es";

interface APIUsageDetailsProps {
  date: string,
  onDateDetails: (value: string | null) => void,
};

const APIUsageDetails: FC<APIUsageDetailsProps> = ({ date, onDateDetails }) => {
  const { data: creativesByDomainReport } = useAPIUsageDetails<CreativesStatsReportEntry[]>({
    params: {
      started_after: date,
      ended_before: getNextDate(date),
    },
    config: {
      select: (data) =>
        (data.length && data[0].creatives_stats_report)
          ? getAPIUsageDetailsFromDTO(data[0].creatives_stats_report)
          : [],
    },
  });

  const { currentSorting, sortedDetails, setSortOrder } = useDetailsSort(creativesByDomainReport, { order: 'asc', type: 'search_value' });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={t => ({ minHeight: 56, display: 'flex', alignItems: 'center', justifyContent: 'space-between', pl: 2, pr: 1, borderBottom: `1px solid ${t.palette.grey[300]}` })}>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Details</Typography>
        <IconButton onClick={() => { onDateDetails(null); }}><CloseIcon sx={{ fontSize: 21, px: 0 }} /></IconButton>
      </Box>
      <Box sx={{ px: 4, pt: 4, pb: 2 }}><Typography sx={{ fontSize: 20 }}>{date}</Typography></Box>
      <Box sx={{ display: 'flex', flexGrow: 1, p: 2, height: '100%' }}>
        <Box sx={{ position: 'relative', flexGrow: 1 }}>
          {!isEmpty(creativesByDomainReport) ? <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ position: 'absolute', height: '100%', top: 0, right: 0, left: 0, overflow: 'auto' }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    align="center"
                    colSpan={4}
                    height={36}
                    sx={t => ({ backgroundColor: 'white', color: t.palette.grey[500] })}>
                    Data received from Social Peta
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" sx={{ top: 37, backgroundColor: 'white' }}>
                    <TableSortLabel
                      active={currentSorting.type === 'search_value'}
                      direction={currentSorting.order}
                      onClick={() => {
                        setSortOrder({ order: currentSorting.order === 'asc' ? 'desc' : 'asc', type: 'search_value' });
                      }}
                    >
                      Competitor
                    </TableSortLabel>
                  </TableCell>
                  <TableCell component="th" sx={{ top: 37, backgroundColor: 'white' }}>
                    <TableSortLabel
                      active={currentSorting.type === 'created_creatives_count'}
                      direction={currentSorting.order}
                      onClick={() => {
                        setSortOrder({ order: currentSorting.order === 'asc' ? 'desc' : 'asc', type: 'created_creatives_count' });
                      }}
                    >
                      New creatives
                    </TableSortLabel>
                  </TableCell>
                  <TableCell component="th" sx={{ top: 37, backgroundColor: 'white' }}>
                    <TableSortLabel
                      active={currentSorting.type === 'updated_creatives_count'}
                      direction={currentSorting.order}
                      onClick={() => {
                        setSortOrder({ order: currentSorting.order === 'asc' ? 'desc' : 'asc', type: 'updated_creatives_count' });
                      }}
                    >
                      Updated creatives
                    </TableSortLabel>
                  </TableCell>
                  <TableCell component="th" sx={{ top: 37, backgroundColor: 'white' }}>
                    <TableSortLabel
                      active={currentSorting.type === 'total_in_sp'}
                      direction={currentSorting.order}
                      onClick={() => {
                        setSortOrder({ order: currentSorting.order === 'asc' ? 'desc' : 'asc', type: 'total_in_sp' });
                      }}
                    >
                      % from total in SP
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedDetails.map((item) => (
                  <TableRow key={`${item.search_value}.${item.created_creatives_count}`}>
                    <TableCell height={36}>{item.search_value}</TableCell>
                    <TableCell>{item.created_creatives_count}</TableCell>
                    <TableCell>{item.updated_creatives_count}</TableCell>
                    <TableCell>{item.total_in_sp}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> : <Box sx={{ px: 2 }}>No data received</Box>}
        </Box>
      </Box>
    </Box>
  )
};

export default APIUsageDetails;
