import { Box, CircularProgress } from '@mui/material';
import type { FC } from 'react';
const GapDetailsLoader: FC = () => {
  return (
    <Box
      sx={(t) => ({
        width: '100%',
        height: '100%',
        borderTopRightRadius: '4px',
        border: `1px solid ${t.palette.grey[300]}`,
        borderLeftColor: 'transparent',
      })}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};
export default GapDetailsLoader;
