import type { FC, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { getPersistedData, persistData } from 'utils/persist';
import type { ColumnKey, VisibleColumns } from './ColumnsSettingsContext';
import { ColumnSettingsContext } from './ColumnsSettingsContext';

const STORAGE_KEY = 'columnsSettings';

const DEFAULT_VISIBILITY_COLUMNS = {
  creatives: true,
  branch: true,
  impressions_meta: true,
  impressions_google: true,
  impressions_total: true,
  traffic_share: true,
  first_seen: true,
};

const ColumnsSettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [visibilityColumnsState, setColumnsSettings] = useState<VisibleColumns>(() => {
    const persistedData = getPersistedData(STORAGE_KEY);
    return {
      ...DEFAULT_VISIBILITY_COLUMNS,
      ...(persistedData || null),
    };
  });
  const [recentlyHiddenColumn, setRecentlyHiddenColumn] = useState('');

  const onChangeVisibilityColumn = useCallback((id: ColumnKey, checked: boolean) => {
    setColumnsSettings((prev: VisibleColumns) => {
      const newState = { ...prev, [id]: checked };
      if (!checked) {
        setRecentlyHiddenColumn(id);
      }
      persistData(STORAGE_KEY, newState);
      return newState;
    });
  }, []);

  const memoizedContextValue = useMemo(
    () => ({
      visibilityColumns: visibilityColumnsState,
      onChangeVisibilityColumn,
      recentlyHiddenColumn,
    }),
    [visibilityColumnsState, onChangeVisibilityColumn, recentlyHiddenColumn]
  );

  return <ColumnSettingsContext.Provider value={memoizedContextValue}>{children}</ColumnSettingsContext.Provider>;
};

export default ColumnsSettingsProvider;
