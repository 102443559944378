import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import type { FC } from 'react';
import { useDomains } from '../api';
import DomainRow from './DomainRow';
import TotalCompetitorsTitle from 'components/common/TotalCompetitorsTitle';

interface DomainsListProps {
  projects: number[];
}

const DomainsList: FC<DomainsListProps> = ({ projects }) => {
  const { data: domains } = useDomains({ params: { projects } });

  return (
    <>
      <TableContainer sx={{ flexGrow: 1, height: 0 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ th: { backgroundColor: 'white' } }}>
              <TableCell component="th">ID</TableCell>
              <TableCell component="th" sx={{ width: '25%' }}>
                Domain
              </TableCell>
              <TableCell component="th">Project</TableCell>
              <TableCell component="th">Created at</TableCell>
              <TableCell component="th" sx={{ backgroundColor: 'white' }} align='right'>
                {domains.length ? (<TotalCompetitorsTitle value={domains.length} />) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domains.map((domain) => (
              <DomainRow key={`domain_${domain.id}`} domain={domain} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DomainsList;
