import { Grid, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { Suspense, type FC, useEffect } from 'react';
import GapTable from './GapTable';
import GapTableSkeleton from './GapTableSkeleton';
import GapDetails from './GapDetails';
import GapFilters from './GapFilters';
import useGapParams from '../hooks/useGapParams';
import GapMultipleLineChart from './GapMultipleLineChart';
import { ErrorBoundary } from 'react-error-boundary';
import GapMainChartEmptyState from './GapMainChartEmptyState';
import GapDetailsLoader from './GapDetailsLoader';
import GapFiltersAndChartLoader from './GapFiltersAndChartLoader';

const Gap: FC = () => {
  const { gapIdDetails, errorBoundaryKey, handleResetGapId } = useGapParams();

  useEffect(() => {
    handleResetGapId();
  }, [handleResetGapId]);

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%' }}>
      <Grid container spacing={2} marginBottom={4} width={'100%'} flexDirection={'row'}>
        <Grid item xs={6} xl={5} display={'flex'} sx={{ height: '100%' }}>
          <Paper sx={{ display: 'flex', flexGrow: 1 }} variant="outlined">
            <Suspense fallback={<GapFiltersAndChartLoader />}>
              <GapFilters />
            </Suspense>
          </Paper>
        </Grid>
        <Grid item xs={6} xl={7} display={'flex'} sx={{ height: '100%' }} height={320}>
          <Paper sx={{ display: 'flex', flexGrow: 1 }} variant="outlined">
            <ErrorBoundary key={errorBoundaryKey} fallbackRender={() => <GapMainChartEmptyState variant="error" />}>
              <Suspense fallback={<GapFiltersAndChartLoader />}>
                <GapMultipleLineChart />
              </Suspense>
            </ErrorBoundary>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingRight={2} paddingLeft={2}>
        <ErrorBoundary key={errorBoundaryKey} fallbackRender={() => null}>
          <Paper
            sx={{
              display: 'flex',
              width: '100%',
              border: 'none',
              minHeight: gapIdDetails ? 480 : 0,
              maxHeight: 750,
            }}
            variant="outlined"
          >
            <Suspense fallback={<GapTableSkeleton />}>
              <GapTable />
            </Suspense>
            {gapIdDetails && (
              <Box sx={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
                <Suspense fallback={<GapDetailsLoader />}>
                  <GapDetails />
                </Suspense>
              </Box>
            )}
          </Paper>
        </ErrorBoundary>
      </Grid>
    </Box>
  );
};

export default Gap;
