import { Link, Typography } from '@mui/material';
import config from 'config';
import isProd from '../../utils/isProd';

const EnvironmentBanner = () => {
  const prefix = config.ADMIN_API_URL.includes('dev') ? 'DEV' : 'STAGE';

  if (isProd()) return null;

  return (
    <Typography
      sx={{ background: 'red', width: '100%', p: 1, color: 'white', fontWeight: 'bold', textAlign: 'center' }}
    >
      This is the {prefix} environment used for development purposes. To work with the stable version and real data,
      please use{' '}
      <Link sx={{ color: 'white', textDecorationColor: 'white' }} href="https://wellspy-admin.asqq.io/" target="_blank">
        PRODUCTION
      </Link>
    </Typography>
  );
};

export default EnvironmentBanner;
