
import {
  Button,
  Popper,
  Fade,
  Paper,
  FormLabel,
  FormControlLabel,
  FormGroup,
  FormControl,
  Checkbox,
  Divider,
  Box,
  ClickAwayListener,
} from '@mui/material';
import type { FC} from 'react';
import type React from 'react';
import { Fragment } from 'react';
import { Settings as SettingsIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { useAnchorMenu } from 'hooks';
import type { ColumnKey } from '../providers/ColumnsSettingsContext';
import { useColumnsSettingsContext } from '../providers/ColumnsSettingsContext';

interface ColumnsSettingsEntry {
  name: string;
  id: ColumnKey;
}

const columnsSettings: ColumnsSettingsEntry[] = [
  { name: 'Creatives', id: 'creatives' },
  { name: 'Branch', id: 'branch' },
  { name: 'Meta', id: 'impressions_meta' },
  { name: 'Google', id: 'impressions_google' },
  { name: 'Total', id: 'impressions_total' },
  { name: 'Traffic share', id: 'traffic_share' },
  { name: 'First seen', id: 'first_seen' },
];

const CompetitorsFlowsColumnsSettings: FC = () => {
  const { visibilityColumns, onChangeVisibilityColumn } = useColumnsSettingsContext();
  const anchorState = useAnchorMenu();
  const popperId = anchorState.isOpen ? 'columnsSettingsPopper' : undefined;

  const handleToggle: React.MouseEventHandler<HTMLElement> = (event) => {
    anchorState.isOpen ? anchorState.handleCloseMenu() : anchorState.handleOpenMenu(event);
  };

  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'start', flexGrow: 1 }}>
      <Button
        id="columns-settings-button"
        aria-describedby={popperId}
        variant="outlined"
        sx={{ pl: 1, pr: 0, borderRadius: 2, maxHeight: 38, }}
        onClick={handleToggle}>
        <SettingsIcon fontSize="medium" />
        <ArrowDropDownIcon />
      </Button>
      <Popper
        id={popperId}
        sx={{ zIndex: 4 }}
        open={anchorState.isOpen}
        anchorEl={anchorState.anchor}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={anchorState.handleCloseMenu}>
            <Fade {...TransitionProps} timeout={100}>
              <Paper sx={{ width: 320 }}>
                <FormControl sx={{ mt: 2, px: 2, width: '100%' }} component="fieldset" variant="standard">
                  <FormLabel component="legend">Visibility of the columns</FormLabel>
                  <FormGroup>
                    {columnsSettings.map(({ name, id }, i) => (
                      <Fragment key={id}>
                        <FormControlLabel
                          id={`column-setting-${id}-label`}
                          sx={{ py: 0.5, }}
                          control={
                            <Checkbox
                              id={`column-setting-${id}-checkbox`}
                              checked={visibilityColumns[id]}
                              onChange={(event) => { onChangeVisibilityColumn(id, event.target.checked); }}
                              name={id}
                            />
                          }
                          label={name}
                        />
                        {i !== columnsSettings.length - 1 && <Divider />}
                      </Fragment>
                    ))}
                  </FormGroup>
                </FormControl>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

export default CompetitorsFlowsColumnsSettings;
