import { apiClient } from 'lib/api';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';

export interface DeleteAppOptions {
  id: number;
}

export const deleteApp = async ({ id }: DeleteAppOptions): Promise<void> => {
  await apiClient.delete(`/apps/${id}`);
};

export interface UseDeleteAppOptions {
  config?: MutationConfig<typeof deleteApp>;
}

export const useDeleteApp = ({ config }: UseDeleteAppOptions = {}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['apps'],
      });

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: deleteApp,
  });

  return mutation;
};
