import type { TextFieldProps, AutocompleteValue } from '@mui/material';
import { Tooltip } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useFlowsAutocomplete } from '../api';
import type { Option, OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';
import type { AutocompleteSize } from 'types/utils';

interface FlowsAutocompleteProps {
  id?: string,
  domains?: number[] | undefined;
  value?: AutocompleteValue<number, true, false, false>;
  onChange: (value: AutocompleteValue<number, true, false, false>) => void;
  InputProps?: TextFieldProps;
  size?: AutocompleteSize
}

const FlowsAutocomplete = ({ InputProps, onChange, value, domains, ...props }: FlowsAutocompleteProps): JSX.Element => {
  const { data: flows, isPending } = useFlowsAutocomplete<Option[]>({
    params: { domain_id: domains },
    config: {
      enabled: !isEmpty(domains),
      placeholderData: [],
      select: (data) => data.map((flow) => ({ value: flow.id, label: flow.flow })),
    },
  });

  return (
    <Tooltip title={InputProps?.disabled && 'Choose domain first'} arrow>
      <CheckboxAutocomplete
        {...props}
        isLoading={isPending}
        value={value}
        InputProps={InputProps}
        onChange={onChange as (value: OptionValue[]) => void}
        id="flows-autocomplete"
        options={flows ?? []}
      />
    </Tooltip>
  );
};

export default FlowsAutocomplete;
