/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import type { SelectChangeEvent, TabProps } from '@mui/material';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { TagsAutocomplete, useTagGroups } from '../../tags';
import type { Option } from '../../../components/common/CheckboxAutocomplete';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { DomainAutocomplete } from '../../domains';
import { useDebouncedQueryParam, useModal } from '../../../hooks';
import PeriodFilter from '../../../components/common/PeriodFilter';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ChannelsAutocomplete, TypesAutocomplete } from '../../creatives';
import type { AdType } from '../../creatives';
import { persistData } from '../../../utils/persist';
import { useLocation } from 'react-router';
import { AD_TYPE_PARAM, APPS_PARAM, CHANNELS_PARAM, DOMAINS_PARAM, LANG_PARAM, TAGS_PARAM } from '../constants';
import useGapParams from '../hooks/useGapParams';
import AppAutocomplete from '../../apps/components/AppAutocomplete';
import type { FilterType } from '../types';
import type { SearchLanguages } from '../../../types/shared';
import LanguageSelect from '../../../components/common/LanguageAutocomplete';

const StaticOptions = [
  { label: 'Networks', value: 'networks' },
  { label: 'Ad types', value: 'ad_types' },
  { label: 'Languages', value: 'languages' },
];

interface StyledTabsProps {
  children?: React.ReactNode;
  value: FilterType;
  onChange: (event: React.SyntheticEvent, newValue: FilterType) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: 'transparent',
  },
});

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  backgroundColor: 'white',
  fontSize: 14,
  marginRight: theme.spacing(1),
  border: '1px solid rgba(0, 0, 0, 0.12)',
  padding: 0,
  minWidth: 44,
  minHeight: 24,
  fontWeight: 400,
  margin: 0,
  color: 'black',
  '&.Mui-selected': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    color: 'black',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const GapFilters = () => {
  const location = useLocation();
  const { isOpen, toggle } = useModal();
  const { rows, setRows, columns, setColumns, value, setValue, period, setPeriod, setGapIdDetails, filter, setFilter } =
    useGapParams();
  const { actualValue: actualDomains, handleChange: setDomains } = useDebouncedQueryParam<
    typeof DOMAINS_PARAM,
    number[]
  >({
    name: 'domains',
    defaultValue: DOMAINS_PARAM,
  });
  const { actualValue: actualApps, handleChange: setApps } = useDebouncedQueryParam<typeof APPS_PARAM, number[]>({
    name: 'apps',
    defaultValue: APPS_PARAM,
  });

  const { actualValue: actualChannels, handleChange: setChannels } = useDebouncedQueryParam<
    typeof CHANNELS_PARAM,
    number[]
  >({ name: 'channels', defaultValue: CHANNELS_PARAM });
  const { actualValue: actualAdTypes, handleChange: setAdTypes } = useDebouncedQueryParam<
    typeof AD_TYPE_PARAM,
    AdType[]
  >({ name: 'ad_types', defaultValue: AD_TYPE_PARAM });
  const { actualValue: actualTags, handleChange: setTags } = useDebouncedQueryParam<typeof TAGS_PARAM, number[]>({
    name: 'tags',
    defaultValue: TAGS_PARAM,
  });
  const { actualValue: actualLanguages, handleChange: setLanguages } = useDebouncedQueryParam<
    typeof LANG_PARAM,
    SearchLanguages[]
  >({ name: 'lang', defaultValue: LANG_PARAM });

  const handleTabChange = (event: SyntheticEvent, newValue: FilterType) => {
    setFilter(newValue);

    const replaceValue = newValue === 'web' ? 'domains' : 'apps';

    if (rows === 'domains' || rows === 'apps') {
      setRows(replaceValue);
    } else if (columns === 'domains' || columns === 'apps') setColumns(replaceValue);
  };

  const handleDomainChange = useCallback(
    (dom: number[]) => {
      if (dom.length > 0) {
        setDomains(dom);
      }
    },
    [setDomains]
  );

  const handleAppChange = useCallback(
    (app: number[]) => {
      setApps(app);
    },
    [setApps]
  );

  const handlePeriodChange = useCallback(
    (event: SelectChangeEvent) => {
      setPeriod(event.target.value);
    },
    [setPeriod]
  );

  const handleChangeIntersectionValue = useCallback(
    (event: SelectChangeEvent) => {
      setValue(event.target.value);
    },
    [setValue]
  );

  const handleColumnChange = useCallback(
    (_: React.SyntheticEvent, newValue: Option) => {
      setColumns(String(newValue.value));
    },
    [setColumns]
  );

  const handleRowChange = useCallback(
    (_: React.SyntheticEvent, newValue: Option) => {
      setRows(String(newValue.value));
    },
    [setRows]
  );

  const handleAdTypeChange = useCallback(
    (types: AdType[]) => {
      const next = [...types].sort();
      setAdTypes(next);
    },
    [setAdTypes]
  );

  const handleTagsChange = useCallback(
    (tags: number[]) => {
      setTags(tags);
    },
    [setTags]
  );

  const handleChannelsChange = useCallback(
    (val: number[]) => {
      setChannels(val);
    },
    [setChannels]
  );

  const handleLanguageChange = useCallback(
    (langs: SearchLanguages[]) => {
      setLanguages(langs);
    },
    [setLanguages]
  );

  useEffect(() => {
    persistData('gap_search', location.search);
  }, [location]);

  const { data: tagGroupsList } = useTagGroups<Option[]>({
    config: {
      select: (data) => data.data.map((tagGroup) => ({ value: tagGroup.group_id, label: tagGroup.group_name })),
    },
  });

  const isWeb = filter === 'web';

  const [columnOptions, rowOptions] = useMemo(() => {
    const options = [
      isWeb ? { label: 'Domains', value: 'domains' } : { label: 'Apps', value: 'apps' },
      ...StaticOptions,
      ...tagGroupsList,
    ];

    const columnOptions = rows ? options.filter((opt) => opt.value != rows) : options;
    const rowOptions = columns ? options.filter((opt) => opt.value != columns) : options;

    return [columnOptions, rowOptions];
  }, [columns, rows, tagGroupsList, isWeb]);

  const derivedColumnValue = useMemo(() => columnOptions.find((o) => o.value == columns), [columns, columnOptions]);
  const derivedRowValue = useMemo(() => rowOptions.find((o) => o.value == rows), [rows, rowOptions]);

  const swapFilters = () => {
    setGapIdDetails(null);
    const rowValue = rows;
    setRows(columns);
    setColumns(rowValue);
  };

  return (
    <Grid container direction="column" sx={{ py: '16px', px: '12px' }}>
      <Typography fontWeight={700} fontSize={14} mb={2}>
        Set up breakdown
      </Typography>
      <Grid item container direction="row" alignItems="center" sx={{ gap: 1, mb: 4 }}>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Autocomplete
            size="small"
            disableClearable
            value={derivedRowValue}
            onChange={handleRowChange}
            id="rows-autocomplete"
            fullWidth
            options={rowOptions}
            sx={{ minWidth: 150 }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Rows" placeholder="Rows" />}
          />
        </Box>
        <IconButton size="small" sx={{ height: 40 }} onClick={swapFilters}>
          <MultipleStopIcon />
        </IconButton>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Autocomplete
            size="small"
            disableClearable
            value={derivedColumnValue}
            onChange={handleColumnChange}
            id="columns-autocomplete"
            fullWidth
            options={columnOptions}
            sx={{ minWidth: 150 }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Columns" placeholder="Columns" />}
          />
        </Box>
        <FormControl sx={{ display: 'flex', flexGrow: 1 }}>
          <InputLabel>Value</InputLabel>
          <Select
            size="small"
            value={value}
            onChange={handleChangeIntersectionValue}
            sx={{ minWidth: 150 }}
            fullWidth
            placeholder="Value"
            label="Value"
            id="value-list"
          >
            <MenuItem value="impressions">Impressions</MenuItem>
            <MenuItem value="total">% of total</MenuItem>
            <MenuItem value="growth">% of growth</MenuItem>
            <MenuItem value="creatives">Creatives</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container>
        <Typography fontWeight={700} mb={2} mr={1}>
          Filters
        </Typography>
        <Box>
          <StyledTabs value={filter!} onChange={handleTabChange}>
            <StyledTab
              id="filter-web-tab"
              value="web"
              sx={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}
              label="Web"
            />
            <StyledTab
              id="filter-app-tab"
              value="app"
              sx={{ borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
              label="App"
            />
          </StyledTabs>
        </Box>
        <Grid container item direction="row" sx={{ mb: 2, gap: 1 }}>
          <Grid item xs={6}>
            {isWeb ? (
              <DomainAutocomplete
                size="small"
                allSelectable={false}
                value={actualDomains}
                disableClearable
                InputProps={{
                  label: 'Domains',
                }}
                onChange={handleDomainChange}
              />
            ) : (
              <AppAutocomplete
                size="small"
                onChange={handleAppChange}
                value={actualApps}
                InputProps={{
                  label: 'Apps',
                }}
              />
            )}
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            <PeriodFilter
              options={[
                { value: '30', label: 'Last month' },
                { value: '90', label: 'Last quarter' },
              ]}
              size="small"
              inputLabel="Period"
              value={period}
              onChange={handlePeriodChange}
              fullWidth
            />
          </Grid>
        </Grid>
        {isOpen && (
          <>
            <Grid container sx={{ gap: 1, mb: 2 }}>
              <Grid item sx={{ flex: 1 }}>
                <TagsAutocomplete size="small" value={actualTags} onChange={handleTagsChange} />
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <ChannelsAutocomplete
                  allSelectable={false}
                  size="small"
                  value={actualChannels}
                  InputProps={{
                    label: 'Networks',
                  }}
                  onChange={handleChannelsChange}
                />
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <TypesAutocomplete
                  size="small"
                  value={actualAdTypes}
                  InputProps={{
                    label: 'Ad Type',
                  }}
                  onChange={handleAdTypeChange}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ gap: 1, mb: 2 }}>
              <Grid item sx={{ flex: 1 / 3 }}>
                <LanguageSelect
                  size="small"
                  value={actualLanguages}
                  InputProps={{
                    label: 'Languages',
                  }}
                  onChange={handleLanguageChange}
                />
              </Grid>
              <Grid item sx={{ flex: 2 / 3 }}></Grid>
            </Grid>
          </>
        )}
        <Button
          id="toggle-filter-button"
          startIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          onClick={toggle}
        >
          <Typography sx={{ fontWeight: 500, fontSize: 14, textTransform: 'uppercase' }}>
            {isOpen ? 'less' : 'more'} filters
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default GapFilters;
