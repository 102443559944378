/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { type FC } from 'react';
import { TableRow, TableHead, TableCell, Checkbox, TableSortLabel } from '@mui/material';
import type { GetGapEntry } from '../types';

interface GapTableHead {
  gapList: GetGapEntry;
  gapIdDetails: string | null;
  numSelected: number;
  orderBy: string | undefined;
  desc: boolean | undefined;
  onToggleSort: (column: string) => void;
  onSelectAllRows: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const GapTableHead: FC<GapTableHead> = ({
  gapList,
  gapIdDetails,
  numSelected,
  orderBy,
  desc,
  onSelectAllRows,
  onToggleSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          colSpan={2}
          width={200}
          height={42}
          sx={(t) => ({
            minWidth: 200,
            zIndex: 6,
            position: 'sticky',
            left: 0,
            borderRight: gapIdDetails ? 'none' : `1px solid ${t.palette.grey[300]}`,
          })}
        >
          {gapList.rows_name}
        </TableCell>
        {!gapIdDetails && <TableCell colSpan={Object.keys(gapList.columns).length}>{gapList.values_name}</TableCell>}
      </TableRow>
      <TableRow>
        <TableCell
          width={42}
          height={42}
          sx={{ minWidth: 42, zIndex: 6, position: 'sticky', left: 0, top: 42 }}
          padding="checkbox"
        >
          <Checkbox color="primary" onChange={onSelectAllRows} checked={numSelected === gapList.rows.length} />
        </TableCell>
        <TableCell
          sx={(t) => ({
            pl: 0.5,
            zIndex: 6,
            position: 'sticky',
            left: 52,
            top: 42,
            borderRight: gapIdDetails ? 'none' : `1px solid ${t.palette.grey[300]}`,
          })}
        >
          Show on chart
        </TableCell>
        {!gapIdDetails &&
          Object.entries(gapList.columns).map(([key, name], i) => (
            <TableCell
              key={name}
              sx={(t) => ({
                wordBreak: 'break-all',
                minWidth: 200,
                top: 42,
                borderRight:
                  Object.keys(gapList.columns).length - 1 !== i ? `1px solid ${t.palette.grey[300]}` : 'none',
              })}
            >
              <TableSortLabel
                active={orderBy === key}
                direction={desc ? 'desc' : 'asc'}
                onClick={() => {
                  onToggleSort(key);
                }}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {name}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default GapTableHead;
