import { apiClient } from 'lib/api';
import type { QueryConfig } from '../../../lib/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { App, AppEntry } from '../types';
import getAppFromDTO from './getAppFromDTO';
import queryString from 'query-string';

interface GetAppsParams {
  projects?: number[];
}
export const getApps = async (params?: GetAppsParams, signal?: AbortSignal): Promise<AppEntry[]> => {
  const res = await apiClient.get<AppEntry[]>(`/apps`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

export const useApps = <TData = App[]>({
  config,
  params,
}: { config?: QueryConfig<typeof getApps, TData>; params?: GetAppsParams } = {}) => {
  const { projects } = { ...params };

  return useSuspenseQuery({
    staleTime: 60 * 60 * 1000,
    select: (data) => data.map((a) => getAppFromDTO(a)) as TData,
    ...config,
    queryFn: ({ signal }) => getApps({ projects }, signal),
    queryKey: ['apps', projects],
  });
};
