/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { LoadingButton } from '@mui/lab';
import type { SelectChangeEvent } from '@mui/material';
import { Box, Button, Dialog, Stack, TextField, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import TagGroupSelect from './TagGroupSelect';
import { useCreateTag } from '../api';
import { isAxiosError } from 'axios';
import type { TagAutocompleteOption, TagEntry } from '../types';

interface AddTagDialogProps {
  isOpen: boolean;
  close: () => void;
  onChange: (value: TagAutocompleteOption[]) => void;
  value: TagAutocompleteOption[];
  dialogValue: string;
}

const AddTagDialog: FC<AddTagDialogProps> = ({ isOpen, close, onChange, value, dialogValue }: AddTagDialogProps) => {
  const [tagName, setTagName] = useState<string>('');
  const [tagGroup, setTagGroup] = useState<number | null>(null);
  const [tagNameFormError, setTagNameFormError] = useState<string | null>(null);
  const [tagGroupFormError, setTagGroupFormError] = useState<string | null>(null);

  const createTag = useCreateTag(Number(tagGroup), {
    config: {
      onSuccess: (result: TagEntry) => {
        onChange([...value, { id: result.tag_id, label: result.tag_name, groupId: Number(tagGroup) }]);
        setTagName('');
        setTagNameFormError(null);
        setTagGroupFormError(null);
        handleClose();
      },
      onError: (e) => {
        if (isAxiosError(e) && e.response) {
          setTagNameFormError((e.response.data as { message: string }).message);
        }
      },
    },
  });
  const handleTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
    setTagNameFormError('');
  };

  const handleTagGroupChange = (event: SelectChangeEvent<number>) => {
    setTagGroup(Number(event.target.value));
    setTagGroupFormError('');
  };

  const handleSubmit = async () => {
    await createTag.mutateAsync({ group_id: Number(tagGroup), tag_name: tagName.trim() });
  };
  const handleClose = () => {
    setTagNameFormError('');
    setTagGroupFormError('');
    setTagName('');
    setTagGroup(null);
    close();
  };

  useEffect(() => {
    setTagName(dialogValue);
  }, [dialogValue]);

  return (
    <Dialog maxWidth="xs" onClose={handleClose} open={isOpen} disableRestoreFocus>
      <Stack sx={{ p: 2, width: 350 }} spacing={4}>
        <Box>
          <Typography variant="h5">Add Tag</Typography>
        </Box>
        <Box>
          <TextField
            required
            defaultValue={dialogValue}
            label="Tag name"
            name="tagName"
            fullWidth
            onChange={handleTagNameChange}
            value={tagName}
            error={Boolean(tagNameFormError)}
            helperText={tagNameFormError}
            onBlur={(e) => {
              !e.target.value.length && setTagNameFormError('Field is required');
            }}
          />
        </Box>
        <Box>
          <TagGroupSelect
            value={tagGroup!}
            required
            error={Boolean(tagGroupFormError)}
            inputLabel="Group name"
            onChange={handleTagGroupChange}
            label="Group name"
            name="tagGroup"
          />
        </Box>
        <Stack direction="row" sx={{ mt: 4 }} spacing={2}>
          <Button
            disabled={createTag.isPending}
            size="large"
            variant="outlined"
            onClick={handleClose}
            color="primary"
            fullWidth
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            disabled={!tagName || !tagGroup || createTag.isPending}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AddTagDialog;
