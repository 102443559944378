import { Grid, Typography } from '@mui/material';
import type { FC } from 'react';

interface GapDetailsTableHeadProps {
  columnName: string;
}
const GapDetailsTableHead: FC<GapDetailsTableHeadProps> = ({ columnName }) => {
  return (
    <Grid container>
      <Grid
        sx={{
          backgroundColor: '#F1F4F8',
          py: 0.75,
          px: 1.5,
        }}
        item
        xs={12}
      >
        <Typography fontSize={14} fontWeight={700}>
          All metrics
        </Typography>
      </Grid>
      <Grid item container sx={{ backgroundColor: 'white', py: 0.75 }}>
        <Grid
          item
          sx={{
            opacity: '60%',
            width: '30%',
            px: 1.5,
          }}
        >
          <Typography color="black" fontSize={14} fontWeight={500}>
            {columnName}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            opacity: '60%',
            width: '20%',
            px: 1.5,
          }}
        >
          <Typography color="black" fontSize={14} fontWeight={500}>
            Impressions
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            opacity: '60%',
            width: '15%',
            px: 1.5,
          }}
        >
          <Typography color="black" fontSize={14} fontWeight={500}>
            % of total
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            opacity: '60%',
            width: '15%',
            px: 1.5,
          }}
        >
          <Typography color="black" fontSize={14} fontWeight={500}>
            % growth
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            opacity: '60%',
            width: '20%',
            px: 1.5,
          }}
        >
          <Typography color="black" fontSize={14} fontWeight={500}>
            Creatives
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GapDetailsTableHead;
