import { useSuspenseQuery } from '@tanstack/react-query';
import type { ListResponse, PaginationParams, SortingParams } from 'lib/api';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { CompetitorDomain } from '../types';
import getCompetitorsFlowsFromDTO from './getCompetitorsFlowsFromDTO';
import type { CompetitorsFlowsDTO, FilteringFields } from './types';

export interface GetCompetitorsFlowsOptions {
  params: PaginationParams & Partial<SortingParams> & Partial<FilteringFields>;
}

export const getCompetitorsFlows = async (
  { params }: GetCompetitorsFlowsOptions,
  signal?: AbortSignal
): Promise<ListResponse<CompetitorsFlowsDTO>> => {
  const res = await apiClient.get<ListResponse<CompetitorsFlowsDTO>>(`/flows`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseCompetitorsFlowsOptions<TData> {
  config?: QueryConfig<typeof getCompetitorsFlows, TData>;
  params: GetCompetitorsFlowsOptions['params'];
}

export const useCompetitorsFlows = <TData = ListResponse<CompetitorDomain>>({
  config,
  params,
}: UseCompetitorsFlowsOptions<TData>) => {
  const { order_by, offset, limit, domain_id, branch_id, desc, period, language } = params;

  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    select: (data) => ({ ...data, data: data.data.map((flows) => getCompetitorsFlowsFromDTO(flows)) }),
    queryFn: ({ signal }) => getCompetitorsFlows({ params }, signal),
    queryKey: ['flows', offset, limit, domain_id, branch_id, order_by, desc, period, language],
  });
};
