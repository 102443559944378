import type { FC } from 'react';
import { useMemo } from 'react';
import type { TextFieldProps, AutocompleteProps } from '@mui/material';
import { Autocomplete, Checkbox, createFilterOptions, TextField, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import type * as React from 'react';
import type { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import type { AutocompleteChangeReason, AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete';
import { intersection } from 'lodash-es';
import { useModal } from 'hooks';
import type { AutocompleteSize } from 'types/utils';

export type OptionValue = number | string | number[];

export interface Option {
  value: OptionValue;
  label: string;
}

interface CheckboxAutocompleteProps
  extends Pick<AutocompleteProps<OptionValue, true, boolean, false>, 'disableClearable'> {
  value?: OptionValue[];
  onChange: (value: OptionValue[]) => void;
  InputProps?: TextFieldProps;
  id: string;
  isLoading?: boolean;
  options: Option[];
  allSelectable?: boolean;
  size?: AutocompleteSize;
}

const CheckboxAutocomplete: FC<CheckboxAutocompleteProps> = ({
  id,
  options,
  isLoading,
  onChange,
  value,
  InputProps,
  allSelectable = true,
  ...props
}: CheckboxAutocompleteProps) => {
  const { isOpen: isFocused, open: onFocus, close: onBlur } = useModal(false);
  const derivedValue = useMemo(() => options.filter((o) => value?.includes(o.value)), [value, options]);
  const allSelected = options.length === derivedValue.length;

  const handleSelectAll = () => {
    if (allSelected) {
      onChange([]);
    } else {
      onChange(options.filter((o) => o.value !== 'select-all').map((o) => o.value));
    }
  };

  const filter = createFilterOptions<Option>();

  const optionRenderer = (
    renderProps: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    { selected }: AutocompleteRenderOptionState
  ) => {
    const selectAllProps = option.value === 'select-all' ? { checked: allSelected } : {};
    let isComplexOptionSelected = false;
    if (typeof option.value === 'object') {
      isComplexOptionSelected =
        !!option.value.length && intersection(value, option.value).length === option.value.length;
    }
    return (
      <li {...renderProps} aria-selected={selected || isComplexOptionSelected}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckBoxIcon />}
          checked={selected || isComplexOptionSelected}
          {...selectAllProps}
        />
        {option.label}
      </li>
    );
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: AutocompleteValue<Option, true, false, false>,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (newValue.find((option) => option.value === 'select-all')) {
        handleSelectAll();
      } else {
        onChange(newValue.map((o) => o.value));
      }
    } else if (reason === 'clear') {
      onChange([]);
    }
  };

  return (
    <Autocomplete
      {...props}
      multiple
      disabled={isLoading ?? InputProps?.disabled}
      loading={isLoading}
      id={id}
      options={options}
      disableCloseOnSelect
      value={derivedValue}
      getOptionLabel={(option) => option.label}
      renderOption={optionRenderer}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      open={isFocused}
      renderInput={(params) => <TextField {...params} label={InputProps?.label} />}
      selectOnFocus={true}
      renderTags={(items: Option[]) => (
        <Typography
          sx={(t) =>
            isFocused && props.size === 'small'
              ? {
                  visibility: 'hidden',
                  fontSize: t.typography.body2.fontSize,
                }
              : {
                  fontSize: props.size === 'small' ? t.typography.body2.fontSize : t.typography.body1.fontSize,
                }
          }
        >
          {InputProps?.label}{' '}
          {items.length !== options.length && (
            <Typography
              component="span"
              color="black"
              sx={(t) => ({
                '&': {
                  p: '0',
                  fontSize: props.size === 'small' ? t.typography.body2.fontSize : t.typography.body1.fontSize,
                },
              })}
            >
              ({items.length})
            </Typography>
          )}
        </Typography>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (allSelectable) {
          return [{ label: 'Select all', value: 'select-all' }, ...filtered];
        }

        return filtered;
      }}
      componentsProps={{
        popper: {
          placement: 'bottom-start',
        },
      }}
    />
  );
};

export default CheckboxAutocomplete;
