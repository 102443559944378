import { Button, useTheme } from "@mui/material";
import AlertMessage from "components/common/AlertMessage";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

interface UseEnqueueSnackbarProps {
  callbackQueue?: () => void
  onUndo?: (id: number) => void
}

const useEnqueueSnackbar = ({ callbackQueue, onUndo }: UseEnqueueSnackbarProps) => {
  const theme = useTheme();
  const [snackbarState, updateSnackbarState] = useState<(number | typeof callbackQueue)[]>([]);

  useEffect(() => {
    if( !callbackQueue ) return;

    const isCallback = snackbarState.length === 1 && typeof snackbarState[0] === typeof callbackQueue;
    if( isCallback ) {
      callbackQueue();
      updateSnackbarState([]);
      return
    }
  }, [callbackQueue, updateSnackbarState, snackbarState])

  const onShowAlert = useCallback((idAlert: number, message: string) => {
    updateSnackbarState((prev) => {
      const newState = [...prev, idAlert];
      if( newState.find(value => typeof value === typeof callbackQueue) === undefined ) newState.push(callbackQueue);
      return newState;
    });

    enqueueSnackbar(<AlertMessage message={message} />, {
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      style: {
        display: 'flex',
        flexWrap: 'nowrap',
        paddingBottom: 0,
        paddingTop: 0,
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.dark,
        borderRadius: theme.shape.borderRadius,
        boxShadow: 'none',
        maxWidth: 320,
        minWidth: 320,
        width: 320,
      },
      onClose: () => {
        updateSnackbarState((prev) => {
          return prev.filter(value => value !== idAlert)
        });
      },
      action: (idAction) => (<Button
        sx={t => ({ color: t.palette.success.dark, fontSize: t.typography.body2.fontSize, })}
        onClick={() => {
          if( onUndo ) onUndo(idAlert);
          updateSnackbarState((prev) => {
            return prev.filter(value => value !== idAlert)
          });
          closeSnackbar(idAction);
      }}>Undo</Button>),
    });

  }, [enqueueSnackbar, onUndo, closeSnackbar, updateSnackbarState,]);

  const onCloseSnackbar = useCallback(closeSnackbar, [closeSnackbar]);

  return { onShowAlert, onCloseSnackbar, };
}

export default useEnqueueSnackbar;
