import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { CircularProgress, TextField, Typography } from '@mui/material';
import type { UseMutationResult } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import type { FC } from 'react';
import { useCallback, useState } from 'react';

interface TagNameFieldProps {
  tagName?: string;
  onClose: () => void;
  onMutate: UseMutationResult;
}

const TagNameField: FC<TagNameFieldProps> = ({ tagName = '', onMutate, onClose }) => {
  const [newTagName, setNewTagName] = useState<string>(tagName);
  const [formError, setFormError] = useState<string | null>(null);

  const handleTagNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewTagName(event.target.value);
      setFormError(null);
    },
    [setNewTagName, setFormError]
  );

  const onChangeTagName = useCallback(() => {
    if (!newTagName.length || (tagName.length && tagName == newTagName)) {
      handleClose();
      return;
    }

    setFormError('');
    onMutate.mutate(
      { tag_name: newTagName.trim() },
      {
        onSuccess: () => {
          handleClose();
        },
        onError: (e) => {
          if (isAxiosError(e) && e.response) {
            setFormError((e.response.data as { message: string }).message);
          } else {
            handleClose();
          }
        },
      }
    );
  }, [newTagName, onMutate, setFormError]);

  const handleClose = () => {
    onClose();
    setNewTagName('');
    setFormError(null);
  };
  return (
    <TextField
      autoFocus
      variant="outlined"
      size="small"
      onKeyUpCapture={(e) => {
        e.key === 'Enter' && onChangeTagName();
      }}
      onBlurCapture={onChangeTagName}
      onChange={handleTagNameChange}
      defaultValue={tagName}
      maxRows={1}
      inputProps={{ style: { fontSize: 14, padding: '6px 8px', height: 18 }, maxLength: 64 }}
      fullWidth
      error={Boolean(formError)}
      helperText={
        formError && (
          <Typography component="span" variant="caption" marginTop={-2}>
            {formError}
          </Typography>
        )
      }
      InputProps={{
        endAdornment: (
          <>
            {onMutate.isPending && <CircularProgress size={18} sx={{ mr: -1 }} color="success" />}
            {onMutate.isError && formError?.length && <ErrorOutlineIcon color="error" sx={{ width: 18, mr: -1 }} />}
          </>
        ),
      }}
      disabled={onMutate.isPending}
    />
  );
};

export default TagNameField;
