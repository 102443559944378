import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import type { ReactNode } from 'react';
import { Box, CircularProgress } from '@mui/material';

const AuthRequired = ({ children }: { children: ReactNode; }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);

  if (route === 'idle') {
    return <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, }}>
      <CircularProgress sx={{ color: '#202020' }} />
    </Box>;
  }

  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};
export default AuthRequired;
