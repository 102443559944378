
import { css, keyframes } from '@mui/material';
import type { SerializedStyles } from '@emotion/serialize';
import type { Keyframes } from '@emotion/react';
import WellSpyLoginBg1 from 'assets/wellspy_login_bg_1.webp';
import WellSpyLoginBg2 from 'assets/wellspy_login_bg_2.webp';
import WellSpyLoginBg3 from 'assets/wellspy_login_bg_3.webp';

const getRandomBackground = (): SerializedStyles => {
  const duration = '40s';

  const backgrounds: { background: string; keyframes: Keyframes; pseudoGradient: string; }[] = [
    {
      background: `url(${WellSpyLoginBg1}) no-repeat center center;`,
      keyframes: keyframes`
        0% { background-position: 45% 50%; }
        100% { background-position: 67% 50%; }
      `,
      pseudoGradient: `linear-gradient(180deg, rgba(46, 29, 39, 0) 0%, #2E1D27 100%);`
    },
    {
      background: `url(${WellSpyLoginBg2}) no-repeat center center;`,
      keyframes: keyframes`
        0% { background-position: 25% 50%; }
        100% { background-position: 60% 50%; }
      `,
      pseudoGradient: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);`
    },
    {
      background: `url(${WellSpyLoginBg3}) no-repeat center center;`,
      keyframes: keyframes`
        0% { background-position: 23% 50%; }
        100% { background-position: 63% 50%; }
      `,
      pseudoGradient: `linear-gradient(180deg, rgba(46, 29, 39, 0) 0%, #2E1D27 100%);`
    },
  ]
  const randomIndex = Math.floor(Math.random() * backgrounds.length);

  return css`
    background: ${backgrounds[randomIndex].background}
    background-size: cover;
    animation: ${backgrounds[randomIndex].keyframes} ${duration} cubic-bezier(0, 0, 0.48, 0.61) infinite alternate;

    &:after {
      content: '';
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 270px;
      background: ${backgrounds[randomIndex].pseudoGradient}

    }
  `;
};

export default getRandomBackground;
