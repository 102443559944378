import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import type { SxProps, Theme } from '@mui/material';
import { Collapse, ListItemIcon, ListItemText, IconButton, ListItem, Button } from '@mui/material';
import type { FC, ReactNode } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

interface SidebarNavItemProps {
  to?: string;
  icon?: ReactNode;
  title: ReactNode;
  children?: ReactNode;
  expanded?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onClick?: () => void;
  isButton?: boolean;
}

const SidebarNavItem: FC<SidebarNavItemProps> = ({
  to,
  icon,
  title,
  children,
  expanded = false,
  sx,
  disabled,
  onClick,
  isButton = false,
  ...props
}) => {
  const [open, setOpen] = useState(expanded);
  const handleExpand = useCallback(() => {
    setOpen((s) => !s);
  }, [setOpen]);

  const expandable = Boolean(React.Children.toArray(children).length);

  const path = useResolvedPath(to ?? '');

  const match = useMatch({ path: path.pathname, end: expandable, caseSensitive: true });
  const isActive = match != null;
  const component = to && !isButton ? Link : 'button';

  const onClickProp = useMemo(() => {
    if (to && !isButton) return undefined;
    if (onClick) return onClick;

    return handleExpand;
  }, [to, isButton, onClick, handleExpand]);

  return (
    <>
      <ListItem sx={sx} {...props}>
        <Button
          variant="text"
          component={component}
          to={to}
          disableElevation
          disableRipple={isActive}
          sx={{
            display: 'flex',
            flexGrow: 1,
            textTransform: 'none',
            textAlign: 'left',
            color: '#fff',
            background: isActive ? 'rgba(144,202,249,0.08)' : 'inherit',
          }}
          onClick={onClickProp}
          disabled={disabled}
        >
          <ListItemIcon sx={{ minWidth: 32, opacity: 0.56 }}>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </Button>

        {expandable && <IconButton onClick={handleExpand}>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
      </ListItem>
      {expandable && (
        <Collapse in={open} timeout="auto" sx={{ '& .MuiListItemIcon-root': { ml: 2.5 } }}>
          {children}
        </Collapse>
      )}
    </>
  );
};

export default SidebarNavItem;
