import type { TextFieldProps, AutocompleteValue, AutocompleteProps } from '@mui/material';
import { useDomains } from '../api';
import type { Option, OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';
import { intersection, sortBy } from 'lodash-es';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { Typography } from '@mui/material';
import { useProjects } from 'features/projects';

interface DomainAutocompleteProps
  extends Omit<AutocompleteProps<number, true, boolean, false>, 'value' | 'onChange' | 'options' | 'renderInput'> {
  value?: AutocompleteValue<number, true, false, false>;
  onChange: (value: AutocompleteValue<number, true, false, false>) => void;
  InputProps?: TextFieldProps;
  allSelectable?: boolean;
}

interface DomainOption extends Option {
  type: 'project' | 'domain';
}

interface RenderGroupParams {
  group: 'project' | 'domain';
  key: number;
  children: ReactNode;
}

const DomainAutocomplete = ({
  InputProps,
  onChange,
  value,
  allSelectable = false,
  ...props
}: DomainAutocompleteProps): JSX.Element => {
  const { data: domainProjects } = useProjects({
    config: {
      select: (data): Option[] => {
        const options: Option[] = data.map((p) => ({ value: p.domains, label: p.name }));
        return options;
      },
    },
  });
  const { data: domains } = useDomains({
    config: {
      select: (data): DomainOption[] => {
        const options: DomainOption[] = data.map((d) => ({ value: d.id, label: d.domain, type: 'domain' }));
        return sortBy(options, (option) => option.label);
      },
    },
  });

  const handleProjectSelect = useCallback(
    (newValue: (number | number[])[]) => {
      const flatValue = newValue.flat();
      const newValueSet = new Set(flatValue);
      // means there are duplicates
      //Under the hood mui trying to add option instead of removing so this is check if it adds all existing values or only partially
      if (newValueSet.size !== flatValue.length && intersection(value, [...newValueSet]).length === newValueSet.size) {
        //if full project option already selected
        const listWithoutRemovedProject = flatValue.filter((id) => flatValue.indexOf(id) === flatValue.lastIndexOf(id));

        onChange(listWithoutRemovedProject);
      } else {
        onChange([...newValueSet]);
      }
    },
    [onChange, value]
  );

  return (
    <CheckboxAutocomplete
      {...props}
      allSelectable={allSelectable}
      id="domain-autocomplete"
      // @ts-expect-error grouping
      groupBy={(option: DomainOption) => String(option.type)}
      renderGroup={(params: RenderGroupParams) => {
        return (
          <li key={params.key}>
            <Typography variant="subtitle2" sx={{ px: 1.75, fontWeight: 500, color: 'rgba(0, 0, 0, 0.38)' }}>
              {params.group === 'domain' ? 'all domains' : 'by project'}
            </Typography>
            <span>{params.children}</span>
          </li>
        );
      }}
      options={[...domainProjects, ...domains]}
      value={value}
      onChange={handleProjectSelect as (value: OptionValue[]) => void}
      InputProps={InputProps}
    />
  );
};

export default DomainAutocomplete;
