/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable  @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useState, type FC, useCallback } from 'react';
import { useDeleteTagGroup } from '../../api';
import { LoadingButton } from '@mui/lab';
import { useModal } from 'hooks';

interface TagGroupDeleteProps {
  groupName: string;
  groupId: number;
  isDefaultItem: boolean;
  onCloseTagGroupDetails: () => void;
}

const TagGroupDelete: FC<TagGroupDeleteProps> = ({ groupId, groupName, isDefaultItem, onCloseTagGroupDetails }) => {
  const actionDialog = useModal();
  const [formError, setFormError] = useState<string | null>(null);

  const deleteTagGroup = useDeleteTagGroup({
    config: {
      onSuccess: () => {
        onCloseTagGroupDetails();
        setFormError(null);
        actionDialog.close();
      },
      onError: (e) => {
        setFormError(e.message);
      },
    },
  });

  const onDelete = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      deleteTagGroup.mutate({ group_id: groupId });
    },
    [groupId, deleteTagGroup]
  );

  const onClose = useCallback(() => {
    actionDialog.close();
    setFormError(null);
  }, [actionDialog]);

  return (
    <>
      <Tooltip title={isDefaultItem ? 'You can not change default item' : null} placement="bottom" followCursor>
        <div>
          <Button disabled={isDefaultItem} size="medium" variant="text" color="error" onClick={actionDialog.open}>
            Delete group
          </Button>
        </div>
      </Tooltip>
      <Dialog fullWidth maxWidth="xs" open={actionDialog.isOpen}>
        <DialogTitle>Delete group</DialogTitle>
        <DialogContent>
          <DialogContentText color={'black'}>
            <Typography>
              Delete{' '}
              <Typography component={'span'} fontWeight={'bold'}>
                {groupName}
              </Typography>{' '}
              group with all tags in it?
            </Typography>
            {formError && (
              <Typography variant="caption" color="red">
                {formError}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={deleteTagGroup.isPending} onClick={onClose} color="info">
            Cancel
          </Button>
          <LoadingButton loading={deleteTagGroup.isPending} onClick={onDelete} variant="outlined" color="error">
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TagGroupDelete;
