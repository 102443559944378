import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { AppEntry, CreateAppPayload } from '../types';

export const createApp = async (data: CreateAppPayload): Promise<AppEntry> => {
  const res = await apiClient.post<AppEntry>(`/apps`, data);
  return res.data;
};

interface UseCreateAppOptions {
  config?: MutationConfig<typeof createApp>;
}

export const useCreateApp = ({ config }: UseCreateAppOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['apps'],
      });

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: createApp,
  });
};
