/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeleteOutline, MoreVert } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import type { UseModalResult } from 'hooks';
import { useAnchorMenu } from 'hooks';
import type { FC } from 'react';
import type { App } from '../types';
import { useDeleteApp } from '../api/deleetApp';
import EditIcon from '@mui/icons-material/Edit';
import { useOutletContext } from 'react-router';
import { useEditedAppId } from '../hooks/useEditedAppId';

interface AppRowProps {
  app: App;
}

const AppRow: FC<AppRowProps> = ({ app }) => {
  const [_, setEditedAppId] = useEditedAppId();
  const { appModal } = useOutletContext<{ appModal: UseModalResult }>();
  const menu = useAnchorMenu();
  const { mutate: deleteApp, isPending } = useDeleteApp({
    config: {
      onSuccess: () => {
        menu.handleCloseMenu();
      },
    },
  });

  const handleDeleteApp = () => {
    if (window.confirm(`Are you sure you want to delete ${app.name}? This action can't be undone`)) {
      deleteApp({ id: app.id });
    }
  };
  const handleEditDomainClick = () => {
    setEditedAppId(app.id);
    appModal.open();
  };

  return (
    <TableRow>
      <TableCell>{app.id}</TableCell>
      <TableCell>
        <Typography variant="body1">{app.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{app.projects.map((p) => p.name).join(', ')}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{app.ios_store_id}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{app.android_store_id}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{format(app.created_at, 'dd.MM.yyyy HH:mm')}</Typography>
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <IconButton onClick={menu.handleOpenMenu}>
          <MoreVert />
        </IconButton>

        <Menu
          anchorEl={menu.anchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menu.isOpen}
          onClose={menu.handleCloseMenu}
        >
          <MenuItem color="error" onClick={handleDeleteApp}>
            <ListItemIcon>
              {isPending ? <CircularProgress size={24} color="inherit" /> : <DeleteOutline color="error" />}
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleEditDomainClick}>
            <ListItemIcon>
              <EditIcon color="inherit" />
            </ListItemIcon>
            <ListItemText>Edit App</ListItemText>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default AppRow;
