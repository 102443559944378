import type { GapData, GetGapDetailsEntryResponse } from './types';
import type { GetGapEntryResponse } from './types';
import { CHART_COLORS } from './constants';
import type { GetGapDetailsResponse } from './types';

const yLabelFormatter = Intl.NumberFormat('en', { notation: 'compact' });
const tooltipYLabelFormatter = Intl.NumberFormat('en');
export const getYLabel = (y: number): string => {
  return yLabelFormatter.format(y);
};

const getTooltipYLabel = (y: number, isPercent = false): string => {
  if (isPercent) {
    return String(y.toFixed(2) + '%');
  }
  return tooltipYLabelFormatter.format(y);
};

export const getTooltipLabel = (entityName: string, y: number, isPercent = false): string => {
  return `${getTooltipYLabel(y, isPercent)} \n ${entityName}`;
};

export const transformGapToChart = ({ data }: GetGapEntryResponse): GapData => {
  return {
    ...data,
    rows: data.rows.map((r, i) => ({
      ...r,
      chart_data: r.chart_data.map((cd) => ({ ...cd, x: new Date(cd.x).getTime(), toolTipLabel: r.row_name })),
      chart_color: CHART_COLORS[i % CHART_COLORS.length],
    })),
  };
};

export const transformGapDetails = ({
  data: { details, value, row_name },
}: GetGapDetailsEntryResponse): GetGapDetailsResponse => {
  return {
    row_name,
    value,
    details: details.map((r, i) => ({
      ...r,
      chart_data: r.chart_data.map((cd) => ({ ...cd, x: new Date(cd.x).getTime(), toolTipLabel: r.column_name })),
      color: CHART_COLORS[i % CHART_COLORS.length],
    })),
  };
};
