import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useAPIUsages } from '../api';
import APIUsageTypeChip from './APIUsageTypeChip';
import type { APIUsageEntry, APIUsageType } from '../types';

type APIGroupedByEntry = {
  [key in APIUsageType]: APIUsageEntry[];
} & {
  totals: {
    [key in APIUsageType]: number;
  };
};

type APIGroupedBy = Record<string, APIGroupedByEntry>;
interface APIUsageListProps {
  startDate: Date | null;
  endDate: Date | null;
  dateDetails: string | null;
  handleDateDetails: (value: string | null) => void;
}

const APIUsageList: FC<APIUsageListProps> = ({ startDate, endDate, dateDetails, handleDateDetails }) => {
  const { data: usages } = useAPIUsages({
    params: {
      startTime: startDate ? startDate.toISOString().slice(0, 19) : undefined,
      endTime: endDate ? endDate.toISOString().slice(0, 19) : undefined,
    },
  });

  const daysRows = useMemo(
    () =>
      Object.entries(
        usages.reduce<APIGroupedBy>((grouped, entry) => {
          const entryDate = entry.created_at.toISOString().slice(0, 10);
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (!grouped[entryDate]) {
            grouped[entryDate] = {
              meta_info: [],
              database_app: [],
              database_domain: [],
              spreadsheets_domain: [],
              spreadsheets_app_high: [],
              spreadsheets_app_low: [],
              totals: {
                meta_info: 0,
                database_app: 0,
                database_domain: 0,
                spreadsheets_domain: 0,
                spreadsheets_app_high: 0,
                spreadsheets_app_low: 0,
              },
            };
          }
          grouped[entryDate][entry.type].push(entry);
          grouped[entryDate].totals[entry.type] = grouped[entryDate][entry.type].reduce(
            (sum, one) => sum + one.api_calls_count,
            0
          );
          return grouped;
        }, {})
      ),
    [usages]
  );

  const totals = useMemo(
    () =>
      daysRows.reduce<{
        [key in APIUsageType]: number;
      }>(
        (tot, [, entry]) => {
          tot.meta_info += entry.totals.meta_info;
          tot.database_app += entry.totals.database_app;
          tot.database_domain += entry.totals.database_domain;
          tot.spreadsheets_domain += entry.totals.spreadsheets_domain;
          tot.spreadsheets_app_high += entry.totals.spreadsheets_app_high;
          tot.spreadsheets_app_low += entry.totals.spreadsheets_app_low;
          return tot;
        },
        {
          meta_info: 0,
          database_app: 0,
          database_domain: 0,
          spreadsheets_domain: 0,
          spreadsheets_app_high: 0,
          spreadsheets_app_low: 0,
        }
      ),
    [daysRows]
  );

  const total = usages.reduce((sum, entry) => sum + entry.api_calls_count, 0);

  return (
    <TableContainer sx={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              component="th"
              width={150}
              sx={t => ({
                height: 56,
                py: 0,
                borderRight: dateDetails ? `1px solid ${t.palette.grey[200]}` : 'none',
                backgroundColor: 'white',
                minWidth: 110
              })}>
              Day
            </TableCell>
            {!dateDetails && <>
              <TableCell component="th" sx={{ py: 0, backgroundColor: 'white', minWidth: 200 }}>
                <APIUsageTypeChip type="database_app" />
              </TableCell>
              <TableCell component="th" width={100} sx={{ py: 0, backgroundColor: 'white' }}>
              <APIUsageTypeChip type="database_domain" />
            </TableCell>
              <TableCell component="th" sx={{ py: 0, backgroundColor: 'white', minWidth: 200 }}>
                <APIUsageTypeChip type="spreadsheets_domain" />
              </TableCell>
              <TableCell component="th" sx={{ py: 0, backgroundColor: 'white', minWidth: 200 }}>
                <APIUsageTypeChip type="spreadsheets_app_high" />
              </TableCell>
              <TableCell component="th" sx={{ py: 0, backgroundColor: 'white', minWidth: 200 }}>
                <APIUsageTypeChip type="spreadsheets_app_low" />
              </TableCell>
              <TableCell component="th" sx={{ py: 0, backgroundColor: 'white', minWidth: 160 }}>
                Total
              </TableCell></>}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {!dateDetails && <><TableCell component="th" sx={{fontWeight: 500}}>Total</TableCell>
              <TableCell component="th" sx={{fontWeight: 600, wordBreak: 'keep-all', whiteSpace: 'nowrap'}}>{totals.database_app}</TableCell>
              <TableCell component="th" sx={{fontWeight: 600, wordBreak: 'keep-all', whiteSpace: 'nowrap'}}>{totals.database_domain}</TableCell>
              <TableCell component="th" sx={{fontWeight: 600}}>{totals.spreadsheets_domain}</TableCell>
              <TableCell component="th" sx={{fontWeight: 600}}>{totals.spreadsheets_app_high}</TableCell>
              <TableCell component="th" sx={{fontWeight: 600}}>{totals.spreadsheets_app_low}</TableCell>
              <TableCell component="th" sx={{fontWeight: 600}}>{total}</TableCell></>}
          </TableRow>
          {daysRows.map(([day, entries]) => (
            <TableRow
              hover
              component={Box}
              onClick={() => { handleDateDetails(day); }}
              sx={t => {
                const showMoreLabel = {
                  '&:hover::after': {
                    content: '"Show more"',
                    position: 'absolute',
                    right: 0,
                    px: 2,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'uppercase',
                    color: t.palette.primary.light,
                    fontSize: 13,
                  }
                };

                return ({
                  position: 'relative',
                  cursor: 'pointer',
                  ...(!dateDetails ? showMoreLabel : null),
                })
              }}
              key={`usage_${day}`}>
              <TableCell
                height={53}
                sx={t => {
                  const activeItem = {
                    backgroundColor: `#f6fbff !important`,
                    boxShadow: `inset -4px 0 0 ${t.palette.primary.light}`
                  };
                  return ({
                    borderRight: dateDetails ? `1px solid ${t.palette.grey[200]}` : 'none',
                    ...(dateDetails === day ? activeItem : null)
                  })
                }}
              >{day}</TableCell>
              {!dateDetails && <>
                <TableCell>{entries.totals.database_app}</TableCell>
                <TableCell>{entries.totals.database_domain}</TableCell>
                <TableCell>{entries.totals.spreadsheets_domain}</TableCell>
                <TableCell>{entries.totals.spreadsheets_app_high}</TableCell>
                <TableCell>{entries.totals.spreadsheets_app_low}</TableCell>
                <TableCell>
                  {entries.totals.database_domain +
                    entries.totals.database_app +
                    entries.totals.spreadsheets_domain +
                    entries.totals.spreadsheets_app_high +
                    entries.totals.spreadsheets_app_low}
                </TableCell></>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default APIUsageList;
