import { Box, Paper } from '@mui/material';
import type { FC } from 'react';
import { Suspense, useCallback } from 'react';
import { Titled } from 'react-titled';
import { DomainsList, DomainsListSkeleton } from 'features/domains';
import { useDebouncedQueryParam } from '../../hooks';
import { NumericArrayParam, withDefault } from 'use-query-params';
import { ProjectAutocomplete } from 'features/projects';

const ProjectsParam = withDefault(NumericArrayParam, [] as number[]);

const DomainsPage: FC = () => {
  const {
    param: projects,
    actualValue: actualProjects,
    handleChange: setProjects,
  } = useDebouncedQueryParam<typeof ProjectsParam, number[]>({ name: 'projects', defaultValue: ProjectsParam });

  const handleProjectsChange = useCallback(
    (branches: number[]) => {
      setProjects(branches);
    },
    [setProjects]
  );

  return (
    <>
      <Titled title={(title) => `Domains | ${title}`} />
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ p: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
            <ProjectAutocomplete
              onChange={handleProjectsChange}
              value={actualProjects}
              InputProps={{ label: 'Projects' }}
            />
          </Box>
          <Suspense fallback={<DomainsListSkeleton />}>
            <DomainsList projects={projects} />
          </Suspense>
        </Paper>
      </Box>
    </>
  );
};

export default DomainsPage;
