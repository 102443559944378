import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { CreateAppPayload } from '../types';
import type { AppDTO } from '../../creatives/api/types';

interface UpdateAppParams {
  id: number;
  data: Partial<CreateAppPayload>;
}
export const updateApp = async ({ id, data }: UpdateAppParams): Promise<AppDTO> => {
  const res = await apiClient.patch<AppDTO>(`/apps/${id}`, data);
  return res.data;
};

interface UseUpdateAppOptions {
  config?: MutationConfig<typeof updateApp>;
}

export const useUpdateApp = ({ config }: UseUpdateAppOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['apps'],
      });

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: updateApp,
  });
};
