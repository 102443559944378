/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Dialog, Stack, TextField, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useCreateApp } from '../api/createApp';
import { enqueueSnackbar } from 'notistack';
import { ProjectAutocomplete } from 'features/projects';
import { useEditedAppId } from '../hooks/useEditedAppId';
import { useSynchronousAppById } from '../api/getAppById';
import { useUpdateApp } from '../api/updateApp';
import { useQueryClient } from '@tanstack/react-query';

interface AppDialogProps {
  isOpen: boolean;
  onClose: (action?: 'declined') => void;
}

const AppDialog: FC<AppDialogProps> = ({ isOpen, onClose }) => {
  const [editedAppId, setEditedAppId] = useEditedAppId();
  const [app, setApp] = useState<string>('');
  const [projects, setProjects] = useState<number[]>([]);
  const [iOSId, setIOSId] = useState<string>('');
  const [androidId, setAndroidId] = useState<string>('');
  const [formError, setFormError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { data: appData, isLoading: isAppLoading } = useSynchronousAppById({
    id: Number(editedAppId),
    config: { enabled: !!editedAppId },
  });

  useEffect(() => {
    if (appData) {
      setApp(appData.name);
      setIOSId(appData.ios_store_id ?? null);
      setAndroidId(appData.android_store_id ?? null);
      setProjects(appData.projects.map((p) => p.id));
    }
  }, [appData]);

  const handleProjectChange = useCallback(
    (newProjects: number[]) => {
      setProjects(newProjects);
    },
    [setProjects]
  );
  const handleAppNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApp(event.target.value);
  };
  const handleIOSIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIOSId(event.target.value);
  };
  const handleAndroidIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAndroidId(event.target.value);
  };

  const handleClose = () => {
    setApp('');
    setIOSId('');
    setAndroidId('');
    setFormError(null);
    setProjects([]);
    setEditedAppId(null);
    onClose();
  };

  const { mutate: createApp, isPending: isAppCreating } = useCreateApp({
    config: {
      onSuccess: () => {
        enqueueSnackbar('New App was added!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        });
        handleClose();
      },
    },
  });

  const { mutate: updateApp, isPending: isAppUpdating } = useUpdateApp({
    config: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: ['apps', editedAppId],
        });
        enqueueSnackbar('App has been updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        });
        handleClose();
      },
    },
  });

  const handleSubmit = () => {
    try {
      if (editedAppId) {
        updateApp({
          id: editedAppId,
          data: { name: app, android_store_id: androidId, ios_store_id: iOSId, projects },
        });
      } else createApp({ name: app, android_store_id: androidId || null, ios_store_id: iOSId || null, projects });
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          setFormError(e.response.data.message as string);
        }
      }
    }
  };

  const title = editedAppId ? 'Edit' : 'Add';

  const isSubmitButtonEnabled = !!app && (!!androidId || !!iOSId) && !isAppUpdating && !isAppLoading && !isAppCreating;
  const isLoading = isAppUpdating || isAppCreating;

  return (
    <Dialog maxWidth="xs" onClose={handleClose} open={isOpen}>
      <Stack sx={{ p: 2, width: 350 }} spacing={4}>
        <Box>
          <Typography variant="h5">{title} app</Typography>
        </Box>
        {isAppLoading ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box>
              <TextField
                label="App"
                name="app"
                fullWidth
                onChange={handleAppNameChange}
                value={app}
                error={Boolean(formError)}
                helperText={formError}
              />
            </Box>
            <Box>
              <ProjectAutocomplete
                InputProps={{
                  label: 'Project',
                }}
                fullWidth
                onChange={handleProjectChange}
                value={projects}
              />
            </Box>
            <Box>
              <TextField
                label="iOS ID"
                name="ios"
                fullWidth
                type="number"
                onChange={handleIOSIdChange}
                value={iOSId}
                error={Boolean(formError)}
                helperText={formError}
              />
            </Box>
            <Box>
              <TextField
                label="Android ID"
                name="android"
                fullWidth
                onChange={handleAndroidIdChange}
                value={androidId}
                error={Boolean(formError)}
                helperText={formError}
              />
            </Box>
          </>
        )}
        <Stack sx={{ mt: 4 }} spacing={2}>
          <LoadingButton
            size="large"
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            loading={isLoading}
            disabled={!isSubmitButtonEnabled}
          >
            {title}
          </LoadingButton>
          <Button
            size="large"
            variant="outlined"
            disabled={isAppUpdating || isAppCreating}
            onClick={handleClose}
            color="primary"
            fullWidth
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AppDialog;
