import type { TextFieldProps, AutocompleteValue, AutocompleteProps } from '@mui/material';
import type { Option, OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';
import { intersection, sortBy } from 'lodash-es';
import { useApps } from '../api';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { Typography } from '@mui/material';
import { useProjects } from 'features/projects';

interface AppAutocompleteProps
  extends Omit<AutocompleteProps<number, true, boolean, false>, 'value' | 'onChange' | 'options' | 'renderInput'> {
  value?: AutocompleteValue<number, true, false, false>;
  onChange: (value: AutocompleteValue<number, true, false, false>) => void;
  InputProps?: TextFieldProps;
  allSelectable?: boolean;
}

interface AppOption extends Option {
  type: 'project' | 'app';
}

interface RenderGroupParams {
  group: 'project' | 'app';
  key: number;
  children: ReactNode;
}
const AppAutocomplete = ({
  InputProps,
  onChange,
  value,
  allSelectable = false,
  ...props
}: AppAutocompleteProps): JSX.Element => {
  const { data: projects } = useProjects({
    config: {
      select: (data): Option[] => {
        const options: Option[] = data.map((p) => ({ value: p.apps, label: p.name }));
        return options;
      },
    },
  });
  const { data: apps } = useApps({
    config: {
      select: (data): Option[] => {
        const options: AppOption[] = data.map((a) => ({ value: a.id, label: a.name, type: 'app' }));
        return sortBy(options, (option) => option.label);
      },
    },
  });

  const handleProjectSelect = useCallback(
    (newValue: (number | number[])[]) => {
      const flatValue = newValue.flat();
      const newValueSet = new Set(flatValue);
      // means there are duplicates
      //Under the hood mui trying to add option instead of removing so this is check if it adds all existing values or only partially
      if (newValueSet.size !== flatValue.length && intersection(value, [...newValueSet]).length === newValueSet.size) {
        //if full project option already selected
        const listWithoutRemovedProject = flatValue.filter((id) => flatValue.indexOf(id) === flatValue.lastIndexOf(id));

        onChange(listWithoutRemovedProject);
      } else {
        onChange([...newValueSet]);
      }
    },
    [onChange, value]
  );

  return (
    <CheckboxAutocomplete
      {...props}
      allSelectable={allSelectable}
      id="app-autocomplete"
      options={[...projects, ...apps]}
      value={value}
      // @ts-expect-error grouping
      groupBy={(option: AppOption) => String(option.type)}
      onChange={handleProjectSelect as (value: OptionValue[]) => void}
      renderGroup={(params: RenderGroupParams) => {
        return (
          <li key={params.key}>
            <Typography variant="subtitle2" sx={{ px: 1.75, fontWeight: 500, color: 'rgba(0, 0, 0, 0.38)' }}>
              {params.group === 'app' ? 'all apps' : 'by project'}
            </Typography>
            <span>{params.children}</span>
          </li>
        );
      }}
      InputProps={InputProps}
    />
  );
};

export default AppAutocomplete;
