/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useCallback, useMemo } from 'react';
import useGapParams from '../hooks/useGapParams';
import { useGapDetails } from '../api/getGapDetails';
import { Box, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { isEmpty, max, min } from 'lodash-es';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryScatter,
} from 'victory';
import { getTooltipLabel, getYLabel, transformGapDetails } from '../helpers';
import { add, differenceInDays, format, sub } from 'date-fns';
import type { EventPropTypeInterface, StringOrNumberOrCallback } from 'victory-core';
import type { VictoryScatterTTargetType } from 'victory-scatter/lib/victory-scatter';
import type { Coordinate } from '../types';

const SCATTER_EVENTS = [
  {
    target: 'data',
    eventHandlers: {
      onMouseOver: () => {
        return [
          {
            mutation: (props: { style: { fill: string } }) => {
              const originalColor: string = props.style.fill;
              return {
                style: Object.assign({}, props.style, {
                  fill: 'white',
                  strokeWidth: 3,
                  strokeOpacity: 0.6,
                  stroke: originalColor,
                }),
                size: 5,
              };
            },
          },
        ];
      },
      onMouseOut: () => {
        return [
          {
            mutation: () => {
              return null;
            },
          },
        ];
      },
    },
  },
];

const GapDetailsChart = () => {
  const {
    setGapIdDetails,
    gapIdDetails,
    rows,
    columns,
    value,
    period,
    domains,
    adTypes,
    channel,
    tags,
    apps,
    filter,
    languages,
  } = useGapParams();
  const { data } = useGapDetails({
    id: gapIdDetails,
    params: {
      rows,
      columns,
      value,
      period,
      ad_type: adTypes,
      tag_id: tags,
      domain_id: domains,
      channel,
      app_id: apps,
      filter: filter!,
      language: languages,
    },
    config: { select: (data) => transformGapDetails(data) },
  });

  const xTickValues: number[] = useMemo(() => {
    if (isEmpty(data)) return [];
    const xValues = data.details.flatMap((c) => c.chart_data.map((cd) => cd.x));
    const [minX = 0, maxX = 1] = [min(xValues), max(xValues)];
    const unit = Math.round(differenceInDays(maxX, minX) / 4);
    const result: number[] = [minX];

    for (let i = minX; i < sub(maxX, { days: unit / 2 }).getTime(); i = add(i, { days: unit }).getTime()) {
      result.push(i);
    }
    result.push(maxX);
    return result;
  }, [data]);

  const handleCloseDetails = useCallback(() => {
    setGapIdDetails(null);
  }, [setGapIdDetails]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
      <Box
        sx={(t) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1,
          px: 1.5,
          height: 42,
          backgroundColor: 'white',
          borderBottom: `1px solid ${t.palette.grey[300]}`,
        })}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 500, textTransform: 'capitalize' }}>{data.value}</Typography>
        <IconButton onClick={handleCloseDetails}>
          <CloseIcon sx={{ fontSize: 21 }} />
        </IconButton>
      </Box>
      <Box sx={{ pt: 1.5, px: 2 }}>
        <Typography component="h2" fontWeight={700} fontSize={20} px={1.5} py={1}>
          {data.row_name}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#F1F4F8',
            py: 0.75,
            px: 1.5,
          }}
        >
          <Typography fontWeight={700} fontSize={14}>
            Trends
          </Typography>
          <Typography fontSize={12} sx={{ color: 'black', opacity: '0.6' }}>
            Estimated by {data.value}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', flexGrow: 1, pt: 2 }}>
          {!isEmpty(data.details) && (
            <VictoryChart
              padding={{ right: 30, left: 0, top: 20, bottom: 20 }}
              height={200}
              width={700}
              domainPadding={{ y: 10, x: 20 }}
              containerComponent={
                <VictoryVoronoiContainer
                  voronoiBlacklist={data.details.map((ch, index) => `scatter${index}`)}
                  labelComponent={
                    <VictoryTooltip
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: 8,
                      }}
                      flyoutPadding={5}
                      cornerRadius={5}
                      pointerLength={5}
                      flyoutStyle={{
                        filter: 'drop-shadow(0 2px 3px rgb(0 0 0 / 0.2))',
                        strokeWidth: 0,
                        fill: 'white',
                      }}
                    />
                  }
                  labels={({ datum }: { datum: Coordinate }) => {
                    return getTooltipLabel(datum.toolTipLabel, datum.y, data.value.includes('%'));
                  }}
                />
              }
            >
              <VictoryAxis
                tickValues={xTickValues}
                animate={{ duration: 1000 }}
                tickFormat={(t: Date) => format(t, 'dd.MM')}
                style={{
                  axis: { stroke: '#757575' },
                  tickLabels: { fontFamily: 'Roboto', fontSize: 8 },
                }}
              />
              <VictoryAxis
                style={{
                  grid: { stroke: '#e0e0e0', strokeWidth: 1 },
                  tickLabels: { fontFamily: 'Roboto', fontSize: 8 },
                  axis: { stroke: 'white' },
                }}
                animate={{ duration: 1000 }}
                dependentAxis
                orientation="right"
                tickFormat={getYLabel}
              />
              {data.details.map((chart) => (
                <VictoryLine
                  animate={{
                    duration: 500,
                  }}
                  key={chart.column_id}
                  style={{
                    data: { stroke: chart.color, strokeWidth: 1.5 },
                  }}
                  data={chart.chart_data}
                  standalone
                />
              ))}
              {data.details.map((chart, index) => (
                <VictoryScatter
                  key={chart.column_id}
                  events={
                    SCATTER_EVENTS as EventPropTypeInterface<VictoryScatterTTargetType, StringOrNumberOrCallback>[]
                  }
                  style={{
                    data: {
                      fill: chart.color,
                    },
                  }}
                  size={4}
                  name={'scatter' + index}
                  data={chart.chart_data.slice(-1)}
                />
              ))}
            </VictoryChart>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '12px 24px', mt: '20px' }}>
          {data.details.map((row) => (
            <Box key={row.column_id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  backgroundColor: row.color,
                  width: 12,
                  height: 4,
                }}
              />{' '}
              - <Typography fontSize={12}>{row.column_name}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default GapDetailsChart;
