import { Box, CircularProgress } from '@mui/material';
import type { FC } from 'react';
const GapFiltersAndChartLoader: FC = () => {
  return (
    <Box sx={{ height: 320, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <CircularProgress size="md" sx={{ width: 40, mx: 'auto' }} />
    </Box>
  );
};
export default GapFiltersAndChartLoader;
