import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    sizes: {
      sidebarWidth: number;
    };
  }

  interface ThemeOptions {
    sizes: {
      sidebarWidth: number;
    };
  }

  interface Palette {
    dark: Palette['primary'];
  }
  interface PaletteOptions {
    dark: PaletteOptions['primary'];
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    macbook15: true;
  }
}

export const themeOptions: ThemeOptions = {
  sizes: {
    sidebarWidth: 248,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      macbook15: 1440,
      xl: 1536,
    },
  },
  palette: {
    mode: 'light',
    dark: {
      main: 'rgba(17, 24, 39, 1)',
    },
    background: {
      default: '#f9fafc',
    },
    success: {
      light: '#EDF7ED',
      main: '#2E7D32',
      dark: '#1E4620',
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-sizeSmall': {
            fontSize: '0.875rem',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            fontWeight: 400,
            transform: 'translate(14px, 6px) scale(1)',
          },
        },
        shrink: {
          '&.MuiInputLabel-sizeSmall': {
            transform: 'translate(14px, -9px) scale(.8)',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-sizeSmall': {
            borderRadius: '8px',
            minWidth: 140,
          },
          '&.MuiInputBase-inputSizeSmall': {
            fontSize: '0.875rem',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            fontWeight: 400,
            height: '36px',
            boxSizing: 'border-box',
          },
        },
        select: {
          '&.MuiInputBase-inputSizeSmall': {
            padding: '6px 6px 6px 14px',
            height: '36px',
            boxSizing: 'border-box',
            fontSize: '0.875rem',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            fontWeight: 400,
            borderRadius: '8px',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root:not(.MuiInputBase-sizeSmall)': {
            minWidth: 160,
          },
          '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            borderRadius: '8px',
            fontSize: '0.875rem',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            fontWeight: 400,
            minWidth: 140,
            minHeight: 38,
          },
          '.MuiAutocomplete-input.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
            padding: '6px 8px 0 8px',
          },
          '.MuiAutocomplete-input.MuiInputBase-sizeSmall': {
            minHeight: '36px',
            borderRadius: '8px',
          },
          '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            width: '100%',
          },
        },
        listbox: {
          padding: '6px 0',
          '.MuiAutocomplete-option': {
            padding: '6px 9px',
            minHeight: 38,
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '16px',
            letterSpacing: '0.15px',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
          },
        },
        option: {
          padding: '6px 9px',
          minHeight: 38,
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '16px',
          letterSpacing: '0.15px',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
          '.MuiButtonBase-root.MuiCheckbox-root': {
            padding: '0px',
            marginRight: '9px',
          },
        },
        popper: {
          minWidth: 200,
          left: 0,
          marginTop: '4px !important',
        },
        hasPopupIcon: {
          '.MuiAutocomplete-hasClearIcon': {
            paddingRight: '56px',
          },
        },
        clearIndicator: {
          '.MuiSvgIcon-root': {
            width: '0.875rem',
            height: '0.875rem',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-sizeSmall p.MuiTypography-root': {
            fontSize: '0.875rem',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            fontWeight: 400,
            padding: '0 6px 2px',
          },
        },
        input: {
          '&.MuiInputBase-inputSizeSmall.MuiAutocomplete-input': {
            minWidth: 10,
            position: 'absolute',
          },
          '&.MuiInputBase-inputSizeSmall.MuiAutocomplete-input:focus': {
            zIndex: 3,
            left: '6px',
            width: 'calc(100% - 68px)',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          minWidth: 200,
          left: 0,
          marginTop: '4px !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          fontWeight: 400,
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

export const darkSubTheme = createTheme({
  ...themeOptions,
  palette: {
    mode: 'dark',
    dark: {
      main: 'rgba(17, 24, 39, 1)',
    },
    background: {
      paper: 'rgba(17, 24, 39, 1)',
    },
  },
});

export default theme;
