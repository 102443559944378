/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext } from 'react';

export type ColumnKey =
  | 'creatives'
  | 'branch'
  | 'impressions_meta'
  | 'impressions_google'
  | 'impressions_total'
  | 'traffic_share'
  | 'first_seen';

export type VisibleColumns = Record<ColumnKey, boolean>;
type ChangeVisibilityColumn = (columnKey: ColumnKey, checked: boolean) => void;

interface ColumnsSettingsContextType {
  visibilityColumns: VisibleColumns;
  onChangeVisibilityColumn: ChangeVisibilityColumn;
  recentlyHiddenColumn: string;
}

export const ColumnSettingsContext = createContext<ColumnsSettingsContextType>({
  visibilityColumns: {},
  onChangeVisibilityColumn: (columnKey: ColumnKey, checked: boolean) => {
    // Our implementation here
  },
  recentlyHiddenColumn: '',
} as ColumnsSettingsContextType);

export function useColumnsSettingsContext() {
  return useContext(ColumnSettingsContext);
}
