import { useSuspenseQuery, useQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { Domain } from '../types';
import type { DomainDTO } from './types';
import type { SynchronousQueryConfig } from 'lib/react-query';

export const getDomainById = async (id: number, signal?: AbortSignal): Promise<DomainDTO> => {
  const res = await apiClient.get<DomainDTO>(`/domains/${id}`, {
    signal,
  });
  return res.data;
};

export const useDomainById = <TData = Domain>({
  id,
  config,
}: {
  id: number;
  config?: QueryConfig<typeof getDomainById, TData>;
}) => {
  return useSuspenseQuery({
    staleTime: 60 * 60 * 1000,
    ...config,
    queryFn: ({ signal }) => getDomainById(id, signal),
    queryKey: ['domain', id],
  });
};

export const useSynchronousDomainById = <TData = Domain>({
  id,
  config,
}: {
  id: number;
  config?: SynchronousQueryConfig<typeof getDomainById, TData>;
}) => {
  return useQuery({
    staleTime: 60 * 60 * 1000,
    ...config,
    queryFn: () => getDomainById(id),
    queryKey: ['domain', id],
  });
};
