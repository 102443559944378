import ClearIcon from '@mui/icons-material/Clear';
import type { SelectChangeEvent } from '@mui/material';
import { Box, Button, Grid, LinearProgress, TablePagination } from '@mui/material';
import PeriodFilter from 'components/common/PeriodFilter';
import { useDebouncedQueryParam, usePagination } from 'hooks';
import type { FC } from 'react';
import { Suspense, useCallback, useState } from 'react';
import {
  BooleanParam,
  createEnumArrayParam,
  NumericArrayParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import { DomainAutocomplete } from '../../domains';
import { useFlowSortingParam } from '../hooks/useFlowSortingParam';
import type { CompetitorsFlowsOrderValue } from '../types';
import CompetitorsFlowsColumnsSettings from './CompetitorsFlowsColumnsSettings';
import CompetitorsFlowsList from './CompetitorsFlowsList';
import { BranchesAutocomplete } from 'features/tags';
import type { SearchLanguages } from 'types/shared';
import LanguageSelect from '../../../components/common/LanguageAutocomplete';

export const ROWS_PERPAGE_OPTIONS = [15, 25, 50];
export const DEFAULT_PER_PAGE = 25;
export const DEFAULT_IMPRESSIONS_PERIOD = '30';

const DomainsParam = withDefault(NumericArrayParam, [] as number[]);
const BranchesParam = withDefault(NumericArrayParam, [] as number[]);
const PeriodParam = withDefault(StringParam, DEFAULT_IMPRESSIONS_PERIOD);
const SortDirectionParam = withDefault(BooleanParam, true);
const LangParam = withDefault(createEnumArrayParam<SearchLanguages>(['en', 'es', 'pt']), [] as SearchLanguages[]);

const CompetitorsFlows: FC = () => {
  const [orderBy, setOrderBy] = useFlowSortingParam();
  const [total, setTotal] = useState(0);
  const [desc, setDesc] = useQueryParam('desc', SortDirectionParam);
  const {
    param: domains,
    actualValue: actualDomains,
    handleChange: setDomains,
  } = useDebouncedQueryParam<typeof DomainsParam, number[]>({ name: 'domains', defaultValue: DomainsParam });
  const [period, setPeriod] = useQueryParam('period', PeriodParam);
  const {
    param: branches,
    actualValue: actualBranches,
    handleChange: setBranches,
  } = useDebouncedQueryParam<typeof BranchesParam, number[]>({ name: 'branches', defaultValue: BranchesParam });
  const {
    param: languages,
    actualValue: actualLanguages,
    handleChange: setLanguages,
  } = useDebouncedQueryParam<typeof LangParam, SearchLanguages[]>({ name: 'lang', defaultValue: LangParam });

  const handleDomainChange = useCallback(
    (dom: number[]) => {
      setDomains(dom);
    },
    [setDomains]
  );

  const handleBranchesChange = useCallback(
    (branches: number[]) => {
      setBranches(branches);
    },
    [setBranches]
  );

  const handleImpressionsPeriodChange = useCallback(
    (event: SelectChangeEvent) => {
      setPeriod(event.target.value);
    },
    [setPeriod]
  );

  const handleLanguageChange = useCallback(
    (langs: SearchLanguages[]) => {
      setLanguages(langs);
    },
    [setLanguages]
  );

  const { page, perPage, handlePageChange, handleRowsPerPageChange, resetPagination } = usePagination(
    [orderBy, desc, domains, period],
    DEFAULT_PER_PAGE
  );

  const handleResetFilters = useCallback(() => {
    setDomains([]);
    setPeriod(DEFAULT_IMPRESSIONS_PERIOD);
  }, [setDomains, setPeriod]);

  const handleResetFiltersWithPagination = useCallback(() => {
    handleResetFilters();
    resetPagination();
  }, [handleResetFilters, resetPagination]);

  const handleToggleSort = useCallback(
    (id: string) => {
      if (id === orderBy) setDesc((prevState) => !prevState);
      else {
        setOrderBy(id);
        setDesc(true);
      }
    },
    [orderBy, setOrderBy, setDesc]
  );

  const activeFilters = domains.length || period !== DEFAULT_IMPRESSIONS_PERIOD;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Grid container alignItems="center" spacing={1} sx={{ mb: 3 }}>
          <Grid item>
            <DomainAutocomplete
              size="small"
              value={actualDomains}
              InputProps={{
                label: 'Domains',
              }}
              onChange={handleDomainChange}
            />
          </Grid>
          <Grid item>
            <PeriodFilter
              size="small"
              inputLabel="Impressions period"
              value={period}
              onChange={handleImpressionsPeriodChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <BranchesAutocomplete
              size="small"
              value={actualBranches}
              InputProps={{
                label: 'Branch',
              }}
              onChange={handleBranchesChange}
            />
          </Grid>
          <Grid item xs={2}>
            <LanguageSelect
              size="small"
              value={actualLanguages}
              InputProps={{
                label: 'Languages',
              }}
              onChange={handleLanguageChange}
            />
          </Grid>
          {activeFilters && (
            <Grid item>
              <Button
                id="reset-filter-button"
                variant="text"
                onClick={handleResetFilters}
                sx={{ height: 36, borderRadius: 2 }}
              >
                <ClearIcon sx={{ mr: 1 }} />
                <span>Reset Filters</span>
              </Button>
            </Grid>
          )}
        </Grid>

        <CompetitorsFlowsColumnsSettings />
      </Box>
      <Box>
        <Suspense fallback={<LinearProgress />}>
          {!!orderBy && (
            <CompetitorsFlowsList
              key="competitors-flows"
              limit={perPage}
              offset={page * perPage}
              setTotal={setTotal}
              reset={handleResetFiltersWithPagination}
              domains={domains as number[] | undefined}
              branches={branches as number[] | undefined}
              period={period}
              orderBy={orderBy as CompetitorsFlowsOrderValue}
              desc={desc}
              languages={languages}
              handleToggleSort={handleToggleSort}
            />
          )}
        </Suspense>
      </Box>

      <TablePagination
        id="competitors-pagination"
        labelRowsPerPage="Domains per page:"
        component="div"
        count={total}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={ROWS_PERPAGE_OPTIONS}
      />
    </Box>
  );
};

export default CompetitorsFlows;
