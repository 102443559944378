/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import { add, differenceInDays, format, sub } from 'date-fns';
import { Box, Typography } from '@mui/material';
import { useGap } from '../api/getGap';
import { useMemo } from 'react';
import { getTooltipLabel, getYLabel, transformGapToChart } from '../helpers';
import { useGapContext } from '../context/GapContext';
import { isEmpty, max, min } from 'lodash-es';
import useGapParams from '../hooks/useGapParams';
import type { EventPropTypeInterface, StringOrNumberOrCallback } from 'victory-core';
import type { VictoryScatterTTargetType } from 'victory-scatter/lib/victory-scatter';
import GapMainChartEmptyState from './GapMainChartEmptyState';
import type { Coordinate } from '../types';

const SCATTER_EVENTS = [
  {
    target: 'data',
    eventHandlers: {
      onMouseOver: () => {
        return [
          {
            mutation: (props: { style: { fill: string } }) => {
              const originalColor: string = props.style.fill;
              return {
                style: Object.assign({}, props.style, {
                  fill: 'white',
                  strokeWidth: 3,
                  strokeOpacity: 0.6,
                  stroke: originalColor,
                }),
                size: 6.5,
              };
            },
          },
        ];
      },
      onMouseOut: () => {
        return [
          {
            mutation: () => {
              return null;
            },
          },
        ];
      },
    },
  },
];

const GapMultipleLineChart = () => {
  const { selectedRows } = useGapContext();

  const { channel, columns, value, adTypes, desc, period, tags, domains, rows, orderBy, apps, filter, languages } =
    useGapParams();

  const { data: gapData } = useGap({
    config: {
      select: transformGapToChart,
    },
    params: {
      channel: channel as number[],
      value,
      rows,
      period,
      domain_id: domains as number[],
      ad_type: adTypes,
      tag_id: tags as number[],
      columns,
      desc,
      order_by: orderBy,
      app_id: apps as number[],
      filter: filter!,
      language: languages,
    },
  });

  const { rows: charts, rows_name: rowName, values_name: valueName } = gapData;
  const selectedCharts = useMemo(() => charts.filter((c) => selectedRows.includes(c.row_id)), [charts, selectedRows]);

  const xTickValues: number[] = useMemo(() => {
    if (isEmpty(selectedCharts)) return [];
    const xValues = selectedCharts.flatMap((c) => c.chart_data.map((cd) => cd.x));
    const [minX = 0, maxX = 1] = [min(xValues), max(xValues)];
    const unit = Math.round(differenceInDays(maxX, minX) / 4);
    const result: number[] = [minX];

    for (let i = minX; i < sub(maxX, { days: unit / 2 }).getTime(); i = add(i, { days: unit }).getTime()) {
      result.push(i);
    }
    result.push(maxX);
    return result;
  }, [selectedCharts]);

  return (
    <Box
      sx={{
        pl: '20px',
        pr: 3,
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Typography fontWeight={700} fontSize={14}>
          Trends in {rowName}
        </Typography>
        <Typography color="gray" fontSize={12}>
          Estimated by {valueName}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', flexGrow: 1 }}>
        {isEmpty(selectedRows) ? (
          <GapMainChartEmptyState variant={'empty'} />
        ) : (
          <VictoryChart
            padding={{ right: 40, bottom: 20, top: 20 }}
            height={200}
            width={600}
            domainPadding={{ y: 10, x: 20 }}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiBlacklist={charts.map((ch, index) => `scatter${index}`)}
                labelComponent={
                  <VictoryTooltip
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: 10,
                    }}
                    flyoutPadding={5}
                    cornerRadius={5}
                    pointerLength={5}
                    flyoutStyle={{
                      filter: 'drop-shadow(0 2px 3px rgb(0 0 0 / 0.2))',
                      strokeWidth: 0,
                      fill: 'white',
                    }}
                  />
                }
                labels={({ datum }: { datum: Coordinate }) => {
                  return getTooltipLabel(datum.toolTipLabel, datum.y, valueName.includes('%'));
                }}
              />
            }
          >
            <VictoryAxis
              tickValues={xTickValues}
              animate={{ duration: 1000 }}
              tickFormat={(t: Date) => format(t, 'dd.MM')}
              style={{
                axis: { stroke: '#757575' },
                tickLabels: { fontFamily: 'Roboto', fontSize: 10 },
              }}
            />
            <VictoryAxis
              style={{
                grid: { stroke: '#e0e0e0', strokeWidth: 1.5 },
                tickLabels: { fontFamily: 'Roboto', fontSize: 10 },
                axis: { stroke: 'white' },
              }}
              animate={{ duration: 1000 }}
              dependentAxis
              orientation="right"
              tickFormat={getYLabel}
            />
            {selectedCharts.map((chart) => (
              <VictoryLine
                animate={{
                  duration: 500,
                }}
                key={chart.row_id}
                style={{
                  data: { stroke: chart.chart_color, strokeWidth: 1.5 },
                }}
                data={chart.chart_data}
                standalone
              />
            ))}
            {selectedCharts.map((chart, index) => (
              <VictoryScatter
                events={SCATTER_EVENTS as EventPropTypeInterface<VictoryScatterTTargetType, StringOrNumberOrCallback>[]}
                key={chart.row_id}
                style={{
                  data: {
                    fill: chart.chart_color,
                  },
                }}
                size={5}
                name={'scatter' + index}
                data={chart.chart_data.slice(-1)}
              />
            ))}
          </VictoryChart>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '12px 24px', mt: 2 }}>
        {selectedCharts.map((row) => (
          <Box key={row.row_id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box
              sx={{
                backgroundColor: row.chart_color,
                width: 12,
                height: 4,
              }}
            />{' '}
            - <Typography fontSize={12}>{row.row_name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GapMultipleLineChart;
