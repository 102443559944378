import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { Project } from '../types';

export const getProjects = async (): Promise<Project[]> => {
  const res = await apiClient.get<Project[]>(`/projects`);
  return res.data;
};

export const useProjects = <TData = Project[]>({ config }: { config?: QueryConfig<typeof getProjects, TData> } = {}) =>
  useSuspenseQuery({
    staleTime: 60 * 60 * 1000,
    ...config,
    queryFn: () => getProjects(),
    queryKey: ['projects'],
  });
