import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { WebCreativesPage } from 'features/creatives';
import StickyLayout from 'components/layout/StickyLayout';

const CreativesByDomainPage: FC = () => (
  <>
    <StickyLayout id="web-creatives-page" pageTitle="Creatives">
      <WebCreativesPage />
    </StickyLayout>
    <Outlet />
  </>
);

export default CreativesByDomainPage;
