import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { CreateDomainPayload, DomainDTO } from './types';

export const createDomain = async (data: CreateDomainPayload): Promise<DomainDTO> => {
  const res = await apiClient.post<DomainDTO>(`/domains`, data);
  return res.data;
};

interface UseCreateProjectDomainOptions {
  config?: MutationConfig<typeof createDomain>;
}

export const useCreateDomain = ({ config }: UseCreateProjectDomainOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['domains']
      });

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: createDomain,
  });
};
