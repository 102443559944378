import type { FC } from 'react';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FilterOptionsState } from '@mui/material';
import { Autocomplete, Box, Button, Checkbox, createFilterOptions, Stack, TextField, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useModal } from 'hooks';
import AddTagDialog from './AddTagDialog';
import AddIcon from '@mui/icons-material/Add';
import CollapseGroup from './CollapseGroup';
import { useTagGroups } from '../api';
import type { TagAutocompleteOption, TagGroupEntry } from '../types';
import { isEmpty, isEqual } from 'lodash-es';
import TagChipItem from './TagChipItem';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';

const filter = createFilterOptions<TagAutocompleteOption | FreeSolo>();
interface EditTagsAutocompleteProps {
  tags: TagAutocompleteOption[];
  handleChangeTags: (value: TagAutocompleteOption[]) => void;
  helperText?: string;
}

interface FreeSolo {
  type?: 'custom';
  inputValue?: string;
  title?: string;
}

const EditTagsAutocomplete: FC<EditTagsAutocompleteProps> = ({
  tags,
  handleChangeTags,
  helperText,
}: EditTagsAutocompleteProps) => {
  const { isOpen: isFocused, toggle: onToggle } = useModal(false);
  const [payloadTags, setPayloadTags] = useState<TagAutocompleteOption[]>([]);
  useEffect(() => {
    setPayloadTags(tags);
  }, [tags]);

  const [dialogValue, setDialogValue] = useState<string>('');
  const { data: tagGroups } = useTagGroups<TagGroupEntry[]>({
    config: {
      select: (data) => data.data.filter((tagGroup) => tagGroup.is_visible),
    },
  });

  const { onShowAlert } = useEnqueueSnackbar({
    onUndo: (idAlert) => {
      const tag = tags.find((tag) => tag.id === idAlert);
      setPayloadTags((prev) => (tag ? [...prev, tag] : prev));
    },
    callbackQueue: () => {
      const isPayloadChanged = !isEqual(
        [...tags].map((i) => i.id).sort((a, b) => a - b),
        [...payloadTags].map((i) => i.id).sort((a, b) => a - b)
      );

      isPayloadChanged && handleChangeTags(payloadTags);
    },
  });

  const [tagList, taGroupLabels] = useMemo(() => {
    const tagList: TagAutocompleteOption[] = tagGroups
      .map((tGroup) =>
        tGroup.tags.map((tag) => ({
          id: tag.tag_id,
          label: tag.tag_name,
          groupId: tGroup.group_id,
        }))
      )
      .flat();

    const taGroupLabels = tagGroups.reduce<Record<number, string>>((acc, tGroup) => {
      acc[tGroup.group_id] = tGroup.group_name;
      return acc;
    }, {});

    return [tagList, taGroupLabels];
  }, [tagGroups]);

  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useModal();

  const checkOption = (option: TagAutocompleteOption) => tags.some((c) => c.id === option.id);

  const checkGroup = useCallback(
    (groupId: number, full = true) => {
      const groupLength = tagList.filter((t) => t.groupId === groupId).length;
      const selectedGroupLength = tags.filter((t) => t.groupId === groupId).length;
      if (full) {
        return groupLength === selectedGroupLength;
      }

      return selectedGroupLength > 0 && selectedGroupLength < groupLength;
    },
    [tags, tagList]
  );

  const selectGroup = useCallback(
    (groupId: number) => {
      const groupedTags = tagList.filter((t) => t.groupId === groupId);
      const selectedGroupTags = tags.filter((t) => t.groupId === groupId);

      if (selectedGroupTags.length > 0) {
        handleChangeTags(tags.filter((c) => c.groupId !== groupId));
      } else {
        handleChangeTags([...tags, ...groupedTags]);
      }
    },
    [tagList, tags, handleChangeTags]
  );

  const deleteSelectedTag = useCallback(
    (tagId: number) => {
      handleChangeTags(tags.filter((tag) => tag.id !== tagId));
    },
    [handleChangeTags, tags]
  );

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: TagAutocompleteOption[]) => {
      const duplicatesArray = newValue.filter((item) => item.id === newValue[newValue.length - 1].id);
      if (duplicatesArray.length > 1) {
        deleteSelectedTag(duplicatesArray[0].id);
      } else {
        handleChangeTags(newValue);
      }
    },
    [deleteSelectedTag, handleChangeTags]
  );

  const updateStateTags = useCallback(
    (value: TagAutocompleteOption[]) => {
      setPayloadTags(value);
    },
    [setPayloadTags]
  );

  return (
    <>
      <Autocomplete
        id="edit-tags-autocomplete"
        options={tagList}
        // @ts-expect-error in this part in case of no matching options need to push object that will be rendered as button, so it can't have Tag interface
        filterOptions={(options, params: FilterOptionsState<TagAutocompleteOption | FreeSolo>) => {
          const filtered = filter(options, params);
          if (isEmpty(filtered)) {
            filtered.push({
              type: 'custom',
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={tags}
        onFocus={onToggle}
        onBlur={onToggle}
        open={isFocused}
        multiple
        fullWidth
        disableCloseOnSelect
        disableClearable
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'flip',
                enabled: false,
              },
            ],
          },
        }}
        getOptionLabel={(option: TagAutocompleteOption) => option.label}
        groupBy={(option: TagAutocompleteOption) => String(option.groupId)}
        renderOption={(props, option: TagAutocompleteOption | FreeSolo) => {
          if ((option as FreeSolo).type === 'custom') {
            return (
              <Stack sx={{ px: 1 }}>
                <Typography color="#000" textAlign="center" marginBottom={1} fontWeight={700}>
                  No matching tags
                </Typography>
                <Button
                  id="add-tag-button"
                  variant="outlined"
                  onClick={() => {
                    setDialogValue((option as FreeSolo).inputValue ?? '');
                    openModal();
                  }}
                >
                  <AddIcon fontSize="small" sx={{ mr: 1 }} /> {(option as FreeSolo).title}
                </Button>
              </Stack>
            );
          }

          return (
            <li style={{ paddingLeft: 30 }} {...props}>
              <Checkbox
                key={(option as TagAutocompleteOption).id}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                checked={checkOption(option as TagAutocompleteOption)}
                inputProps={{
                  id: `edit-tags-autocomplete-checkbox-${(option as TagAutocompleteOption).groupId}`,
                }}
              />
              {(option as TagAutocompleteOption).label}
            </li>
          );
        }}
        renderGroup={(params) => {
          if (params.group === 'undefined') {
            return <>{params.children}</>;
          }
          return (
            <CollapseGroup
              onChange={() => {
                selectGroup(Number(params.group));
              }}
              checked={checkGroup(Number(params.group))}
              partiallyChecked={checkGroup(Number(params.group), false)}
              key={params.key}
              params={params}
              label={taGroupLabels[Number(params.group)]}
            />
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tags"
            variant="outlined"
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              id: 'edit-tags-autocomplete-field',
            }}
          />
        )}
        renderTags={(items) => {
          return (
            <Box sx={{ display: 'flex', gap: 0.75, flexWrap: 'wrap' }}>
              {items.map((tag, index) => (
                <TagChipItem
                  size="small"
                  key={`${tag.groupId}.${tag.id}.${index}`}
                  tag={tag}
                  payloadTags={payloadTags}
                  updateStateTags={updateStateTags}
                  onShowAlert={onShowAlert}
                />
              ))}
            </Box>
          );
        }}
      />
      <AddTagDialog
        dialogValue={dialogValue}
        isOpen={isModalOpen}
        close={closeModal}
        onChange={handleChangeTags}
        value={tags}
      />
    </>
  );
};

export default EditTagsAutocomplete;
