/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Box, Table, TableBody, TableContainer } from '@mui/material';
import type { FC } from 'react';
import GapDetailsTableHead from './GapDetailsTableHead';
import GapDetailsTableRow from './GapDetailsTableRow';
import GapDetailsChart from './GapDetailsChart';
import useGapParams from '../hooks/useGapParams';
import { useGapDetails } from '../api/getGapDetails';
import { transformGapDetails } from '../helpers';
import { useGap } from '../api/getGap';

const GapDetails: FC = () => {
  const {
    gapIdDetails,
    rows,
    columns,
    value,
    period,
    domains,
    adTypes,
    channel,
    tags,
    desc,
    orderBy,
    apps,
    filter,
    languages,
  } = useGapParams();

  const { data } = useGapDetails({
    id: gapIdDetails,
    params: {
      rows,
      columns,
      value,
      period,
      ad_type: adTypes,
      tag_id: tags,
      domain_id: domains,
      channel,
      app_id: apps,
      filter: filter!,
      language: languages,
    },
    config: { select: (data) => transformGapDetails(data) },
  });

  const { data: gapData } = useGap({
    params: {
      channel: channel as number[],
      value,
      rows,
      period,
      domain_id: domains as number[],
      ad_type: adTypes,
      tag_id: tags as number[],
      columns,
      desc,
      order_by: orderBy,
      app_id: apps as number[],
      filter: filter!,
      language: languages,
    },
  });

  return (
    <Box
      sx={(t) => ({
        width: '100%',
        overflow: 'scroll',
        position: 'absolute',
        height: '100%',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderTop: `1px solid ${t.palette.grey[300]}`,
        borderBottom: `1px solid ${t.palette.grey[300]}`,
        borderRight: `1px solid ${t.palette.grey[300]}`,
      })}
    >
      <Box>
        <GapDetailsChart />
      </Box>
      <Box sx={{ width: '100%', position: 'sticky', top: 0, zIndex: 10, px: 2 }}>
        <GapDetailsTableHead columnName={gapData.data.columns_name} />
      </Box>
      <TableContainer sx={{ px: 2, pb: 1.5 }}>
        <Table>
          <TableBody>
            {data.details.map((d) => (
              <GapDetailsTableRow
                key={d.column_id}
                columnName={d.column_name}
                columnId={d.column_id}
                generalColumnName={gapData.data.columns_name}
                impressions={d.impressions}
                creatives={d.creatives}
                percentGrowth={d.percent_growth}
                percentTotal={d.percent_total}
                color={d.color}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GapDetails;
