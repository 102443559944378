import { useSuspenseQuery, useQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { AppEntry } from '../types';
import type { SynchronousQueryConfig } from 'lib/react-query';

export const getAppById = async (id: number, signal?: AbortSignal): Promise<AppEntry> => {
  const res = await apiClient.get<AppEntry>(`/apps/${id}`, {
    signal,
  });
  return res.data;
};

export const useAppById = <TData = AppEntry>({
  id,
  config,
}: {
  id: number;
  config?: QueryConfig<typeof getAppById, TData>;
}) => {
  return useSuspenseQuery({
    staleTime: 60 * 60 * 1000,
    ...config,
    queryFn: ({ signal }) => getAppById(id, signal),
    queryKey: ['apps', id],
  });
};

export const useSynchronousAppById = <TData = AppEntry>({
  id,
  config,
}: {
  id: number;
  config?: SynchronousQueryConfig<typeof getAppById, TData>;
}) => {
  return useQuery({
    staleTime: 60 * 60 * 1000,
    ...config,
    queryFn: () => getAppById(id),
    queryKey: ['apps', id],
  });
};
