import type { App, AppEntry } from '../types';

export default function getAppFromDTO(dto: AppEntry): App {
  const app: App = {
    ...dto,
    created_at: new Date(dto.created_at),
    updated_at: new Date(dto.modified_at),
  };

  return app;
}
