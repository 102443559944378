import type { TextFieldProps, AutocompleteValue } from '@mui/material';
import type { Option, OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';
import { useGetSynchronousBranches } from '../api';
import type { AutocompleteSize } from 'types/utils';

interface FlowsAutocompleteProps {
  value?: AutocompleteValue<number, true, false, false>;
  onChange: (value: AutocompleteValue<number, true, false, false>) => void;
  InputProps?: TextFieldProps;
  size?: AutocompleteSize;
}

const BranchesAutocomplete = ({ InputProps, onChange, value, ...props }: FlowsAutocompleteProps): JSX.Element => {
  const { data: branches, isPending } = useGetSynchronousBranches<Option[]>({
    config: {
      select: (data) => {
        const options = data.tags.map((tag) => ({ value: tag.tag_id, label: tag.tag_name }));
        options.unshift({ value: 0, label: '—' });
        return options;
      },
    },
  });

  return (
    <CheckboxAutocomplete
      {...props}
      isLoading={isPending}
      options={branches ?? []}
      value={value}
      InputProps={InputProps}
      onChange={onChange as (value: OptionValue[]) => void}
      id="branches-autocomplete"
    />
  );
};

export default BranchesAutocomplete;
