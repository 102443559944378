import { Box, Paper } from '@mui/material';
import type { FC } from 'react';
import { Suspense, useCallback } from 'react';
import { Titled } from 'react-titled';
import { AppListSkeleton, AppList } from 'features/apps';
import { ProjectAutocomplete } from 'features/projects';
import { useDebouncedQueryParam } from '../../hooks';
import { NumericArrayParam, withDefault } from 'use-query-params';

const ProjectsParam = withDefault(NumericArrayParam, [] as number[]);

const AppsPage: FC = () => {
  const {
    param: projects,
    actualValue: actualProjects,
    handleChange: setProjects,
  } = useDebouncedQueryParam<typeof ProjectsParam, number[]>({ name: 'projects', defaultValue: ProjectsParam });

  const handleProjectChange = useCallback(
    (newProjects: number[]) => {
      setProjects(newProjects);
    },
    [setProjects]
  );

  return (
    <>
      <Titled title={(title) => `Apps | ${title}`} />
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ p: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
            <ProjectAutocomplete
              onChange={handleProjectChange}
              value={actualProjects}
              InputProps={{ label: 'Projects' }}
            />
          </Box>
          <Suspense fallback={<AppListSkeleton />}>
            <AppList projects={projects} />
          </Suspense>
        </Paper>
      </Box>
    </>
  );
};

export default AppsPage;
