import { type FC } from 'react';
import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useGapParams from '../hooks/useGapParams';

const GapTableSkeleton: FC = () => {
  const { gapIdDetails } = useGapParams();

  return (
    <Box
      sx={(t) => ({
        position: 'relative',
        display: 'flex',
        flexGrow: gapIdDetails ? 0 : 1,
        overflow: 'hidden',
        width: gapIdDetails ? 400 : '100%',
        height: gapIdDetails ? '100%' : 400,
        border: `1px solid ${t.palette.grey[300]}`,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: gapIdDetails ? 0 : '4px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: gapIdDetails ? 0 : '4px',
      })}
    >
      <TableContainer
        sx={{
          position: 'absolute',
          overflow: 'scroll',
          width: '100%',
          height: '100%',
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} width={200} height={42}>
                <Skeleton animation="wave" height={42} width={200} />
              </TableCell>
              {!gapIdDetails && (
                <TableCell colSpan={4}>
                  <Skeleton animation="wave" height={42} width={300} />
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell width={42} padding="checkbox">
                <Skeleton animation="wave" height={30} width={30} />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" height={30} width={120} />
              </TableCell>
              {!gapIdDetails && (
                <>
                  <TableCell>
                    <Skeleton animation="wave" height={30} width={160} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={30} width={170} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={30} width={120} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={30} width={170} />
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(15).keys()].map((i) => (
              <TableRow key={i}>
                <TableCell width={42} padding="checkbox">
                  <Skeleton animation="wave" height={30} width={30} />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" height={30} width={120} />
                </TableCell>
                {!gapIdDetails && (
                  <>
                    <TableCell>
                      <Skeleton animation="wave" height={30} width={160} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={30} width={170} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={30} width={120} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={30} width={170} />
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GapTableSkeleton;
