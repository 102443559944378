import { debounce } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useUpdateCreative } from '../api';
import type { TagAutocompleteOption } from '../../tags';

export default function useChangeCreativeTags(creativeId: number, initial: TagAutocompleteOption[] | []) {
  const [tags, setTags] = useState<TagAutocompleteOption[] | []>(initial);
  const [error, setErr] = useState<string | null>(null);

  const updateCreativeParams = useMemo(
    () => ({
      id: creativeId,
      config: {
        onError: () => {
          setErr(`Can't save your changes!`);
        },
        onSuccess: () => {
          setErr(null);
        },
      },
    }),
    [creativeId]
  );

  const mutation = useUpdateCreative(updateCreativeParams);

  const update = useMemo(() => debounce(mutation.mutate, 700), [mutation.mutate]);

  const updateTags = useCallback(
    (value: TagAutocompleteOption[]) => {
      setTags(value);
      update({ tags: value.map((t) => t.id) });
    },
    [update]
  );

  return {
    error,
    tags,
    updateTags,
    mutation,
  };
}
