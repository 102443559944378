import { useSuspenseQuery } from '@tanstack/react-query';
import type { SortingParams } from 'lib/api';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { FilterType, GetGapEntryResponse } from '../types';
import type { SearchLanguages } from '../../../types/shared';

export interface FilteringFields {
  // viewing settings
  rows: string | null;
  columns: string | null;
  value: string | null;
  // filters
  domain_id?: (number | null)[] | null;
  period: string | null;
  channel?: (number | null)[] | null;
  ad_type?: string[];
  tag_id?: (number | null)[] | null;
  app_id?: number[];
  filter?: FilterType;
  language: SearchLanguages[];
}

interface GetGapOptions {
  params: Partial<SortingParams> & Omit<FilteringFields, 'filter'>;
}

export const getGap = async ({ params }: GetGapOptions, signal?: AbortSignal): Promise<GetGapEntryResponse> => {
  const res = await apiClient.get<GetGapEntryResponse>(`/gap`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseGapOptions<TData> {
  config?: QueryConfig<typeof getGap, TData>;
  params: Partial<SortingParams> & FilteringFields;
}

export const useGap = <TData = GetGapEntryResponse>({ config, params }: UseGapOptions<TData>) => {
  const { domain_id, app_id, filter, ...restParams } = params;

  const filteredParams = {
    ...restParams,
    ...(filter === 'web' ? { domain_id } : { app_id }),
  };

  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getGap({ params: filteredParams }, signal),
    queryKey: ['gap', ...Object.values(filteredParams), filter],
  });
};
