import { TableHead, TableRow, TableSortLabel, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useColumnsSettingsContext } from '../providers/ColumnsSettingsContext';
import type { CompetitorsFlowsOrderValue } from '../types';
import { StyledStickyHeadTableCell, TableCellItem } from './CompetitorsFlowsComponents';

const STICKY_LEFT_VALUE = 162;

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  th: {
    borderBottomColor: `${theme.palette.grey[200]}`,
  },
}));

interface CompetitorsFlowsTableSortProps {
  handleToggleSort: (id: CompetitorsFlowsOrderValue) => void;
  orderBy: CompetitorsFlowsOrderValue;
  desc: boolean;
}

const CompetitorsFlowsTableHead: FC<CompetitorsFlowsTableSortProps> = ({ desc, orderBy, handleToggleSort }) => {
  const {
    visibilityColumns: {
      impressions_meta,
      impressions_google,
      impressions_total,
      traffic_share,
      first_seen,
      creatives,
      branch,
    },
  } = useColumnsSettingsContext();

  return (
    <TableHead>
      <StyledTableRow>
        <StyledStickyHeadTableCell sx={{ minWidth: STICKY_LEFT_VALUE }}>
          <Typography fontWeight="bold">Domain</Typography>
        </StyledStickyHeadTableCell>
        <StyledStickyHeadTableCell sx={{ left: STICKY_LEFT_VALUE }}>
          <TableSortLabel
            active={orderBy === 'flow'}
            direction={desc ? 'desc' : 'asc'}
            onClick={() => {
              handleToggleSort('flow');
            }}
          >
            <Typography fontWeight="bold">Flows</Typography>
          </TableSortLabel>
        </StyledStickyHeadTableCell>
        {creatives && (
          <TableCellItem>
            <TableSortLabel
              active={orderBy === 'creatives'}
              direction={desc ? 'desc' : 'asc'}
              onClick={() => {
                handleToggleSort('creatives');
              }}
            >
              Creatives
            </TableSortLabel>
          </TableCellItem>
        )}
        {branch && <TableCellItem>
            <Typography fontWeight={"bold"}>Branch</Typography>
        </TableCellItem>}
        {impressions_meta && (
          <TableCellItem>
            <TableSortLabel
              active={orderBy === 'impressions_meta'}
              direction={desc ? 'desc' : 'asc'}
              onClick={() => {
                handleToggleSort('impressions_meta');
              }}
            >
              Meta
            </TableSortLabel>
          </TableCellItem>
        )}
        {impressions_google && (
          <TableCellItem>
            <TableSortLabel
              active={orderBy === 'impressions_google'}
              direction={desc ? 'desc' : 'asc'}
              onClick={() => {
                handleToggleSort('impressions_google');
              }}
            >
              Google
            </TableSortLabel>
          </TableCellItem>
        )}
        {impressions_total && (
          <TableCellItem>
            <TableSortLabel
              active={orderBy === 'impressions_total'}
              direction={desc ? 'desc' : 'asc'}
              onClick={() => {
                handleToggleSort('impressions_total');
              }}
            >
              Total
            </TableSortLabel>
          </TableCellItem>
        )}
        {traffic_share && <TableCellItem>Traffic share</TableCellItem>}
        {first_seen && (
          <TableCellItem>
            <TableSortLabel
              active={orderBy === 'first_seen'}
              direction={desc ? 'desc' : 'asc'}
              onClick={() => {
                handleToggleSort('first_seen');
              }}
            >
              First seen
            </TableSortLabel>
          </TableCellItem>
        )}
      </StyledTableRow>
    </TableHead>
  );
};

export default CompetitorsFlowsTableHead;
