import { debounce } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useUpdateCreative } from '../api';

export default function useChangeCreativeComment(creativeId: number, initial: string | null) {
  const [comment, setCommentState] = useState<string>(initial ?? '');
  const [error, setErr] = useState<string | null>(null);

  const updateCreativeParams = useMemo(
    () => ({
      id: creativeId,
      config: {
        onError: () => {
          setErr(`Can't save your changes!`);
        },
        onSuccess: () => {
          setErr(null);
        },
      },
    }),
    [creativeId]
  );

  const mutation = useUpdateCreative(updateCreativeParams);

  const update = useMemo(() => debounce(mutation.mutate, 500), [mutation.mutate]);

  const updateComment = useCallback(
    (value: string) => {
      setCommentState(value);
      update({ comment: value });
    },
    [update]
  );

  return {
    error,
    comment,
    updateComment,
    mutation,
  };
}
