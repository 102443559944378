import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig, SynchronousQueryConfig } from 'lib/react-query';
import type { Domain } from '../types';
import getDomainFromDTO from './getDomainFromDTO';
import type { DomainDTO } from './types';
import queryString from 'query-string';

interface GetDomainsParams {
  projects?: number[];
}
export const getDomains = async (params?: GetDomainsParams, signal?: AbortSignal): Promise<DomainDTO[]> => {
  const res = await apiClient.get<DomainDTO[]>(`/domains`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

export const useDomains = <TData = Domain[]>({
  config,
  params,
}: { config?: QueryConfig<typeof getDomains, TData>; params?: GetDomainsParams } = {}) => {
  const { projects } = { ...params };

  return useSuspenseQuery({
    staleTime: 60 * 60 * 1000,
    select: (data) => data.map((d) => getDomainFromDTO(d)) as TData,
    ...config,
    queryFn: ({ signal }) => getDomains(params, signal),
    queryKey: ['domains', projects],
  });
};

export const useSynchronousDomains = <TData = Domain[]>({
  config,
  params,
}: { config?: SynchronousQueryConfig<typeof getDomains, TData>; params?: GetDomainsParams } = {}) => {
  const { projects } = { ...params };

  return useQuery({
    staleTime: 60 * 60 * 1000,
    select: (data) => data.map((d) => getDomainFromDTO(d)) as TData,
    ...config,
    queryFn: () => getDomains(),
    queryKey: ['domains', projects],
  });
};
