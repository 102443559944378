import { useState } from "react";
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Box, Typography, styled, CircularProgress } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import IconGoogle from "assets/icon-google-color.svg?react";
import HotjarConsent from "./HotjarConsent";

const FormBox = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    padding: 16px 40px;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    position: absolute;
    bottom: 24px;
    left: 24px;
    margin-right: 24px;
    max-width: 600px;
    padding: 40px 20px;
    border-radius: 12px;
    background-color: rgba(255,255,255,0.8)
  }
`;

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onSignIn = async () => {
    try {
      setIsLoading(true);
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FormBox>
      <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 480 }}>
        <Typography sx={{ fontSize: 34 }}>Log in to WELLSPY</Typography>
        <Box>
          <LoadingButton
            loading={isLoading}
            size={"large"}
            onClick={onSignIn}
            variant={isLoading ? "outlined" : "contained"}
            loadingPosition={"center"}
            loadingIndicator={<CircularProgress size={24} color={"inherit"} />}
            startIcon={!isLoading ? <Box sx={{ backgroundColor: 'white', mr: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 60, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>
              <IconGoogle width={34} height={34} /></Box> : null}
            sx={{
              fontSize: 20,
              fontWeight: 500,
              lineHeight: 1.25,
              textTransform: 'none',
              my: 4,
              padding: isLoading ? '21px 0 21px 8px' : '4px 0px 4px 8px',
              width: '100%',
              height: isLoading ? 'auto' : '67px',
              maxWidth: 370,
              minWidth: 370,
              justifyContent: 'start',
              alignContent: 'center',
              alignItems: 'stretch',
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>Sign In with Google</Box>
          </LoadingButton>
          <HotjarConsent />
        </Box>
      </Box>
    </FormBox>
  );
};

export default LoginForm;
