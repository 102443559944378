import { Outlet, useMatch } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Add as AddIcon } from '@mui/icons-material';
import { DomainDialog } from 'features/domains';
import { useModal } from '../../hooks';
import { AppDialog } from 'features/apps';

const activeClass = {
  color: 'rgba(33, 150, 243, 1)',
  '&:before': {
    content: '""',
    display: 'block',
    width: '100%',
    height: 2,
    backgroundColor: 'rgba(33, 150, 243, 1)',
    position: 'absolute',
    bottom: 0,
  },
};

const CompetitorsContainer = () => {
  const domainModal = useModal();
  const appModal = useModal();

  const isDomainsTab = useMatch('settings/competitors/domains');

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', px: 3, pt: 3 }}
      >
        <Box>
          <Typography variant="h5" mb={1.5}>
            Competitors
          </Typography>
          <Button
            to="/settings/competitors/domains"
            component={Link}
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              px: 2,
              ...(isDomainsTab && activeClass),
            }}
          >
            Domains
          </Button>
          <Button
            to="/settings/competitors/apps"
            component={Link}
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              px: 2,
              ...(!isDomainsTab && activeClass),
            }}
          >
            Apps
          </Button>
        </Box>
        <Box>
          {isDomainsTab ? (
            <Button variant="contained" color="primary" endIcon={<AddIcon />} onClick={domainModal.open}>
              Add domain
            </Button>
          ) : (
            <Button variant="contained" color="primary" endIcon={<AddIcon />} onClick={appModal.open}>
              Add app
            </Button>
          )}
        </Box>
      </Grid>
      <DomainDialog onClose={domainModal.close} isOpen={domainModal.isOpen} />
      <AppDialog onClose={appModal.close} isOpen={appModal.isOpen} />
      <Outlet context={{ domainModal, appModal }} />
    </>
  );
};

export default CompetitorsContainer;
