import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { CreateDomainPayload, DomainDTO } from './types';

interface UpdateDomainParams {
  id: number;
  data: Partial<CreateDomainPayload>;
}
export const updateDomain = async ({ id, data }: UpdateDomainParams): Promise<DomainDTO> => {
  const res = await apiClient.patch<DomainDTO>(`/domains/${id}`, data);
  return res.data;
};

interface UseUpdateDomainOptions {
  config?: MutationConfig<typeof updateDomain>;
}

export const useUpdateDomain = ({ config }: UseUpdateDomainOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['domains'],
      });

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: updateDomain,
  });
};
