import { Typography } from "@mui/material"
import type { FC } from "react"

const TotalCompetitorsTitle: FC<{ value: number }> = ({ value }) => {
  return (
    <Typography sx={ t => ({ fontSize: 12, fontWeight: 500, color: t.palette.grey[500], pr: 2,}) }>
      Total: <Typography component={'span'} sx={t => ({ fontSize: t.typography.body2.fontSize, fontWeight: 500, color: t.palette.grey[800] })}>{value}</Typography>
    </Typography>
  )
}

export default TotalCompetitorsTitle;
