import type { CreativesStatsReportEntryDTO } from './types';

export default function getAPIUsageDetailsFromDTO(dto: CreativesStatsReportEntryDTO[]): CreativesStatsReportEntryDTO[] {
  const data: CreativesStatsReportEntryDTO[] = dto.map(item => {
    const totalPercentageSP = item.ws_total_creatives_count / item.sp_total_creatives_count;

    return ({
    ...item,
    total_in_sp: !Number.isNaN(totalPercentageSP) ? parseInt((totalPercentageSP * 100).toFixed(0)) : 0
  })})

  return data;
}
