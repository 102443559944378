import type { AutocompleteRenderGroupParams } from '@mui/material';
import { Box, Checkbox, Collapse, IconButton, Typography } from '@mui/material';
import { useModal } from 'hooks';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CollapseGroup = ({
  params,
  checked,
  partiallyChecked,
  onChange,
  label,
}: {
  params: AutocompleteRenderGroupParams;
  checked: boolean;
  onChange: () => void;
  partiallyChecked: boolean;
  label?: string;
}) => {
  const { isOpen, open, toggle } = useModal(true);

  const handleChange = () => {
    onChange();
    if (!isOpen) {
      open();
    }
  };
  return (
    <Box>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
        <Checkbox
          id={`collapse-tag-group-${params.group.toLowerCase().replace(/\s+/g,'')}`}
          key={params.group}
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={partiallyChecked ? <IndeterminateCheckBoxIcon /> : <CheckBoxIcon />}
          checked={checked || partiallyChecked}
          onChange={handleChange}
        />
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center', cursor: 'pointer' }}
          onClick={toggle}
        >
          <Typography sx={{ cursor: 'pointer' }} fontWeight="bold" component="span">
            {label ?? params.group}
          </Typography>
          <IconButton aria-label="expand row" size="small">
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isOpen} timeout="auto">
        {params.children}
      </Collapse>
    </Box>
  );
};

export default CollapseGroup;
