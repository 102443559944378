import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { SynchronousQueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { FlowsAutocompleteEntry, GetFlowsAutocomplete } from './types';

interface FilteringFields {
  domain_id: number[];
}

interface GetFlowsAutocompleteOptions {
  params: Partial<FilteringFields>;
}

export const getFlowsAutocomplete = async (
  { params }: GetFlowsAutocompleteOptions,
  signal?: AbortSignal
): Promise<FlowsAutocompleteEntry[]> => {
  const res = await apiClient.get<GetFlowsAutocomplete>(`/flows/autocomplete`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  return res.data.data;
};

interface UseFlowsAutocompleteOptions<TData> {
  config?: SynchronousQueryConfig<typeof getFlowsAutocomplete, TData>;
  params: GetFlowsAutocompleteOptions['params'];
}

export const useFlowsAutocomplete = <TData = FlowsAutocompleteEntry[]>({
  config,
  params,
}: UseFlowsAutocompleteOptions<TData>) => {
  return useQuery({
    staleTime: 60 * 60 * 1000,
    ...config,
    queryFn: () => getFlowsAutocomplete({ params }),
    queryKey: ['flows/autocomplete', params],
  });
};
