import type { AutocompleteValue, TextFieldProps } from '@mui/material';
import type { OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';
import type { AutocompleteProps } from '@mui/material';
import type { SearchLanguages } from 'types/shared';

interface LanguageOption {
  value: SearchLanguages;
  label: string;
}
export const LANGUAGE_OPTIONS: LanguageOption[] = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Unknown', value: 'un' },
];

interface LanguageAutocompleteProps
  extends Omit<
    AutocompleteProps<SearchLanguages, true, boolean, false>,
    'value' | 'onChange' | 'options' | 'renderInput'
  > {
  value?: AutocompleteValue<SearchLanguages, true, boolean, false>;
  onChange: (value: AutocompleteValue<SearchLanguages, true, boolean, false>) => void;
  InputProps?: TextFieldProps;
  allSelectable?: boolean;
}

const LanguageAutocomplete = ({
  InputProps,
  value,
  onChange,
  allSelectable = true,
  ...props
}: LanguageAutocompleteProps): JSX.Element => {
  return (
    <CheckboxAutocomplete
      {...props}
      allSelectable={allSelectable}
      value={value}
      id="language-autocomplete"
      onChange={onChange as (value: OptionValue[]) => void}
      InputProps={InputProps}
      options={LANGUAGE_OPTIONS}
    />
  );
};

export default LanguageAutocomplete;
