/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { type FC } from 'react';
import TagAdd from './TagAdd';
import TagDelete from './TagDelete';
import TagSorting from './TagSorting';
import type { TagEntry } from '../../types';
import { useTagsSort } from 'features/tags';
import TagNameField from './TagNameField';
import { useUpdateTag } from 'features/tags';
import type { UseMutationResult } from '@tanstack/react-query';
import { useModal } from 'hooks';

interface TagGroupTagsProps {
  groupId: number,
  tags: TagEntry[]
}

interface TagNameProps {
  tagName: string,
  tagId: number,
  groupId: number
}

const TagName: FC<TagNameProps> = ({ tagName, tagId, groupId }) => {
  const { open, close, isOpen } = useModal(false);
  const changeTag = useUpdateTag({
    params: {
      group_id: groupId,
      tag_id: tagId
    },
  });

  return (
    <>
      {!isOpen
        ? <Button
          variant="text"
          onClick={open}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            textAlign: 'left',
            textTransform: 'inherit',
            fontSize: 14,
            lineHeight: 1.3,
            letterSpacing: .2,
            fontWeight: 'normal',
            wordBreak: 'break-all',
            color: 'black',
            ml: -1,
            border: '1px solid transparent',
            '&:hover': {
              border: '1px solid #bbe1ff',
            }
          }}>{tagName}</Button>
        : <Box sx={{ display: 'flex', flexGrow: 1, ml: -1 }}>
          <TagNameField
            tagName={tagName}
            onMutate={changeTag as UseMutationResult}
            onClose={close} />
        </Box>
      }
    </>
  )
}

const TagGroupTags: FC<TagGroupTagsProps> = ({ groupId, tags }) => {
  const { currentSorting, sortedTags, setSortOrder } = useTagsSort(tags, { order: 'asc', type: 'alphabet' });

  return (
    <TableContainer
      component={Box}
      sx={t => ({
        borderRadius: 2,
        border: `1px solid ${t.palette.grey[300]}`,
      })}>
      <Table
        size="small"
        stickyHeader
        sx={{
          '& thead th': { backgroundColor: 'white' },
          '& tbody tr:last-child td': { border: 'none' }
        }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 280 }}>Tag name</TableCell>
            <TableCell>Creatives count</TableCell>
            <TableCell align="right">Sorting <TagSorting setSortOrder={setSortOrder} currentSorting={currentSorting} /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TagAdd groupId={groupId} />
          {sortedTags.map(tag => (
            <TableRow
              sx={{
                '& td:last-child button': { visibility: 'hidden' },
                '&:hover td:last-child button': { visibility: 'visible' },
                '& .tagEditName': { visibility: 'hidden' },
                '&:hover .tagEditName': { visibility: 'visible' }
              }}
              key={tag.tag_id}>
              <TableCell sx={{ width: 280, maxWidth: 280 }}>
                <TagName tagName={tag.tag_name} tagId={tag.tag_id} groupId={groupId} />
              </TableCell>
              <TableCell>{tag.creatives_count}</TableCell>
              <TableCell align="right">
                <TagDelete groupId={groupId} tag={tag} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default TagGroupTags;
