import type { FC } from "react";
import { useCallback } from "react";
import type { TagAutocompleteOption } from "..";
import type { ChipProps } from '@mui/material';
import { Chip } from "@mui/material";
import getTruncatedText from "utils/getTruncatedText";

interface TagChipItemProps extends ChipProps {
  payloadTags: TagAutocompleteOption[],
  tag: TagAutocompleteOption;
  updateStateTags: (values: TagAutocompleteOption[]) => void,
  onShowAlert: (idAlert: number, value: string) => void,
};

const TagChipItem: FC<TagChipItemProps> = ({ onShowAlert, updateStateTags, payloadTags, tag: { id: tagId, label: tagLabel } , ...restProps}) => {
  const isStateTag = !!payloadTags.find(tag => tag.id === tagId);

  const deleteSelectedTag = useCallback(() => {
      onShowAlert(tagId, `"${tagLabel}" deleted!`);
      updateStateTags(payloadTags.filter(tag => tag.id !== tagId));
    },
    [payloadTags, tagId, tagLabel]);

  return isStateTag ? (
    <Chip
      onDelete={deleteSelectedTag}
      key={tagId}
      id={`chip-tag-autocomplete-${tagId}`}
      label={getTruncatedText({ text: tagLabel, maxLength: 20 })}
      {...restProps}
    />
  ) : null;
};

export default TagChipItem;
