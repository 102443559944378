import { useEffect, useState } from 'react';
import { orderBy } from 'lodash-es';
import type { CreativesStatsReportEntry } from '../types';

type SortOrderProp = 'asc' | 'desc';
type SortTypeProp = 'search_value' | 'created_creatives_count' | 'updated_creatives_count' | 'total_in_sp';

interface SortOptionsProps {
  name: string;
  order: SortOrderProp;
  type: SortTypeProp;
}

const useDetailsSort = (initialArray: CreativesStatsReportEntry[], initialSorting:Pick<SortOptionsProps, 'order' | 'type'>) => {
  const [sortedDetails, setSortedArray] = useState<CreativesStatsReportEntry[]>(initialArray);
  const [currentSorting, setCurrentSorting] = useState<Pick<SortOptionsProps, 'order' | 'type'>>(initialSorting);

  const setSortOrder = ({order, type}: Pick<SortOptionsProps, 'order' | 'type'> ) => {
    const sortCb =
      type === 'search_value'
        ? (item: CreativesStatsReportEntry) => item.search_value.toLowerCase()
        : (item: CreativesStatsReportEntry) => item[type];

    const sortedArray = orderBy( initialArray, [sortCb], [order] );
    setSortedArray(sortedArray);
    setCurrentSorting({order, type});
  };

  useEffect(() => {
    setSortOrder(currentSorting);
  }, [initialArray])

  return { currentSorting, sortedDetails, setSortOrder };
};

export default useDetailsSort;
