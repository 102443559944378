import type { AdType } from '../types';
import { useDomains } from 'features/domains';
import { isEmpty } from 'lodash-es';
import type { Theme } from '@mui/material';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useDeletableChips, useModal } from 'hooks';
import type { Option, OptionValue } from 'components/common/CheckboxAutocomplete';
import { useFlowsAutocomplete } from 'features/competitors-flows';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTagGroups } from '../../tags';
import { CHANNELS } from './ChannelsAutocomplete';
import { AD_TYPES_OPTIONS } from './TypeAutocomplete';
import { useApps } from '../../apps';
import type { SearchLanguages } from '../../../types/shared';
import { LANGUAGE_OPTIONS } from '../../../components/common/LanguageAutocomplete';

interface FilterHeaderProps {
  selectedApps: number[];
  changeApps: (value: number[]) => void;
  selectedDomains: number[];
  changeDomains: (value: number[]) => void;
  selectedFlows: number[];
  changeFlows: (value: number[]) => void;
  selectedChannels: number[];
  changeChannels: (value: number[]) => void;
  selectedAdTypes: AdType[];
  changeAdTypes: (value: AdType[]) => void;
  selectedTags: number[];
  changeTags: (value: number[]) => void;
  selectedLanguages: SearchLanguages[];
  changeLanguages: (value: SearchLanguages[]) => void;
  handleReset: () => void;
}

const FilterHeader = ({
  selectedApps,
  changeApps,
  selectedDomains,
  changeDomains,
  selectedFlows,
  changeFlows,
  selectedChannels,
  changeChannels,
  selectedAdTypes,
  changeAdTypes,
  selectedTags,
  changeTags,
  selectedLanguages,
  changeLanguages,
  handleReset,
}: FilterHeaderProps) => {
  const { isOpen, toggle } = useModal();
  const isSmallerScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('macbook15'));
  const chipsToShow = isSmallerScreen ? 10 : 20;

  const { data: domains } = useDomains<Option[]>({
    config: {
      select: (data) => data.map((d) => ({ value: d.id, label: d.domain })),
    },
  });
  const { chips: domainChips } = useDeletableChips({
    changeFn: changeDomains as (value: OptionValue[]) => void,
    selectedData: selectedDomains,
    data: domains,
  });

  const { data: apps } = useApps<Option[]>({
    config: {
      select: (data) => data.map((a) => ({ value: a.id, label: a.name })),
    },
  });

  const { chips: appChips } = useDeletableChips({
    changeFn: changeApps as (value: OptionValue[]) => void,
    selectedData: selectedApps,
    data: apps,
  });

  const { data: flows } = useFlowsAutocomplete<Option[]>({
    params: { domain_id: selectedDomains },
    config: {
      enabled: !isEmpty(selectedDomains),
      select: (data) => data.map((f) => ({ value: f.id, label: f.flow })),
    },
  });

  const { data: tags } = useTagGroups<Option[]>({
    config: {
      select: (data): Option[] =>
        data.data.map((tagGroup) => tagGroup.tags.map((tag) => ({ value: tag.tag_id, label: tag.tag_name }))).flat(),
    },
  });

  const { chips: flowChips } = useDeletableChips({
    changeFn: changeFlows as (value: OptionValue[]) => void,
    selectedData: selectedFlows,
    data: flows ?? [],
  });

  const { chips: channelChips } = useDeletableChips({
    changeFn: changeChannels as (value: OptionValue[]) => void,
    selectedData: selectedChannels,
    data: CHANNELS as Option[],
  });

  const { chips: adTypesChips } = useDeletableChips({
    changeFn: changeAdTypes as (value: OptionValue[]) => void,
    selectedData: selectedAdTypes,
    data: AD_TYPES_OPTIONS as Option[],
  });

  const { chips: tagsChips } = useDeletableChips({
    changeFn: changeTags as (value: OptionValue[]) => void,
    selectedData: selectedTags,
    data: tags,
  });

  const { chips: languageChips } = useDeletableChips({
    changeFn: changeLanguages as (value: OptionValue[]) => void,
    selectedData: selectedLanguages,
    data: LANGUAGE_OPTIONS as Option[],
  });

  const appliedFilters = [
    ...domainChips,
    ...appChips,
    ...flowChips,
    ...tagsChips,
    ...channelChips,
    ...adTypesChips,
    ...languageChips,
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        pt: appliedFilters.length ? 1 : 0,
        pb: appliedFilters.length ? 3 : 0,
      }}
    >
      <Box sx={{ display: 'inline-flex', gap: 1, flexWrap: 'wrap', maxHeight: 220, overflowY: 'scroll' }}>
        {!!appliedFilters.length && (
          <Button sx={{ height: 24, borderRadius: 16, py: 0.5, px: 1, fontSize: '0.8125rem' }} onClick={handleReset}>
            Reset filters
          </Button>
        )}
        {isOpen ? appliedFilters : appliedFilters.slice(0, chipsToShow)}
      </Box>
      {appliedFilters.length > chipsToShow && (
        <Box sx={{ display: 'inline-flex', mt: 1 }}>
          <Button sx={{ height: 24, borderRadius: 16, py: 0.5, px: 1, fontSize: '0.8125rem' }} onClick={toggle}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}Show {isOpen ? 'less' : 'more'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FilterHeader;
